export const ru = {
  next: "Далее",
  youWontBeCharged:
    "Средства не будут заблокированы до подтверждения деталей сессии.",
  home: "Избранные",
  chats: "Чаты",
  settings: "Настройки",
  moreSettings: "Дополнительные настройки",
  settingsNotify: "Настройки | Waylight",
  logOut: "Выйти",
  changePassword: "Изменить пароль",
  receiveResetPassword:
    "Нажмите “Сбросить”, чтобы получить ссылку для изменения пароля.",
  enterEmailForResetPassword:
    "Введите ваш email адрес, чтобы получить ссылку для изменения пароля.",
  successResetPassword: "Email отправлен!",
  failResetPassword: "Не удалось сбросить пароль.",
  reset: "Cбросить",
  signOut: "Выйти",
  availableBooking: "Бронирование доступно",
  unavailableBooking: "Бронирование недоступно",
  shareProfile: "Поделиться профилем",
  requests: "Запросы",
  hours: "Часы",
  totalEarned: "Всего оплат",
  totalHours: "Всего часов",
  runningNow: "началась",

  offer: "Предложение",
  gallery: "Галерея",
  photo: "Фото",

  occupation: "Должность",
  skills: "Тэги",
  experts: "Эксперты",
  noInformation: "Нет информации",
  speaks: "Языки",
  socialLinks: "Социальные сети",

  contacts: "Контакты",
  noOffersYet: "Нет доступных предложений",
  all: "Все",
  requested: "Запрошена",
  confirmed: "Подтверждена",
  passed: "Завершена",
  joined: "Присоединён",
  ongoing: "Активна",
  canceled: "Отменена",
  rejected: "Отклонена",
  approved: "Подтверждена",
  calendar: "Календарь",
  favorites: "Избранные",
  removeAll: "Удалить все",
  viewProfile: "Открыть профиль",
  today: "Сегодня",
  upcoming: "Будущие",
  attachments: "Вложения",

  prepare: "Подготовиться",

  prerequisites: "Требования",
  download: "Скачать",
  past: "Прошлые",
  decline: "Отклонить",
  accept: "Подтвердить",

  wantDeclinePaidSession:
    "Вы уверены, что хотите отклонить запрос? Оплата вашего клиента будет возвращена.",
  wantDeclineAfterApprove:
    "Вы уверены, что хотите отменить? Оплата вашего клиента будет возвращена.",
  videoCall: "Включить видео",
  findExpert: "Найти эксперта",
  selectAnExpert: "выбрать эксперта",
  inviteClients: "пригласите ваших клиентов",
  selectAChat: "Выберите чат, чтобы начать разговор",
  noChatsYet: "Еще нет чатов",
  notHaveMessages: "У вас еще нет сообщений",
  shareProfileToClient: "Поделиться профилем с клиентом",
  remove: "Удалить",
  time: "Время",
  timeSlots: "Слоты",
  deleteOffer: "Удалить предложение",
  myOffers: "Мои предложения",

  createNewOffer: "Создать новое предложение",
  editOffer: "Редактировать предложение",
  successfulyBooked: "Запрос на сессию успешно отправлен",
  close: "Закрыть",
  account: "Аккаунт",
  price: "Стоимость",
  priceAndLength: "Цена и длительность",

  uploadAFile: "Загрузить файл",
  uploadAFileError: "Файл не загружен",
  confirm: "Подтвердить",
  IDLogin: "Ваш логин",
  myProfile: "Мой профиль",
  transactions: "Транзакции",
  transactionHistory: "История транзакций",
  noTransactions: "У вас еще нет транзакций",
  typicalSchedule: "Типичный график",
  amount: "Сумма",
  status: "Статус",
  paid: "Выплачена",
  action: "Действие",
  description: "Описание",
  billing: "Оплаты",
  card: "Карта",
  clients: "Клиенты",

  payout: "Выплаты",
  firstName: "Имя",
  lastName: "Фамилия",
  skip: "Пропустить",
  connect: "Подключиться",
  about: "Мой опыт",
  title: "Название",
  languages: "Языки",
  currency: "Валюта",
  addYourOffer: "Добавить ваше предложение",
  join: "Войти",
  joinIn: "Войти через",
  joinOn: "Войти",
  message: "Сообщение",
  client: "Клиент",
  expert: "Эксперт",
  Expert: "Эксперт",
  noMoreOptions: "Больше нет опций",
  male: "Мужчина",
  female: "Женщина",
  custom: "Custom",
  copyYourLink: "Скопировать вашу ссылку",
  done: "Готово",
  search: "Поиск",
  searchWaylight: "Найдите свой Waylight",
  suggested: "Популярные",

  startASession: "Быстрая сессия",
  quickSession: "Быстрая сессия",
  rate: "Стоимость",
  perHour: "за час",
  packages: "Пакеты",
  timezone: "Часовой пояс",
  book: "Бронировать",
  favorite: "Избранный",
  lastSession: "Последнее взаимодействие",
  ago: "тому назад",
  gender: "Пол",
  loading: "Загрузка",
  min: "мин",
  hour: "час",
  online: "Онлайн",
  offline: "Оффлайн",
  date: "Дата",
  displayName: "Имя",
  closePanel: "Закрыть панель",
  goToStripe: "Открыть вашу панель выплат",
  completeStripeOnBoarding: "Завершить Stripe Express онбординг",
  stripeOnBoarding: "Stripe Онбординг",
  completed: "завершено",
  notCompleted: "не завершено",
  myClients: "Мои клиенты",
  findClients: "Мы не можем найти клиентов",
  clientDisplayedHere: "Когда вы найдете клиентов, они будут отображены здесь",
  writeSomething: "Введите данные и нажмите enter, чтобы создать item",

  registerAs: "Зарегистрироваться как:",
  addedCards: "Ваши карты",
  cardType: "Тип карты",
  billingHistory: "История оплат",
  deleteCard: "Удалить карту",
  wantToDeleteCard: "Вы уверены, что хотите удалить карту?",
  selectPaymentMethod: "Выберите способ оплаты",
  addNewCard: "Добавить новую карту",
  emailAddress: "Публичный email адрес",
  publicPhoneNumber: "Общедоступный номер телефона",
  phoneNumber: "Номер телефона",
  username: "Имя пользователя",
  socialProfilesOrLinks: "Социальные профили / ссылки",
  newOffer: "Новое предложение",
  myExperts: "Мои эксперты",
  myClientProfile: "Мой клиентский профиль",
  noExpertsFound: "Эксперты не найдены",
  openMenu: "Открыть меню",
  becomeAnExpert: "Войти в Waylight Business",
  switchToExpert: "Переключиться на страницу бизнеса",
  switchToClient: "Переключиться на страницу клиента",
  notHaveExperts: "У вас еще нет экспертов",
  endCall: "Остановить видео",
  endSharing: "Остановить демонстрацию",
  lastOnline: "Последний раз онлайн",
  clientSince: "Клиент с",
  welcome: "Добро пожаловать",
  messageError: "Обязательное поле",
  support: "Поддержка",
  profile: "Профиль",
  portfolio: "Портфолио",
  publicId: "Public ID",
  files: "Файлы",
  reason: "Причина",
  declineReason: "Сообщите клиенту о причине отказа",
  rejectionReason: "Причина отказа",
  business: "Бизнес",
  passwordResetInvalidEmail: "Неверный Email",
  logIn: "Вход",
  logInSubtitle:
    "Для выполнения каких-либо действий, пожалуйста, авторизуйтесь или зарегистрируйтесь.",
  enterEmail: "Введите email",
  enterPassword: "Пароль",
  capsLockIsOn: "Caps Lock включен",
  numLockIsOn: "Num Lock включен",
  forgotPassword: "Забыли свой пароль?",
  forgotPasswordEmail:
    "Введите свой адрес электронной почты, чтобы получить ссылку для сброса пароля.",
  requestPasswordReset: "Сбросить пароль",
  requestResetLink: "Отправить ссылку повторно",
  in: "через",
  sec: "сек",
  passwordResetRequestReceived: "Запрос на сброс пароля",
  passwordResetIfAccountExist:
    "Если учетная запись существует, вскоре вы получите электронное письмо со ссылкой.",
  resetPassword: "Сбросить пароль",
  setNewPassword: "Установите новый пароль для входа в личный кабинет",
  passwordChangeSuccessful: "Пароль успешно изменён",
  passwordChangeSuccessfulSubtitle:
    "Теперь вы можете использовать новый пароль для входа в свою учетную запись.",
  passwordChange: "Изменить пароль",
  dontHaveAccount: "У вас нет учётной записи?",
  passwordOld: "Старый пароль",
  passwordNew: "Новый пароль",
  alreadyRegistered: "У вас есть учётная запись?",
  tokenExpired: "Устаревший токен",
  tokenExpiredDescription: "Этот токен устарел либо уже был использован",
  validLength: "8 и более символов",
  upperCase: "Верхний регистр (A-Z)",
  lowerCase: "Нижний регистр (a-z)",
  numbersOrSymbols: "Цифры или символы (0-9!@#$%^&*)",
  joinFree: "Присоединиться сейчас",
  businessTitle:
    "Waylight | Инструмент Link in BIO, который помогает зарабатывать!",
  businessDescription:
    "Увеличьте свой доход от социальных сетей в 10 раз! Запускайтесь всего за 2 минуты!",
  spotlightYourExperience:
    "Эффективные инструменты для ваших источников доходов: контента, экспертизы и сообществ",
  monetizationPlatform: "Студия Онлайн Бизнеса",
  createAccount: "Создать аккаунт",
  watchVideo: "Смотреть видео",
  bestToolsAvailable:
    "Мы хотим, чтобы у вас были лучшие инструменты и вы не должны были ежемесячно оплачивать их использование.",
  schedulingManagement: "Управление расписанием.",
  chooseConvenientTimeSlots: "Выбирайте удобные таймслоты и получайте запросы.",
  expertLandingPayouts: "Выплаты.",
  simpleOnboarding: "Простой onboarding ваших финансовых сервисов.",
  industryStandard: "Стандарты индустрии по качеству и доступности.",
  lead: "Ведите",
  engage: "Вовлекайте",
  frequentlyAskedQuestions: "Часто задаваемые вопросы",
  contactUs: "Контакты:",
  accordionLabel0: "Если сервис бесплатный, как работает Waylight?",
  accordionText0:
    "Мы получаем комиссию в соответствии с объёмами успешных платежей, а discovery и благотворительные звонки за наш счёт!",
  accordionLabel1: "Вы поддерживаете много типов предложений?",
  accordionText1:
    "Конечно, вы можете создать столько предложений, сколько захотите, указав стоимость, длительность и детали каждого.",
  accordionLabel2: "Как я получу платежи?",
  accordionText2:
    "Вам необходимо будет пройти простой onboarding, чтобы подключить ваши финансовые методы. Дополнительные методы будут добавлены в ближайшем будущем.",
  accordionLabel3: "Я могу использовать Zoom, Meet или Skype?",
  accordionText3:
    "Мы интегрировали звонки в платформу, чтобы гарантировать безопасность всех взаимодействий. Менее безопасные и более гибкие методы будут добавлены в будущем.",
  accordionLabel4: "Я могу использовать Zapier, Calendly и т.д.?",
  accordionText4:
    "Мы активно работам над интеграциями и были бы рады услышать ваши мысли на этот счёт. Напишите нам на support@waylight.me. Спасибо!",
  freeAudience: "Мы разрабатываем - вы создаёте!",
  businessProfile: "Бизнес профиль.",
  onlineBusiness: "Запустите онлайн бизнес за минуты, делая то, в чём хороши.",
  paidCalls: "Платные видео и аудио звонки.",
  paidChatsAndBlog: "Платные чаты и блог.",
  setUpSubscription: "Создайте подписку на чат и персональный блог.",
  web3: "Web3 рейтинги и отзывы.",
  collectRealRatings:
    "Получайте реальный рейтинги и отзывы, чтобы создать персональный бренд.",
  getStartedToday: "Три простых шага к новому источнику дохода:",
  createProfile: "1. Создайте Ваш Профиль",
  fillTemplate:
    "Заполните ваш Профиль, создайте расписание и пропозиции, укажите стоимости платных чатов, блога и за считанные минуты будьте готовы принимать заказы и получать подписки.",
  shareIt: "2. Поделитесь с миром",
  linkYourProfile:
    "Свяжите ваш профиль со всеми каналами - вашими социальными сетями, рекламой, email. Поделитесь информацией про ваш онлайн бизнес в постах, видео, персональной коммуникации.",
  growYourBusiness: "3. Развивайте ваш бизнес",
  shareYourExpertise:
    "Делитесь вашей экспертизой и знаниями, ведите ваш блог, отвечайте на запросы и чаты, консультируйте онлайн по видео и получайте публичные рейтинги и отзывы с помощью Web3 технологий.",
  businessflow: "Флоу",
  businessflowStep1Title: "Добавьте ссылку в БИО",
  businessflowStep1:
    "Добавьте ссылку на ваш профиль Waylight Business в БИО в социальных сетях (Instagram, Facebook, YouTube, LinkedIn, Twitter, TikTok, и т.д.) и поделитесь об этом в сториз и постах",
  businessflowStep2Title: "1:1 видео звонки, 1:1 чаты, Персональный блог",
  businessflowStep2:
    "Монетизируйтесь через 1:1 видео звонки, 1:1 чаты с подписчиками и клиентами. Создавайте закрытые посты для подписчиков: образовательный контент, статьи, видео-советы, бэкстейдж и опыт из личной жизни.",
  businessflowStep3Title: "Получайте деньги",
  businessflowStep3:
    "Получайте деньги за видео звонки, чаты и подписки на персональный блог. Увеличивайте монетизацию, распространяя информацию про ваш Waylight!",
  bookOnboardingCall: "Забронируйте бесплатный онбординг",
  talkToFounders:
    "Поговорите с основателями как начать монетизироваться с Waylight.",
  orScanQR: "или сканировать QR",
  yourFreeTools: "Ваши бесплатные инструменты",
  features: "Возможности",
  personalLink: "Персональная ссылка (URL)",
  personalQR: "Персональный QR",
  scheduling: "Календарь",
  bookings: "Бронирования",
  paidChats: "Платные 1:1 чаты",
  personalPaidBlog: "Персональный платный блог",
  soon: "скоро",
  subscriptions: "Подписки",
  payments: "Платежи в 100+ странах",
  payouts: "Выплати в 58+ странах",
  cryptoPayments: "Крипто платежи",
  coinsAwarding: "Coin вознаграждения",
  web3Ratings: "Web3 рейтинги, отзывы",
  clientsManagement: "Управление клиентами",

  dashboard: "Дэшборд",
  businessCalculator: "Waylight Business Калькулятор Дохода",
  howMuchMoney: "Узнайте, сколько ваш бизнес заработает на подписках.",
  estimateNumber: "1. Оцените количество клиентов и подписчиков.",
  howMuchYouCharge: "2. Сколько стоит видео сессия или подписка",
  monthlyIncome: "Месячный доход",
  newToday: " новых сегодня",

  atThisWeek: "на этой неделе",
  welcomeToWaylight: "Добро пожаловать в Waylight!",
  welcomeToWaylightBusiness: "Добро пожаловать в Waylight Business!",
  linkToVideo: "Ссылка на основное видео",
  writeSentences: "Разместите несколько слов о себе",
  emptyExpertTitle: "По нашим данным, такой страницы не существует",
  emptyExpertText:
    "Ссылка может быть неправильной или страница отключена или удалена.",
  profileData: "Данные профиля",
  headerImageTooltip:
    "Рекомендуемые соотношения изображения для заголовка – 10:1. Попробуйте что-то близкое к 1900x190px или 1280x128px.",
  emailTooltip:
    "Этот email адрес будет отображен в вашем профиле (это не ваш логин). Вы можете указать любой адрес или не указать ни одного вообще. Это поле - опциональное!",
  timezoneTooltip:
    "Если вы не можете найти ваш город, попробуйте поискать зону, такую как 'Pacific Daylight Time' или 'Eastern European Time'.",
  linkToVideoPresentation: "Добавить внешнюю ссылку",
  expertProfileVideoPresentation: "Или загрузить файл",
  linkToVideoPresentationTooltip:
    "Укажите ссылку на видео для деталей профиля. Большинство видео хостингов сработает. Если вы загрузите видео – оно будет иметь приоритет, по сравнению с этой ссылкой.",
  linkToVideoOfferTooltip:
    "Укажите ссылку на видео для деталей предложения. Большинство видео хостингов сработает. Если вы загрузите видео – оно будет иметь приоритет, по сравнению с этой ссылкой.",
  phoneTooltip:
    "Этот номер телефона будет публично доступным в вашем профиле. Это поле - опциональное!",
  socialProfilesTooltip:
    "Добавьте ссылки на ваши социальные сети и сайты, например Youtube, Facebook, Linkedin, Instagram или Tiktok.",
  videoPresentationTooltip:
    "Загрузите видео для деталей профиля. Оно будет иметь приоритет по сравнению с изображением или ссылкой на видео.",
  videoOfferTooltip:
    "Загрузите изображение или видео для деталей предложения. Оно будет иметь приоритет по сравнению с изображением или ссылкой на видео.",
  occupationTooltip:
    "Расскажите мире про вашу занятость, например 'AR Consultant' или 'Top Tiktok Influencer'.",
  skillsTooltip:
    "Добавьте ваши умение, чтобы клиенты понимали, в чём вы хороши, например 'Entrepreneurship' или 'Parenting'.",
  aboutTooltip:
    "Добавьте несколько слов о себе, чтобы клиенты ощутили вашу приветливость и оценили ваш персональный бренд.",
  speaksTooltip: "Укажите на каких языках разговариваете.",
  cropPhoto: "Обрезать фото",
  updatePhoto: "Загрузить фото для профиля",
  crop: "Обрезать",

  videoAudioSettings: "Видео и аудио настройки",
  videoAudioSettingsDescription:
    "Используйте это меню, чтобы настроить видео и аудио",
  cameras: "Камеры",
  microphones: "Микрофоны",
  output: "Аудио выводы",
  bgSettings: "Выберите бэкграунд для видео",
  toStartAChat: "Чтобы начать чат,",
  startAChat: "и начните чат.",
  messageToAnExpert: "Отправьте первое сообщение эксперту",
  messageToAClient: "Отправьте первое сообщение клиенту",
  chatWindowGetToKnow:
    "1. Познакомьтесь с потенциальными клиентами и их потребностями",
  requestInformation: "2. Узнайте дополнительную информацию, если требуется",
  notGettingAReply: "3. Если вы не получаете ответ, попробуйте вежливо еще раз",

  chatWindowProvideABrief: "1. Расскажите, что вы ищете",
  chatWindowAddAttachments: "2. Добавьте вложения, если нужно",
  describeYourIssue:
    "Пожалуйста, опишите ваш запрос, с чем вам нужна помощь или чему вы хотите научиться. Добавьте файлы, если нужно.",
  getAConfirmation: "Вы скоро получите подтверждение от эксперта.",
  describeTheDetails:
    "Опишите детали, стоимость и предпосылки вашего предложения.",
  areYouSure: "Вы уверены, что хотите удалить это предложение?",
  offerAvailable: "Предложение доступно",
  offerUnavailable: "Предложение недоступно",
  notHaveOffers: "У вас еще нет предложений",
  createOne: "Создайте предложение, чтобы начать работу с вашими клиентами.",
  getPaid: "Вы получаете фиксированную оплату за фиксированное время.",
  describeYourOffer: "Кратко опишите ваше предложение.",

  priceLimit: "Цена может быть $0, или от ${{minPrice}} до ${{maxPrice}}.",
  priceLimitForUA: "Цена может быть ₴0, или от ₴{{minPrice}} до ₴{{maxPrice}}.",
  offerNameDescription:
    "Попробуйте что-то вроде 'Discovery Call' или 'Basic Graphic Design' если вы в этом разбираетесь.",
  clickingConfirm: "Нажимая “Подтвердить”, я соглашаюсь с",
  termsAndConditions: "Условиями Использования",
  paymentFeesMayAlter: "Комиссия платёжной системы может варьироваться.",
  notBeCharged:
    "Cредства никогда не будут списаны без дополнительного подтверждения.",
  last: "Последние 4",
  lastDigits: "Последние 4 цифры",
  billingPageName: "Имя",
  gateway: "Платежная система",
  prepareForTheMeeting: "Подготовьтесь к сессии",
  prepareForSession: "Подготовьтесь к",
  findAQuiet: "Найдите тихое место, где вам никто не будет мешать",
  makeSure: "Убедитесь, что у вас качественный интернет",
  weRecommendUsing: "Мы рекомендуем использовать наушники",
  prepareYourQuestions: "Приготовьте вопросы",

  initialization: "Инициализация...",
  beginOnboardingAsExpert:
    "Начните процесс регистрации как эксперт в Waylight Business",
  connectStripeAccount:
    "Чтобы получить деньги от ваших клиентов, вы должны создать метод выплат.",
  provideABrief: "1. Кратко укажите на то, с чем вам нужна помощь",
  addAttachments: "2. Добавьте файлы, если нужно",
  gettingAReply: "3. Если вы не получаете ответ, попробуйте вежливо еще раз",

  fileType: "тип файлов не поддерживается",
  orDragAndDrop: " или перетянуть",
  upTo: "до 1 Гб (свяжитесь с поддержкой, если у вас большие размеры файлов)",
  headerImage: "Изображение заголовка",
  inviteClientsModalInviteClients: "Пригласите клиентов",
  shareYourLink:
    "Поделитесь вашей персональной ссылкой, чтобы пригласить ваших клиентов.",
  addDays: "Настройте ваше регулярное расписание",
  publiclyInformation:
    "В каждом дне недели может быть указано несколько таймслотов.",
  availableTimeslot: "Доступный таймслот",
  AddTimeslot: "Добавить таймслот",
  AddToSchedule: "Добавить в Расписание",
  signUpAs: "Зарегистрироваться как",
  registerAsClient: "Я хочу научиться новому",
  registerAsExpert: "Я хочу поделиться опытом",
  noTimeslots:
    "Нет доступных таймслотов. Выберите другую дату или спросите у эксперта относительно расписания.",
  toastSavedSuccessfully: "Успешно сохранено",
  toastDeletedSuccessfully: "Успешно удалено",
  toastProfileCopied: "Ссылка скопирована",
  toastEmailCopied: "Электронная почта скопирована",
  toastBookingAvalable: "Ваш профиль доступен для бронирования",
  toastBookingUnavalable: "Ваш профиль недоступен для бронирования",
  toastQrCopied: "QR-код скопирован",
  toastPaymentMethodAdded: "Способ оплаты успешно добавлен",
  toastPaymentMethodDeleted: "Способ оплаты успешно удален",
  toastUrlCopied: "Персональная ссылка скопирована",
  toastOfferUrlCopied: "Ссылка на предложение скопирована",

  toastPayoutMethodAdded: "Способ выплаты успешно добавлен",
  toastPayoutMethodDeleted: "Способ выплаты успешно удален",
  toastpayoutMethodNotAdded:
    "Нам не удалось добавить способ выплаты. Повторите попытку позже!",
  toastPrimaryCardError: "Не вдалося встановити основну картку",
  toastPrimaryCardAdded: "Основная карта успешно добавлена",
  toastTooLargeFile: "Слишком большой размер файла",
  toastOfferNotAdded: "Предложение не добавлено",
  toastOfferNotEdited: "Предложение не отредактировано",
  toastSessionLinkCopied: "Ссылка на сессию скопирована",
  toastMaxParticipantsReached: "Максимальное количество участников достигнуто",
  toastLinkExpired: "Срок действия этой ссылки вышел.",
  toastSessionExtendedSuccessfully: "Сессия успешно продлена",
  toastTimeslotStartAndEnd:
    "Вы не можете добавить таймслот, который начинается и заканчивается в то же время",
  toastTimeslotEndsInPast:
    "Вы не можете добавить таймслот, который заканчивается в прошлом",
  toastTimeslotDuplicate: "Вы не можете добавить дублирующийся таймслот",
  toastSkillNameTooLong:
    "Название тэга слишком длинное, попробуйте выбрать более короткое название",
  buttonSave: "Сохранить",
  buttonCancel: "Отменить",
  buttonApprove: "Подтвердить",
  buttonDelete: "Удалить",
  buttonChat: "Чат",
  buttonOpenChat: "Чат",
  buttonFiles: "Файлы",
  buttonNotNow: "Не сейчас",
  buttonSubmit: "Отправить",
  buttonNo: "Нет",
  buttonEnd: "Завершить",
  buttonSignUp: "Зарегистрироваться",
  buttonYes: "Да",
  buttonEdit: "Редактировать",
  buttonCopy: "Копировать",
  buttonReply: "Ответить",
  buttonAdd: "Добавить",
  buttonSend: "Отправить",
  buttonRequest: "Запросить поддержку",
  buttonSet: "Установить",
  buttonEditPhoto: "Редактировать фото",
  buttonStartAnyway: "Подтвердить запуск",
  buttonWait: "Подождать",
  buttonCopyLink: "Копировать ссылку",
  buttonLeave: "Выйти",
  buttonExtraMinutes: "Добавить 10 минут",
  buttonViewOffer: "Просмотреть детали",
  buttonMoreDetails: "Подробнее",
  buttonContinue: "Продолжить",
  buttonBookACall: "Видео сессии",
  notificationSetup: "Setup",
  notificationFillProfile: "Заполните профиль",
  notificationHi: "Привет!",
  notificationAddYourName:
    "Добавьте ваше имя и фото, чтобы оживить общение, если хотите. Не настаиваем :)",
  notificationSetupBilling: "Настройка оплат",
  notificationGoToBilling: "Перейти в Оплаты",
  notificationWeAreSureYouHaveALotToSay:
    "Мы уверены, вам есть что рассказать о себе. Заполните профиль и начните делиться знаниями!",
  notificationAddAnOffer: "Разместите предложение",
  notificationAddYourOffers: "Разместите ваши предложения",
  notificationGoToMyOffers: "Перейти в Мои предложения",
  notificationConnectStripe: "Настройка Выплат",
  notificationSetupPayouts: "Онбординг по выплатам",
  notificationInOrderToReceiveMoney:
    "Чтобы получить деньги от ваших клиентов, вы должны создать метод выплат.",
  notificationGoToPayouts: "Перейти в Выплаты",
  notificationSetYourSchedule: "Настройте ваше расписание",
  notificationWorkAtConvenientHours: "Работайте в удобное время",
  accountOn: "Аккаунт включен",
  accountOff: "Аккаунт выключен",
  paymentFailed: "Неудачная попытка оплаты",
  areYouSureCancelPaidSession:
    "Вы уверены, что хотите отменить сессию? Ваш платёж будет возвращён.",
  areYouSureCancelLessThanADay:
    "Вы уверены, что хотите отменить сессию, когда осталось менее 24ёх часов? Ваш платеж НЕ будет возвращен.",
  link: "Ссылка",
  view: "Открыть",
  requires_action: "Требует 3DS",
  succeeded: "Успешная",
  signUpTitle: "Зарегистрироваться",
  profileTitleNotify: "Профиль | Waylight",
  dashboardTitleNotify: "Дэшборд | Waylight",
  chatTitleNotify: "Чат | Waylight",
  signUpTitleNotify: "Зарегистрироваться | Waylight",
  with: "с",
  expertsTitle: "Эксперты",
  expertsTitleNotify: "Избранные | Waylight",
  chatTitle: "Чат",
  clientsTitle: "Клиенты",
  clientsTitleNotify: "Клиенты | Waylight",
  clientTitle: "Клиент",
  fixedPrice: "Фиксированная",
  internetConnectionLost: "Интернет соединение отсутствует",
  fiveMinutesLeft: "Осталось 5 минут",
  summarizeMeeting: "Подведите итоги вашей встречи и поделитесь впечатлениями",
  oopsConnectionLost: "Ой... Другая сторона потеряла связь",
  partyTemporaryLostConnection:
    "Кажется, вы остались наедине, из-за потери связи на другой стороне. Извините за неудобства.",
  leaveAReviewFor: "Оставьте отзыв про ",
  leaveAReview: "Оставьте отзыв",
  review: "Отзыв",
  followers: "Подписчики",
  rating: "Рейтинг",
  ratings: "рейтинга(ов)",
  reviews: "Отзывы",
  hitStarRate: "Поделитесь вашими впечатлениями ниже!",
  reviewsEmpty: "У эксперта ещё нет отзывов",

  thankYouForLighting: "Спасибо, что осветили путь другим!",
  devicePermissionTittle:
    "Разрешите Waylight доступ к вашему микрофону и камере. Перезагрузите страницу, если у вас нет видео.",
  networkConnection: "Уровень соединения",
  unknown: "неизвестен",
  low: "низкий",
  average: "средний",
  good: "стабильный",
  noFilesAdded: "Файлы ещё не добавлены.",
  enterYourEmail: "Укажите ваш email адрес",
  required: "Обязательное поле",
  emailOrPassword: "Неправильный email или пароль",
  emailInUse: "Этот email уже используется",
  businessIsNotAvailableTitle:
    "Waylight Business не доступен в вашей стране. Будьте первыми, кто узнает о запуске.",
  beInTouch: "Будьте на связи",
  businessIsNotAvailableEmailAddress: "Email Адрес",
  pleaseTryAgain:
    "Мы не смогли сохранить ваш email адрес. Пожалуйста, повторите попытку.",
  thankYou:
    "Спасибо, мы свяжемся с вами, как только поддержка вашей страны запустится.",

  yourCountry: "Страна вашего банка",
  open: "Открыть",
  offerName: "Название предложения",
  gatewayCountryWarning:
    "Внимание: это должна быть страна вашего банковского учреждения, и ее НЕЛЬЗЯ изменить в будущем, что может повлиять на вашу возможность получать выплаты.",
  addAttachment: "Добавить файл",
  you: "Вы",
  explore: "Избранные",
  stats: "Статистика",
  more: "Смотреть еще",
  noPrice: "Бесплатные предложения",
  theyWillAppear: "Они будут отображены здесь, когда вы начнете взаимодействие",
  noExpertFoundUsingFilter: "По этому запросу эксперты не найдены.",
  tryAnotherInput: "Попробуйте другой тэг или запрос",
  noFavoriteExpertsFound: "Избранные эксперты не найдены.",
  tryAddingSomeone: "Попробуйте добавить кого-то из экспертов в избранное.",
  agoraDeniedError:
    "Пожалуйста, разрешите использование микрофона и камеры в настройках, чтобы использовать Waylight!",
  editCover: "Редактировать обложку",
  language: "Язык",
  invalid: "Некорректный",

  typeHere: "Введите сообщение",
  schedule: "Расписание",
  offers: "Предложения",
  qrSharing: "Загрузите и поделитесь своим QR-кодом.",
  qrSharingMobile:
    "Нажмите и удерживайте QR-код, чтобы скопировать и поделиться им.",
  preview: "Предварительный просмотр",
  back: "Назад",
  love: "Сделано с любовью в Waylight",
  allRightsReserved: "© 2024 Waylight Inc. Все права защищены.",
  allRightsReservedSecond: "© 2022 Arceon Consulting OÜ. Все права защищены",
  termsOfService: "Условия Пользования",
  privacyPolicy: "Политика Конфиденциальности",
  sessionSharingpopupTitle: "Невозможно начать демонстрацию экрана!",
  sessionSharingpopupDescription:
    "Проверьте, обновлён ли ваш браузер до последней версии и есть ли у него системные разрешения для демонстрации экрана. Также, попробуйте перезагрузить страницу. Если проблема остаётся, свяжитесь с поддержкой!",
  sessionSharingpopupSafariDescription:
    "Демонстрация экрана не поддерживается вашим браузером. Последние версии популярных браузеров поддерживают эту функцию.",
  copy: "Копировать",
  expertWithoutOffers:
    "У этого эксперта пока нет ни одного платного предложения",
  errorViewHeader: "Что-то пошло не так!",
  errorViewDescription:
    "Произошла ошибка. Возможно, эта ссылка не работает, или у вас нет доступа к элементу.",
  returnToHome: "Вернуться на домашнюю",
  invalidURL:
    "Неверный URL-адрес. Убедитесь, что он начинается с 'https://' или 'http://'",
  invalidVideoExternalURL:
    "Недопустимый URL-адрес видео. Добавьте ссылку на Youtube или Vimeo.",
  tooLongUrl:
    "Эта ссылка слишком длинна. Пожалуйста, не используйте ссылки, длиннее, чем 128 символов.",
  repeatedURL: "Эта ссылка уже добавлена",
  edited: "Отредактировано",
  emptyCards: "Вы еще не добавили ни одной карты",
  emptyBillingHistory: "У вас еще нет транзакций",
  addNewSkill: "Добавить новый тег",
  confirmationAddSkill: "Вы уверены, что хотите добавить новый тег",
  addPaymentMethod: "Добавить способ оплаты",
  stripeDescription: "Для транзакций с экспертами из 50+ стран",
  fondyDescription: "Для транзакций с экспертами из Украины",
  fondyAddCardDescription:
    "Добавьте способ оплаты, чтобы продолжить. Будет предпринята попытка подтвердить тестовый платеж.",
  timeZoneChangedHeader: "Часовой пояс изменён",
  timeZoneSChangedDescription:
    "Мы обнаружили, что ваш часовой пояс изменился. Обновить часовой пояс в настройках профиля?",
  updateButton: "Изменить",
  and: "и",
  moreFiles: "еще файлов",
  addPayout: "Добавьте хотя бы один способ получения выплат",
  testChargeWarning: "Будет предпринята попытка подтвердить тестовый платеж.",
  payoutMethods: "Ваши способы получения выплат",
  identifier: "Идентификатор",
  stopWar: "Остановите войну",
  yourAreOnlyOneHere: "К этой сессии пока больше никто не подключился",
  yourAreOnlyOneHereSingle:
    "К этой сессии пока больше никто не подключился\nПоделитесь ссылкой ниже с другими",
  clientView: "Клиент",
  businessView: "Бизнес",
  mainPage: "Главная страница",
  signUpCompleted: "Регистрация завершена",
  selectPaymentInfo:
    "Доступные способы оплаты зависят от платежной системы вашего эксперта",
  payment: "Платежи",
  paymentWasDeclined: "Платеж отклонен.",
  payoutWasDeclined: "Способ выплаты отклонена.",
  primary: "Основная",
  notUsed: "Не используется",
  releaseBannerTitle: "Waylight был обновлён до новой версии.",
  refresh: "Обновить",
  country: "Страна",
  viewAll: "Просмотреть все",
  videoPresentation: "Видеопрезентация",
  bookFor: "Забронировать за",
  bookForFree: "Бронировать бесплатно",
  follow: "Следить",
  followed: "Слежу",
  emptyPortfolio: "Похоже, в этом портфолио нет файлов",
  media: "Медиа",
  other: "Файлы",
  emptyReview: "Похоже отзывов нет",
  newest: "Самые новые",
  oldest: "Самые старые",
  viewProfileInfo: "Открыть детали",
  emptyTransactions: "Похоже, транзакций нет",
  emptyOffers: "К сожалению, у этого эксперта пока нет предложений.",
  searchExperts: "Искать экспертов",
  modifySchedule: "Редактировать расписание",
  editMode: "Редактировать",
  finishEditing: "Завершить редактирование",
  editPersonalInfo: "Редактировать профиль",
  editPortfolio: "Редактировать портфолио",

  profileId: "ID личного профиля",
  profileSettings: "Настройки профиля",
  editPublicID: "Изменить общедоступный ID",
  removeTimeslot: "Удалить таймслот",
  areYouSureRemoveTimeslot: "Вы уверены, что вы хотите удалить таймслот?",
  qrCode: "QR код",
  communication: "Инструмент",
  editCoverDescription: "Настройте обложку",
  editProfileData: "Редактировать данные профиля",
  editProfileDataDescription: "Добавьте информацию о себе и своем опыте",
  editPublicIDDescription:
    "Выберите уникальный публичный идентификатор для своего профиля",
  profilePhoto: "Фото профиля",
  publicIDDescription: "Это автоматически сгенерированный идентификатор!",
  publicIDWarning:
    "Не забудьте изменить его на что-нибудь уникальное в режиме редактирования, как, например, 'supergirl'.",
  followWarning: "Кнопка “Подписаться” будет работать для всех пользователей.",
  openChatWarning: "Вы не сможете пообщаться с собой, но ваши клиенты смогут.",
  bookingOfferWarning:
    "Пользователи смогут приобрести предложение, нажав здесь.",
  shareOffer: "Скопировать ссылку",
  turnOffAccount: "Выключить аккаунт",
  turnOnAccount: "Включить аккаунт",
  areYouSureYouWantTurnOff:
    "Вы уверены, что хотите полностью выключить ваш аккаунт?",
  areYouSureYouWantTurnOn: "Вы уверены, что хотите включить ваш аккаунт?",
  disableFreeChat: "Выключить бесплатный чат",
  enableFreeChat: "Включить бесплатный чат",
  areYouSureYOuWantDisableFreeChat:
    "Вы уверены, что хотите выключить бесплатный чат?",
  areYouSureYOuWantEnableFreeChat:
    "Вы уверены, что хотите включить бесплатный чат?",
  cancelEditing: "Отменить редактирование",
  descriptionCancelEditing: "Вы уверены, что хотите отменить редактирование?",
  takenPublicID: "Этот публичный идентификатор уже занят",
  charactersMatch: "2-64 символа",
  charactersBetweenMatch: "Поле должно содержать от 1 до 64 символов",
  charactersMaxMatch: "Максимальная длина поля - 64 символа",
  deletePhoto: "Удалить фото",
  descriptionDeletePhoto: "Вы уверены, что хотите удалить свою фотографию?",
  accountOnDescription:
    "Когда вы выключаете аккаунт, он стаёт недоступным любым способом (прямая ссылка, поиск и т.д.)",
  freechat: "Бесплатный чат",
  freechatDescription:
    "Когда вы выключаете бесплатный чат, пользователи не смогут начать коммуникацию с вами этим способом. При этом, вы сможете сделать это с вашей стороны с вашими клиентами.",

  editMessage: "Редактировать сообщение",
  replyTo: "Ответить",
  file: "Файл",

  backgroundWarning:
    "Внимание: фоны не полностью поддерживаются в браузерах Safari и Firefox и могут не работать.",
  single: "Быстрые",

  connectIn: "Подключиться через",
  connectOn: "Подключиться",
  sessionDecisionTittle: "Кроме вас никто не присоединился",

  cancelledSessionExpertDescription:
    "Платеж переведён на ваш счет. Приносим извинения за неудобства.",
  cancelledSessionClientDescription:
    "С вас не будет взиматься плата, и вы получите возврат средств. Приносим извинения за неудобства.",

  addExtraMinutes: "Добавить 10 минут",
  unableAddExtraMinutes: "Невозможно добавить 10 минут",
  disabledAddExtraMinutes: "Невозможно добавить 10 минут",
  disabledAddExtraMinutesDescription:
    "К сожалению, у вас запланирована сессия менее чем через 10 минут",
  monday: "Понедельник",
  tuesday: "Вторник",
  wednesday: "Среда",
  thursday: "Четверг",
  friday: "Пятница",
  saturday: "Суббота",
  sunday: "Воскресенье",
  h: "ч",
  sharingSetting: "Настройки доступа",
  call: "Звонок",
  public: "Публичное",
  byLinkOnly: "Только по ссылке",
  offerInfo: "Информация о предложении",
  coverVideo: "Обложка / видео",
  imageVideo: "Изображение / видео",
  chooseCountry: "Выберите страну",
  passwordChangedSuccessfully: "Пароль успешно изменён",
  connected: "Мой круг",
  passedALot: "Завершенные",
  chooseCountryBank: "Выберите страну вашего банка или финансового сервиса",
  createAccountContinue: "Создайте аккаунт, чтобы продолжить",
  metasignInTitle: "Вход | Waylight",
  metasignUpTitle: "Регистрация | Waylight",
  metapasswordForgotTitle: "Восстановление пароля | Waylight",
  metapasswordResetTitle: "Сброс пароля | Waylight",
  clientflowStep1Title: "Найдите ссылку в БИО или поиске Waylight",
  clientflowStep1:
    "Найдите ссылку Waylight вашего криэйтора, инфлюэнсера или эксперта в социальных сетях (Instagram, Facebook, YouTube, LinkedIn и т. д.) или введите имя или никнейм в поиске Waylight.",
  clientflowStep2Title:
    "Забронируйте видеозвонок или подпишитесь на блог или чат",
  clientflowStep2:
    "Откройте и изучите бизнес-профиль вашего криэйтора, инфлюэнсера или эксперта. Найдите интересное предложение для видеозвонка 1:1, забронируйте его и зайдите в него в назначенное время. Попробуйте пообщаться до или после, чтобы получить больше информации на долгосрочную перспективе. Подпишитесь на личный блог, с контентом только для подписчиков, туториалами, статьями, видео-советами, личным жизненным опытом, уроками, музыкой и т.д.",
  clientflowStep3Title: "Проверьте децентрализованные рейтинги и отзывы",
  clientflowStep3:
    "Децентрализованные рейтинги создают репутацию для авторов и экспертов, их нельзя купить или подделать и они создают чувство доверия, прозрачности, подтверждения опыта и спроса на контент для клиентов и подписчиков. Оставляйте свои реальные рейтинги после видеозвонков, чатов и в постах. Поделитесь своими отзывами, чтобы помочь сообществу Waylight выделить лучших.",
  confirmBooking: "Подтвердить выбор",
  free: "Бесплатно",
  unableVerifyEmail: "Ошибка при верификации вашего email.",
  thankYouForVerifiedEmail: "Cпасибо за верификацию вашего email!",
  reSend: "Повторить",
  signInToContinue: "Войдите, чтобы продолжить",
  switchingToClientView: "Переключение в режим клиента",
  switchingToExpertView: "Переключение в режим бизнеса",
  cookiePermission:
    'Мы ценим вашу конфиденциальность. Мы используем файлы cookie для улучшения вашего опыта, предоставления персонализированного контента и анализа нашего трафика. Нажимая "Принять", вы соглашаетесь на использование нами файлов cookie.',
  readMore: "Подробнее",
  requestSupport: "НАЖМИТЕ ниже, чтобы обратиться в службу поддержки:",
  notReadableDevicesWarning:
    "Внимание: ваша камера и/или микрофон в данный момент либо используются, либо к ним нет доступа!",
  howPayoutsWork: "Как работают выплаты",
  payoutsOnboarding1: "Завершите онбординг, чтобы включить выплаты.",
  payoutsOnboarding2:
    "Мы храним ваши деньги в безопасности до вашего успешного онбординга.",
  payoutsOnboarding3:
    "Вам понадобятся ваши банковские реквизиты или данные карты в зависимости от вашей страны.",
  payoutsOnboarding4: "Выплаты происходят каждый понедельник.",
  payoutsOnboarding5: "Вы можете проверить статус выплат в Транзакциях.",
  payoutsOnboarding6: "Платежная система взимает 0,25% + $0,25 за выплату.",
  payoutsOnboardingEcommpay6:
    "Платежный шлюз взимает 0,5% за карты и 0% за продавцов, если включены комиссии клиента.",
  payoutsOnboarding7:
    "Если вы получаете выплату впервые, перевод может занять 7–14 рабочих дней из-за ограничений платежной системы.",
  payoutsOnboarding8: "Обычно выплаты занимают около 3 рабочих дней.",
  payoutsOnboarding9:
    "Изменение страны в настоящее время невозможно. Свяжитесь с нами, если есть подобный запрос, и мы постараемся решить это в вашем конкретном случае.",
  payoutsOnboarding10:
    "Waylight взимает переменную комиссию в зависимости от вашего плана.",
  payoutsOnboarding11: "Если у вас есть вопросы, обращайтесь в",
  supportAlternative: "Поддержку",
  cancelledFreeSessionDescription:
    "Вы единственный, кто пришел. Приносим извинения за неудобства.",
  m: "м",
  cityNotFound: "Варианты не найдены. Выберите ближайший крупный город.",
  notifications: "Уведомления",
  telegramBot: "Телеграм-бот",
  notConnected: "не подключено",
  connectTelegramBot: "Подключить Телеграм-бот",
  disconnectTelegramBot: "Отключить Телеграм-бот",
  telegramConnected: "подключён",
  bgSettingsDescription:
    "Использовать эти настройки чтобы установить бэкграунд для ваших видео потоков",
  selectBackground: "Выбрать бэкграунд",
  bookingDelay: "Установить отложенное бронирование",
  bookingDelayDescription: "Укажите, насколько заранее вас можно бронировать.",
  processingFee: "Комиссия платёжной системы",
  payoutsOnboardingEcommpay1:
    "Выберите метод выплаты личной кредитной карты или частного предпринимательства.",
  payoutsOnboardingEcommpay3:
    "Вы должны либо добавить как минимум одну личную кредитную карту + свой индивидуальный налоговый номер, ЛИБО завершить регистрацию мерчанта в Fondy, чтобы транзакции работали!",
  payoutsOnboardingEcommpay4: "Выплаты происходят после каждой транзакции.",
  payoutsOnboardingEcommpay7:
    "Выплаты происходят не позднее следующего рабочего дня.",
  tutorials: "Продукты",
  tutorialsAndCourses: "Купить продукты",
  noOffers: "Похоже, здесь пока нет предложений",
  newTutorialOffer: "Новое предложение продукта",
  newTutorialOfferDescription: "Создайте платный или бесплатный продукт",
  tutorialName: "Название продукта",
  tutorialNameDescription:
    "Выберите название для вашего продукта, например, «Урок танцев №1»",
  cover: "Обложка",
  tutorialCoverTooltip:
    "Загрузите изображение или видео для отображения в описании продукта.",
  getTutorialPaid: "Вы получаете деньги за каждую покупку этого продукта.",
  unlockedAfterPurchase: "Доступ после покупки",
  unlockedAfterPurchaseDescription:
    "Добавьте контент, который ваши клиенты разблокируют.",
  tutorialDetailsDescription:
    "Добавьте комментарии, детали или инструкции здесь",
  removeSection: "Удалить раздел",
  addAnotherSection: "Добавить еще один раздел",
  details: "Детали",
  toastEmptyUnlockedContent: "Заблокированный контент не может быть пустым",
  toastTutorialNotEdited: "Этот продукт не был отредактирован",
  removeSectionDescription: "Вы уверены, что хотите удалить раздел?",
  buyFor: "Купить за",
  buttonOpenChatAlt: "Открыть чат",
  buttonShare: "Поделиться",
  tutorial: "Продукт",
  toastBuyingYourOwnTutorial: "Вы не можете покупать свой собственный продукт!",
  purchasingInProgress: "Транзакция в процессе",
  byClickingConfirm: "Нажимая",
  iAgreeWith: "я соглашаюсь с",
  allowWaylightToCharge:
    "и позволяю Waylight взимать платеж с моей карты сейчас и в будущем согласно условий подписки, пока я её не отменю.",
  paymentProcessingFees: "Могут взиматься комиссии за обработку платежей.",
  bookingTutorialWarning: "Пользователи смогут купить продукт, нажав здесь.",
  editTutorials: "Редактировать продукты",
  topReview: "Лучший отзыв",
  addForFree: "Добавить бесплатно",
  tutorialPaymentFailed:
    "Ваш платеж не прошел, и вы не смогли купить этот продукт.",
  buy: "Купить",
  tutorialWith: "продукт от",
  purchased: "Куплено",
  noTutorialsFound: "Продукты не найдены",
  noTutorialsFoundDescription:
    "Они появятся здесь, когда вы купите или добавите продукт",
  tags: "Теги",

  createTutorial: "Создать предложение продукта",
  createTutorialDescription: "Добавьте платный или бесплатный продукт.",
  requires_transfer: "Ожидает онбординга",
  pending: "В ожидании выплаты",
  requires_payout: "В ожидании выплаты",
  requires_capture: "На удержании",
  reschedule: "Перепланировать",
  rescheduleDelay: "Установите задержку перепланирования",

  buttonChange: "Перепланировать",
  timeslots: "Временные интервалы",
  specifyReason: "Укажите причину изменения даты и времени.",
  rescheduleReason: "Причина перепланирования",
  optionLink: "Добавить внешнюю ссылку",
  optionUpload: "Или загрузите файл",
  tutorialLinkDescription: "Добавьте ссылку на ваш продукт.",
  tutorialUploadDescription:
    "Загрузите любой файл здесь. Вы можете добавить один файл на раздел.",
  bgNone: "Нет",
  bgLowBlur: "Слабое размытие",
  bgMediumBlur: "Среднее размытие",
  bgHighBlur: "Сильное размытие",
  bgSolidColour: "Сплошной цвет",
  bgCustomImage: "Собственное изображение",
  telegramNotifications: "Уведомления в Telegram",
  connectYourTelegramAccount:
    "Подключите свой аккаунт Telegram, чтобы получать уведомления от Waylight.",
  buttonConnectTelegram: "Подключить Telegram",
  notificationGoToMyProfile: "Перейти в мой профиль",
  setupSchedule: "Настроить расписание",
  aboutTheTutorial: "О продукте",
  added: "Добавлено",
  orderingChangeInProgress: "Изменение порядка...",
  gloryToUkraine: "Слава Украине!",
  terroristStates:
    "Waylight не работает для пользователей из санкционированных стран с диктаторскими и террористическими режимами: Россия, Иран, Северная Корея, Сирия, Куба, Венесуэла, Афганистан.",
  never: "Без ограничений",
  expiration: "Экспирация",
  expirationDescription:
    "Клиенты будут иметь доступ к этому продукту в течение определенного количества дней",
  daysLeft: "дней осталось",
  tutorialExpired: "Ваш доступ к этому продукту истек.",
  days: "дней",
  tips: "Благодарности",
  newTipOffer: "Новое предложение благодарность",
  newTipOfferDescription: "Создать благодарность",
  tipName: "Название благодарности",
  tipNameDescription: "Выберите название для вашей благодарности",
  tipCoverTooltip:
    "Загрузите изображение или видео, которое будет отображаться в описании благодарности.",
  aboutTheTip: "Про благодарность",
  describeYourTip:
    "Добавьте описание благодарности: сбор пожертвований, благотворительность или просто кофе?",
  tipAmounts: "Добавьте три типичных суммы",
  tipAmountsDescription:
    "Ваши клиенты также смогут ввести свою собственную сумму, если они захотят.",
  bonusTipper: "(Не обязательно) Отправить бонус клиенту",
  bonusTipperDescription:
    "Добавьте контент или сообщение, которое клиенты получат в качестве бонуса после благодарности.",
  tipLinkDescription: "Добавьте ссылку на ваш бонус.",
  tipMessageDescription: "Добавьте ваше текстовое сообщение здесь.",
  toastTipNotEdited: "Эта благодарность не была отредактирована",
  leaveATip: "Поблагодарить",
  bookingTipWarning: "Пользователи смогут поблагодарить, нажав здесь.",
  includesAReward: "Включает бонус",
  selectTip: "Выберите, сколько вы хотите поблагодарить",
  selectTipAmount: "Выберите сумму благодарности",
  toastBuyingYourOwnTip: "Вы не можете поблагодарить сами себе!",
  tipSuccessfullyBought: "Благодарность успешно проведена",
  tip: "Благодарность",
  tipPaymentFailed: "Ваш платеж не прошел, и вы не смогли поблагодарить.",
  purchasedOffers: "Покупки",
  noTipsFound: "Благодарности не найдены",
  noTipsFoundDescription: "Они появятся здесь, когда вы поблагодарите",
  thankYouExclamation: "Спасибо!",
  editTips: "Редактировать благодарности",
  tipPriceLimit: "Цена может быть от ${{minPrice}} до ${{maxPrice}}.",
  tipPriceLimitForUA: "Цена может быть от ₴{{minPrice}} до ₴{{maxPrice}}.",
  cardAuthorization: "Авторизация карты",
  failed: "Ошибка",
  communityName: "Название сообщества",
  communityNameDescription:
    "Выберите название для вашего сообщества, например, «Мой эксклюзивный платный канал в Telegram»",
  getCommunityPaid: "Цена подписки",
  Period: "Период*",
  expirationCommunityDescription: "Установите продолжительность подписки",
  aboutTheCommunityOffer: "О предложении",
  TelegramChannelName: "Название канала/группы в Telegram*",
  communityTelegramNameDescription:
    "Выберите название вашего канала или группы. Если поле пустое, пожалуйста, нажмите на значок информации для получения инструкций.",
  communities: "Сообщества",
  community: "Сообщество",
  subscribeFor: "Подписаться за",
  subscribed: "Подписан",
  unsubscribe: "Отписаться",
  unsubscribeDescription:
    "Вы уверены, что хотите отписаться?\nВаш доступ истечет",
  subscribeDescription: "{{community}}.",
  usubscribed: "Отписан",
  unlimited: "Неограниченная",
  subscribeAgain: "Подписаться снова",
  unsubscribeDescriptionModal:
    "Вы уверены, что хотите подписаться снова?\nОплата за следующий период будет произведена",
  subscribe: "Подписаться",
  currentlyMember: "Вы являетесь участником {{title}} в Telegram.",
  notCurrentlyMember: "Вы не являетесь участником {{title}} в Telegram.",
  newCommunityOffer: "Новое предложение сообщества",
  newCommunityOfferDescription: "Создайте или управляйте сообществом",
  editCommunities: "Редактировать сообщества",
  joinCommunities: "Присоединиться к сообществам",
  noCommunitiesAdded: "Сообщества не добавлены",
  TheyWillAppear: "Они появятся здесь, когда вы создадите свои предложения.",
  createCommunity: "Создать сообщество",
  noAccessToData: "Нет доступа к данным! Добавьте",
  noAccessToDataAsAnAdministrator:
    "в качестве администратора в вашу группу, чтобы предоставить доступ.",
  retryPayment: "Повторить платеж",
  areYouSureYouWantToSetCard:
    "Вы уверены, что хотите установить карту *{{last4}} в качестве основной?",
  thingsToTry: "Попробуйте следующее:",
  checkIfYouHaveSufficient:
    "Проверьте, достаточно ли у вас средств на балансе или включены ли интернет-платежи.",
  selectDifferentPaymentMethod: "Выберите другой способ оплаты.",
  set: "Установить",
  primaryCard: "Основная",
  setPrimary: "Установить основной",
  inviteMember: "Пригласить участника",
  copyLink: "Скопировать ссылку",
  members: "Участники",
  cancel: "Отмена",
  actions: "ДЕЙСТВИЯ",
  subscribedUntil: "ПОДПИСАН ДО",
  waylightClients: "Клиент Waylight",
  nameMembers: "ИМЯ",
  admin: "Администратор",
  external: "Не авторизован",
  subscriber: "Подписчик",
  unsubscribed: "Отписан",
  kicked: "Исключен",
  owner: "Владелец",
  save: "Сохранить",
  kickAndBan: "Исключить из канала",
  areYouSureYouWantToRemove:
    "Вы уверены, что хотите удалить «{{name}}» из вашего канала?",
  WeHaveEncounteredAnIssue:
    "У нас возникла проблема с вашим регулярным платежом!",
  yourPaymentHasNotGone:
    "Ваш платеж не прошел, и мы не смогли повторить попытку списания средств за вашу подписку.",
  gatewayFee: "Клиенты оплачивают комиссию платежного шлюза",
  gatewayFeeDescription:
    "При включении этой настройки комиссия платежного шлюза будет добавлена к счетам клиентов. При отключении комиссия будет вычитаться из выплат бизнеса.",
  enableGatewayFee: "Включить комиссию платежного шлюза для клиентов?",
  areYouSureYOuWantEnableGatewayFee:
    "Вы уверены, что хотите включить комиссию платежного шлюза для клиентов?",
  disableGatewayFee: "Отключить комиссию платежного шлюза для клиентов?",
  areYouSureYOuWantDisableGatewayFee:
    "Вы уверены, что хотите отключить комиссию платежного шлюза для клиентов? Комиссия будет вычитаться из ваших выплат!",
  communityTooltip:
    "Убедитесь, что вы добавили Waylight Bot в вашу группу/канал в качестве администратора, подключили свою учетную запись Telegram к Waylight и это единственное предложение для этого канала!",
  communityCoverTooltip:
    "Загрузите изображение или видео, которое будет отображаться в описании сообщества.",
  setCard: "Установить",
  expirationDateMustBeInFuture: "Срок действия должен быть в будущем",
  accessGranted: "Доступ разрешен",
  noAccess: "Нет доступа",
  manageCommunity: "Управление сообществом",
  manage: "Управление",
  January: "Январь",
  February: "Февраль",
  March: "Март",
  April: "Апрель",
  May: "Май",
  June: "Июнь",
  July: "Июль",
  August: "Август",
  September: "Сентябрь",
  October: "Октябрь",
  November: "Ноябрь",
  December: "Декабрь",
  toastBuyingYourOwnCommunity:
    "Вы не можете подписаться на своё собственное сообщество!",
  priceLimitCommunity: "Цена может быть от ${{minPrice}} до ${{maxPrice}}.",
  priceLimitCommunityForUA:
    "Цена может быть от ₴{{minPrice}} до ₴{{maxPrice}}.",
  toastRetryPaymentSuccess: "Повторная попытка оплаты прошла успешно.",
  toastCommunityLinkCopied: "Ссылка на сообщество скопирована",
  communityPaymentFailed: "Не удалось выполнить платеж за сообщество",
  AddExpirationDateManually: "Добавить экспирацию вручную:",
  SetTimeFormat: "Установить формат времени",
  events: "События",
  newEventOffer: "Новое предложение о событии",
  newEventOfferDescription: "Создать запланированное событие",
  eventName: "Название события",
  eventNameDescription:
    "Выберите имя для вашего события, например, «Мастер-класс по SEO»",
  eventCoverTooltip: "Подсказка обложки события",
  getEventPaid: "Вы получаете оплату за каждую покупку мест на этом событии",
  aboutTheEventOffer: "О событии",
  dateAndTime: "Дата и время",
  clickToSetDateAndTime: "Нажмите, чтобы установить дату и время",
  DescribeYourEvent: "Кратко опишите ваше событие.",
  onlineEvent: "Онлайн-событие",
  offlineEvent: "Офлайн-событие",
  eventLinkDescription:
    "Добавьте информацию о месте события, таком как Zoom или Meet",
  uploadOptional: "Загрузить (по желанию)",
  uploadEventDetails:
    "Загрузите здесь любой файл с дополнительными деталями о событии",
  addInformationAboutLocation:
    "Добавьте информацию о местоположении события или ссылку на карту",
  location: "Ссылка на место",
  addressOptional: "Адрес (по желанию)",
  addAddressEvent: "Добавьте адрес события",
  AllDayEvent: "Это событие продолжается весь день.",
  toastBuyingYourOwnEvent: "You can not buy your own event!",
  event: "Событие",
  timeToEvent: "Время до события",
  hoursEvent: "часов",
  seatsLeft: "свободных мест",
  follower: "Подписчик",
  priceLimitEventForUA: "Цена может быть от ₴{{minPrice}} до ₴{{maxPrice}}.",
  soldOut: "Продано!",
  joinEvents: "Посетить события",
  noEventFound: "События не найдены",
  noEventsFoundDescription:
    "Они появятся здесь, когда вы посетите хотя бы одно событие",
  editEvents: "Редактировать события",
  attendForfree: "Посетить бесплатно",
  disabled: "Отключено",
  enabled: "Включено",
  personalCreditcard: "Личный (Кредитная карта)",
  privateEntrepreneur: "Частный предприниматель (IBAN)",
  selectPayoutMethod: "Выберите метод выплаты",
  enterYourPersonalTaxCode: "Введите свой личный налоговый код*",
  createFondyMerchantAccount:
    "Создайте учетную запись продавца в Fondy и завершите онбординг:",
  create: "Создать",
  participantsMin: "Минимальное количество участников - 1",
  personalTaxCodeError:
    "Индивидуальный налоговый код должен быть правильным числом!",
  participantsError: "Количество участников должно быть правильным числом!",
  peName: "Название ИП",
  url: "URL",
  okpo: "ОКПО",
  phone: "Телефон",
  peNameTooltip:
    "Официальное название вашего частного предпринимательства: 'Фамилия Имя Отчество'.",
  urlTooltip:
    "URL вашего бизнеса. Это может быть ваш веб-сайт, Facebook, Instagram и т. д.",
  okpoTooltip: "ОКПО-код вашего частного предпринимательства.",
  phonePayoutTooltip: "Ваш номер телефона.",
  paymentsTipStripe:
    "Платежи всегда доступны для пользователей Stripe. Вывод средств доступен после завершения онбординга.",
  paymentsTipFondy1:
    "Частные предприниматели: платежи активируются после создания мерчанта. Вывод средств доступен после завершения регистрации.",
  paymentsTipFondy2:
    "Кредитные карты: платежи и вывод средств активируются после добавления комбинации частной кредитной карты и индивидуального налогового кода.",
  phoneError: "Телефон должен быть правильным числом!",
  toastTaxCodeAdded: "Ваш индивидуальный налоговый код успешно добавлен.",
  toastTaxCodeError:
    "Произошла ошибка при попытке добавить ваш индивидуальный налоговый код!",
  toastPECodeAdded:
    "Ваш новый продавец Fondy успешно создан. Проверьте свою электронную почту, чтобы начать процесс интеграции!",
  toastPECodeError:
    "Произошла ошибка при попытке создать нового продавца Fondy!",
  onboarding: "Онбординг",
  notCompletedOnboarding: "Не завершен",
  completedOnboarding: "Завершен",
  completeOnboarding: "Завершить онбординг",
  onboardingTooltip:
    "Завершите онбординг в панели управления Fondy. Проверьте свою электронную почту на приглашение создать пароль, войдите в панель управления Fondy, загрузите необходимые документы и добавьте цифровую подпись. После одобрения ваш аккаунт будет активирован.",
  fondyMerchantAccountDetails: "Детали учетной записи Fondy",
  eventIsLife: "Прямой эфир!",
  created: "Инициирована",
  processing: "Обрабатывается",
  goToFondyDashboard: "Открыть ваш кабинет Fondy",
  youMustCompleteFondy:
    "Вы должны завершить регистрацию в Fondy, используя ссылку из email, который вы получили на:",
  chooseEventType: "Выберите тип события",
  addException: "Добавить исключение",
  manageScheduleExceptions: "Управление исключениями в расписании",
  addRemoveSpecificTime:
    "Добавьте или удалите конкретное время, когда вы доступны или недоступны.",
  exceptionType: "Тип исключения:",
  available: "Доступность",
  notAvailable: "Недоступность",
  yes: "Да",
  no: "Нет",
  removeException: "Удалить исключение",
  removeExceptionDesc: "Вы уверены, что хотите удалить исключение {{time}}?",
  NoExceptionsAdded: "Исключения не добавлены",
  availableAllDay: "Доступность на весь день.",
  notAvailableAllDay: "Недоступность на весь день.",
  exceptionDeleteSuccessfully: "Исключение успешно удалено",
  exceptionDeleteError: "Ваше исключение не может быть удалено",
  exceptionSavedSuccessfully: "Исключение успешно сохранено",
  exceptionSavedError: "Ваше исключение не может быть сохранено",
  addJoininstructions: "Добавить инструкции по присоединению",
  addJoininstructionsDesc:
    "Добавьте контент или сообщение, которое ваши клиенты увидят после покупки, когда они еще не присоединились к каналу",
  joinChannel: "Присоединиться к каналу",
  communityUploadDescription: "Загрузить изображение или видео.",
  detailsOptional: "Детали (опционально)",
  type: "Tип",
  youCannotAddPast:
    "Вы не можете добавить временной интервал, который заканчивается в прошлом",
  Sun: "Вс",
  Mon: "Пн",
  Tue: "Вт",
  Wed: "Ср",
  Thu: "Чт",
  Fri: "Пт",
  Sat: "Сб",
  toastEventNotEdited:
    "Произошла ошибка при попытке редактировать ваше событие!",
  refunded: "Возвращена",
  declined: "Отклонена",
  verified: "Верифицирована",
  transactionSuccessful: "Транзакция успешно выполнена",
  myTabs: "Мои табы",
  tabStatus: "{{tabName}} - {{status}}",
  controlOfferTabs:
    "Типы предложений, которые клиенты могут видеть на вашей главной странице:",
  onlyEnglishLetters: "Разрешены только английские буквы и цифры",
  notificationCompleteOnboarding: "Завершить Онбординг",
  notificationYourPaidOffersAreUnavailable:
    "Ваши платные предложения недоступны для ваших клиентов.",
  products: "Продукты",
  bookingAvailable: "Доступность бронирования",
  bookingAvailableDescription: "Укажите, доступно ли бронирование в целом.",
  availableKey: "Доступно",
  notAvailableKey: "Недоступно",
  paymentOccurs: "Платеж каждые",
  paymentOccursOneTime: "Одноразовый платеж.",
  eventIsOver: "Событие завершено",
  integrations: "Интеграции",
  externalAnalytics: "Внешняя аналитика",
  externalAnalyticsDescription:
    "Установите идентификаторы учетных записей внешней аналитики ниже.",
  participantsManage: "Участники",
  manageEvent: "Управление событием",
  noEventsAdded: "События не добавлены",
  createEvent: "Создать событие",
  shareEvent: "Поделиться событием",
  waylightProfile: "Waylight Профиль",
  tickedId: "ID билета",
  checkIn: "Чек-ин",
  checkedIn: "Использован",
  notCheckedin: "Не использован",
  codeIsValid: "Код действителен! Использован!",
  codeisInvalid: "Код недействителен! Пожалуйста, попробуйте ещё раз.",
  codeIsUsed: "Этот код уже использован!",
  buyMore: "Купить ещё",
  noParticipantsYet: "Пока нет участников",
  emptyDeteilsBuysTickets:
    "Они появятся здесь, когда кто-то купит билеты на ваше событие.",
  connectYourTelegram:
    "Подключите свой Telegram, чтобы получить доступ к каналу!",
  change: "Изменение",
  numberOfTickets: "Количество билетов:",
  selectPaymentMethodTitle: "Выбрать способ оплаты:",
  or: "- или -",
  addACard: "Добавить карту",
  subscriptionLevels: "Уровни подписки",
  subscriptionLevelsTooltip:
    "Добавьте до 3 уровней к вашей подписке на сообщество. Например: ежемесячно, ежеквартально и ежегодно.",
  levelName: "Название уровня",
  starter: "Стартовый",
  pro: "Профессиональный",
  levelDiscription: "Название уровня, отображаемое клиентам",
  period: "Период",
  periodDiscription: "Клиенты будут оплачивать подписку с этим интервалом",
  priceDiscription: "Вы получаете оплату в указанные интервалы",
  removeLevel: "Удалить уровень",
  addAnotherLevel: "Добавить еще один уровень",
  noTrial: "Без пробного периода",
  trialDiscription: "Количество дней бесплатного доступа для ваших клиентов",
  trialPeriod: "Пробный период:",
  selectSubscription: "Выбрать подписку:",
  subscription: "Подписка",
  removeLevelDescription: "Вы уверены, что хотите удалить уровень?",
  tryForFree: "Попробовать бесплатно",
  startTrial: "Начать trial",
  subscribedSuccessfully: "Подписка успешно оформлена",
  edit: "Редактировать",
  expirationColumName: "Экспирация",
  kick: "Исключить",
  trial: "Пробный период",
  notJoined: "Не присоединён",
  telegramProfile: "Профиль Telegram",
  notAuthorized: "Не авторизован",
  noMembersFoundFilter: "Участники по установленному фильтру не найдены.",
  tryADifferentFilter: "Попробуйте другой фильтр или введите другой запрос.",
  "not joined": "Не присоединился",
  "not authorized": "Не авторизован",
  membership: "Членство",
  priceDiscriptionEvents:
    "Вы получаете оплату за каждую покупку мест на этом событии",
  fanZone: "Фан-зона",
  vipZone: "VIP-зона",
  selectTickets: "Выберите билеты:",
  attendance: "Участие",
  eventLevels: "Типы билетов",
  noTicketsSelected: "Билеты не выбраны",
  SetMaximumNumberOfSeats:
    "Установите максимальное количество мест для этого уровня",
  participants: "Количество мест",
  import: "Импорт",
  export: "Экспорт",
  labelNameDescription: "Выберите лейбл для ваших импортированных данных",
  hidden: "скрыто",
  upload: "Загрузить",
  labelName: "Название лейбlа",
  fileIsRequired: "Вы должны загрузить файл!",
  code: "код",
  level: "Уровень",
  label: "Лейбл",
  exportFileError: "Экспорт не удался!",
  exportFileSuccess: "Файл успешно экспортирован.",
  importFileSuccess: "Файл успешно импортирован.",
  importFileError: "Импорт не удался!",
  statsSuccess: "Статистика чек-ина успешно загружена.",
  statsError: "Произошла ошибка при загрузке статистики чек-ина!",
  uploadAText: "Простой текст или файл csv до 50 МБ",
  onlyTextFilesAllowed: "Разрешены только текстовые файлы или csv!",
  toastCommunityNotEdited: "Ошибка при редактировании сообщества",
  toastYouCanSelectOnlyImage:
    "Вы можете выбрать только одно изображение для заголовка",
  sections: "Секции",
  logInOrSignup: "Войдите или зарегистрируйтесь в Waylight ниже",
  continue: "Продолжить",
  invalidEmail: "Невалидный email",
  enterCode: "Введите код",
  haveSentCodeTo: "Мы отправили код на",
  paste: "Вставить",
  resendCodeIn: "Повторно отправить код... (через {{time}} секунд)",
  resendCode: "Повторно отправить код",
  waylight: "Waylight",
  pleaseChooseCountry: "Выберите страну вашего банка или финансовой службы.",
  buyAccessFor: "Купить доступ за",
  thankYouForFollowing: "Спасибо, что подписались!",
  incorrectCode: "Код неверен или устарел!",
  enterEmailToContinue: "Введите адрес электронной почты, чтобы продолжить",
  city: "Город",
  social: "Соцсети",
  earned: "суммарно",
  priceLimitARS:
    "Цена может быть ARS$ 0, или от ARS$ {{minPrice}} до ARS$ {{maxPrice}}.",
  priceLimitAUD:
    "Цена может быть A$ 0, или от A$ {{minPrice}} до A$ {{maxPrice}}.",
  priceLimitEUR: "Цена может быть €0, или от €{{minPrice}} до €{{maxPrice}}.",
  priceLimitBOB:
    "Цена может быть Bs. 0, или от Bs. {{minPrice}} до Bs. {{maxPrice}}.",
  priceLimitBGN:
    "Цена может быть лв 0, или от лв {{minPrice}} до лв {{maxPrice}}.",
  priceLimitCAD:
    "Цена может быть CA$ 0, или от CA$ {{minPrice}} до CA$ {{maxPrice}}.",
  priceLimitCLP:
    "Цена может быть CL$ 0, или от CL$ {{minPrice}} до CL$ {{maxPrice}}.",
  priceLimitCOP:
    "Цена может быть COL$ 0, или от COL$ {{minPrice}} до COL$ {{maxPrice}}.",
  priceLimitCRC: "Цена может быть ₡0, или от ₡{{minPrice}} до ₡{{maxPrice}}.",
  priceLimitHRK:
    "Цена может быть kn 0, или от kn {{minPrice}} до kn {{maxPrice}}.",
  priceLimitCZK:
    "Цена может быть Kč 0, или от Kč {{minPrice}} до Kč {{maxPrice}}.",
  priceLimitDKK:
    "Цена может быть kr 0, или от kr {{minPrice}} до kr {{maxPrice}}.",
  priceLimitDOP:
    "Цена может быть RD$ 0, или от RD$ {{minPrice}} до RD$ {{maxPrice}}.",
  priceLimitHKD:
    "Цена может быть HK$ 0, или от HK$ {{minPrice}} до HK$ {{maxPrice}}.",
  priceLimitEGP:
    "Цена может быть EGP 0, или от EGP {{minPrice}} до EGP {{maxPrice}}.",
  priceLimitHUF:
    "Цена может быть Ft 0, или от Ft {{minPrice}} до Ft {{maxPrice}}.",
  priceLimitISK:
    "Цена может быть kr 0, или от kr {{minPrice}} до kr {{maxPrice}}.",
  priceLimitINR: "Цена может быть ₹0, или от ₹{{minPrice}} до ₹{{maxPrice}}.",
  priceLimitIDR:
    "Цена может быть Rp 0, или от Rp {{minPrice}} до Rp {{maxPrice}}.",
  priceLimitILS: "Цена может быть ₪0, или от ₪{{minPrice}} до ₪{{maxPrice}}.",
  priceLimitUYU:
    "Цена может быть $U 0, или от $U {{minPrice}} до $U {{maxPrice}}.",
  priceLimitGBP: "Цена может быть £0, или от £{{minPrice}} до £{{maxPrice}}.",
  priceLimitTRY: "Цена может быть ₺0, или от ₺{{minPrice}} до ₺{{maxPrice}}.",
  priceLimitTTD:
    "Цена может быть TT$ 0, или от TT$ {{minPrice}} до TT$ {{maxPrice}}.",
  priceLimitTHB: "Цена может быть ฿0, или от ฿{{minPrice}} до ฿{{maxPrice}}.",
  priceLimitCHF:
    "Цена может быть CHF 0, или от CHF {{minPrice}} до CHF {{maxPrice}}.",
  priceLimitSEK:
    "Цена может быть kr 0, или от kr {{minPrice}} до kr {{maxPrice}}.",
  priceLimitZAR:
    "Цена может быть R 0, или от R {{minPrice}} до R {{maxPrice}}.",
  priceLimitSGD:
    "Цена может быть SGD$ 0, или от SGD$ {{minPrice}} до SGD$ {{maxPrice}}.",
  priceLimitSAR:
    "Цена может быть SAR 0, или от SAR {{minPrice}} до SAR {{maxPrice}}.",
  priceLimitRON:
    "Цена может быть lei 0, или от lei {{minPrice}} до lei {{maxPrice}}.",
  priceLimitPLN:
    "Цена может быть zł 0, или от zł {{minPrice}} до zł {{maxPrice}}.",
  priceLimitPHP:
    "Цена может быть ₱ 0, или от ₱ {{minPrice}} до ₱ {{maxPrice}}.",
  priceLimitPEN:
    "Цена может быть S/. 0, или от S/. {{minPrice}} до S/. {{maxPrice}}.",
  priceLimitPYG:
    "Цена может быть ₲ 0, или от ₲ {{minPrice}} до ₲ {{maxPrice}}.",
  priceLimitNOK:
    "Цена может быть kr 0, или от kr {{minPrice}} до kr {{maxPrice}}.",
  priceLimitNZD:
    "Цена может быть NZ$ 0, или от NZ$ {{minPrice}} до NZ$ {{maxPrice}}.",
  priceLimitMXN:
    "Цена может быть MX$ 0, или от MX$ {{minPrice}} до MX$ {{maxPrice}}.",
  priceLimitKES:
    "Цена может быть KSh 0, или от KSh {{minPrice}} до KSh {{maxPrice}}.",
  linkInBIOtool: "Инструмент Link in BIO",
  thatHelpsYouEarn: "который помогает зарабатывать!",
  earnMore: "Зарабатывайте больше",
  withWaylight: "с Waylight:",
  descriptionEarn: "Увеличьте свой доход от социальных сетей в 10 раз!",
  descriptionEarnPart: "Запуск всего за 2 минуты!",
  earnOnPaidCommunities: "Зарабатывайте на платных сообществах.",
  createAndManage:
    "Создавайте и управляйте подписками на сообщества в Telegram.",
  EarnPaidDigital: "Зарабатывайте на платных цифровых продуктах.",
  hostAndManage:
    "Добавляйте и управляйте курсами, обучающими материалами, электронными книгами, руководствами, файлами и т.д.",
  earnOnline: "Зарабатывайте на онлайн и офлайн-событиях.",
  sellTicketsAndManage:
    "Продавайте билеты и управляйте онлайн и офлайн групповыми событиями.",
  EarnServices: "Зарабатывайте на платных услугах.",
  sellManage: "Продавайте и управляйте потоком ваших услуг.",
  earnOnlineVideoSessions: "Зарабатывайте на платных онлайн-видеосессиях.",
  EarnAppointments: "Зарабатывайте на платных личных встречах.",
  ScheduleSignups:
    "Создавайте расписание и получайте заявки на ваши личные или групповые встречи 1 на 1.",
  earnTipping: "Зарабатывайте на благодарностях.",
  getTippingSupport:
    "Получайте благодарности в поддержку ваших каналов или за размещение контента в социальных сетях.",
  youWilGet: "Вы получите",
  tools: "1. Инструменты",
  sales: "2. Продажи",
  clientsGet: "3. Клиентов",
  howItWorks: "Как это работает",
  GetClients: "Получайте новые продажи и клиентов",
  useWaylightGrowth:
    "Используйте инструменты роста и советы Waylight, чтобы продавать больше и привлекать новых клиентов из социальных сетей.",
  createWaylight: "Создать Waylight",
  creatorsExperts: "Криэйторов и экспертов",
  interactionsMonthly: "Ежемесячных взаимодействий",
  monthOverMonth: "MoM рост",
  forWhom: "Для кого:",
  pricing: "Цены:",
  chooseYourPlan: "Выберите свой план",
  saveWhenChoosingYearlyPlan: "Экономьте больше на годовом плане!",
  monthlyBilling: "Ежемесячный",
  annuallyBilling: "Ежегодный",
  freePlan: "Бесплатный",
  startLowestCosts: "Начните с минимальных затрат и исследуйте Waylight",
  mo: "/мес.",
  signUp: "Зарегистрируйтесь",
  impressionMo: "{{comission}}% комиссии за транзакции.",
  basicToolsIncluded: "Включены основные инструменты!",
  basic: "Базовый",
  getEverythingBasic:
    "Получите все из бесплатного плана с пониженной комиссией",
  contactSales: "Связаться с отделом продаж",
  transactionFees: "2% комиссии за транзакции.",
  publishingMarketplace: "Публикация в поиске маркетплейса.",
  saveBadge: "Экономия {{percent}}%",
  bestValue: "Лучшая цена",
  GetMoreSales: "Получите больше продаж из социальных сетей без комиссий",
  ziroTransactionFees: "0% комиссии за транзакции.",
  proToolsIncluded: "Включены профессиональные инструменты!",
  customizations: "Настройки.",
  verifiedSign: "Значок 'проверено'.",
  prioritySupport: "Приоритетная поддержка.",
  launchExpert: "Помощь Эксперта по запускам.",
  askTelegram: "Спросите в Telegram",
  askWhatsApp: "Спросите в WhatsApp",
  askEmail: "Спросите по Email",
  MakeTenX: "Зарабатывайте в 10 раз больше",
  onYourSocialMedia: "на вашем социальном медиа",
  revenue: "доходе! 🎉",
  launchJust: "Запускайтесь всего за",
  twoMinutes: "2 минуты!",
  product: "Продукт:",
  customersDataManagement: "Управление данными клиентов",
  reviewsProduct: "Отзывы",
  portfolioProduct: "Портфолио",
  businessWebsite: "Бизнес-сайт",
  securePayments: "Безопасные платежи",
  tipping: "Благодарности",
  paidAppointments: "Платные личные встречи",
  paidServices: "Платные услуги",
  ticketsOnlineEvents: "Билеты на онлайн-мероприятия",
  ticketsScanner: "Сканер билетов",
  ticketsPersonEvents: "Билеты на оффлайн мероприятия",
  paidAccessCourses: "Платный доступ к курсам",
  paidAccessDigitalProducts: "Платный доступ к цифровым продуктам",
  paidGroupChannelAccessTelegram:
    "Платный доступ к группам и каналам в Telegram",
  paidSubscriptionCommunitiesTelegram:
    "Платные подписки на сообщества в Telegram",
  productMenuItem: "Продукт",
  PricingMenuItem: "Цены",
  Creator: "Криэйтор",
  Freelancer: "Фрилансер",
  Tutor: "Репетитор",
  Coach: "Коуч",
  Influencer: "Инфлюенсер",
  ask: "Задать",
  anyQuestions: "любые вопросы здесь:",
  how: "Как",
  itWorks: "это работает",
  businessflowStep1new:
    "Добавьте свою ссылку Waylight в BIO социальных сетей и начните привлекать аудиторию и клиентов, делясь историями и постами об этом.",
  businessflowStep2new:
    "Используйте инструменты Waylight и советы по росту, чтобы продавать больше и привлекать новых клиентов из социальных сетей.",
  businessCalculatorNew: "Калькулятор дохода Waylight",
  howMuchMoneyNew: "Узнайте, сколько денег подписчики принесут вашему бизнесу.",
  estimateNumberNew: "1. Оцените количество клиентов и подписчиков",
  howMuchYouChargeNew: "2. Сколько стоит предложение",
  for: "Для",
  whom: "кого",
  getEverythingBasicNew:
    "Получите все в базовом варианте с пониженной комиссией",
  startLowestCostsNew: "Начинайте без затрат и изучайте Waylight",
  GetMoreSalesNew:
    "Получите больше продаж из своих социальных сетей без комиссий",
  onYourSocialMediaNew: "на ваших социальных сетях! 🎉",
  accordionv2label0: "В каких странах вы работаете?",
  accordionv2text0:
    "Waylight Business работает в 55+ странах на 5 континентах. Вы можете обратиться в нашу службу поддержки относительно вашей страны или просто попробовать зарегистрироваться. Клиенты могут оплачивать ваши предложения из 150+ стран мира.",
  accordionv2label1: "Как я буду получать выплаты?",
  accordionv2text1:
    "Вы будете получать выплаты на свой банковский счет каждый понедельник. Подключение вашего банковского счета к Waylight Business занимает менее 1 минуты.",
  accordionv2label2: "Какие инструменты включены в стоимость?",
  accordionv2label3: "У вас есть Apple Pay, Google Pay, PayPal?",
  accordionv2text3:
    "Да, у нас есть. Мы называем это «Smooth Checkout». Waylight работает над автоматизацией потоков продаж с более высокими конверсионными показателями, поэтому у нас есть 1-click-checkout (с PayPal на подходе).",
  accordionv2label4: "Как Waylight поможет мне увеличить продажи в 10 раз?",
  accordionv2text4:
    "Вы получите доступ к двигателю роста продаж и академии монетизации, которые помогут вам продавать больше и привлекать больше клиентов из ваших социальных медиа. Кроме того, Waylight уже создал автоматизированные потоки и инструменты, которые обеспечивают более высокие конверсионные показатели.",
  accordionv2label5: "Что мне нужно, чтобы начать?",
  accordionv2text5:
    "Это легко 👌 Зарегистрируйтесь, добавьте свой банковский счет, создайте предложения, присоединитесь к академии монетизации, добавьте ссылки в социальных медиа и начните зарабатывать все больше и больше денег.",
  connectWaylight: "Связаться в Waylight!",
  toastTooLargeFileTwilio:
    "Этот файл слишком большой. Максимальный размер файла - 150 Мб.",
  enableSold: "Включить индикаторы продаж предложений?",
  areYouSureYOuWantEnableSold:
    "Вы уверены, что хотите включить индикаторы продаж предложений? Значения продаж будут видны всем пользователям.",
  disableSold: "Отключить индикаторы продаж предложений?",
  areYouSureYOuWantDisableSold:
    "Вы уверены, что хотите отключить индикаторы продаж предложений?",
  soldEnabled: "Индикаторы продаж предложений включены",
  soldEnabledDescription:
    "Когда эта настройка включена, значения продаж отображаются всем пользователям.",
  tipOrCommunitypriceLimitARS:
    "Цена может быть от ARS$ 1879.31 до ARS$ 9396554.46.",
  tipOrCommunitypriceLimitAUD: "Цена может быть от A$ 3.01 до A$ 15097.1",
  tipOrCommunitypriceLimitEUR: "Цена может быть от €1.81 до €9070.",
  tipOrCommunitypriceLimitBOB: "Цена может быть от Bs. 13.81 до Bs. 69119.48.",
  tipOrCommunitypriceLimitBGN: "Цена может быть от лв 3.55 до лв 17750.81.",
  tipOrCommunitypriceLimitCAD: "Цена может быть от CA$ 2.74 до CA$ 13718.98.",
  tipOrCommunitypriceLimitCLP:
    "Цена может быть от CL$ 1864.56 до CL$ 9321644.2.",
  tipOrCommunitypriceLimitCOP:
    "Цена может быть от COL$ 8072.11 до COL$ 40360576.84.",
  tipOrCommunitypriceLimitCRC: "Цена может быть от ₡1055.02 до ₡5277582.56.",
  tipOrCommunitypriceLimitHRK: "Цена может быть от kn 36 до kn 70472.",
  tipOrCommunitypriceLimitCZK: "Цена может быть от Kč 45.71 до Kč 228558.",
  tipOrCommunitypriceLimitDKK: "Цена может быть от kr 13.54 до kr 67719.",
  tipOrCommunitypriceLimitDOP: "Цена может быть от RD$ 295 до RD$ 588928.",
  tipOrCommunitypriceLimitHKD: "Цена может быть от HK$ 15.58 до HK$ 77915.",
  tipOrCommunitypriceLimitEGP: "Цена может быть от EGP 154.75 до EGP 309501.",
  tipOrCommunitypriceLimitHUF: "Цена может быть от Ft 716 до Ft 3579217.",
  tipOrCommunitypriceLimitISK: "Цена может быть от kr 684 до kr 1369300.",
  tipOrCommunitypriceLimitINR: "Цена может быть от ₹167.89 до ₹839435.",
  tipOrCommunitypriceLimitIDR: "Цена может быть от Rp 78852 до Rp 157703833.",
  tipOrCommunitypriceLimitILS: "Цена может быть от ₪19 до ₪36657.",
  tipOrCommunitypriceLimitUYU: "Цена может быть от $U 81 до $U 402925.",
  tipOrCommunitypriceLimitGBP: "Цена может быть от £1.56 до £7779.95.",
  tipOrCommunitypriceLimitTRY: "Цена может быть от ₺67.06 до ₺335289.19.",
  tipOrCommunitypriceLimitTTD: "Цена может быть от TT$ 13.58 до TT$ 67917.76.",
  tipOrCommunitypriceLimitTHB: "Цена может быть от ฿69.82 до ฿349109.6.",
  tipOrCommunitypriceLimitCHF: "Цена может быть от CHF 1.73 до CHF 8647.",
  tipOrCommunitypriceLimitSEK: "Цена может быть от kr 20.82 до kr 104092.39.",
  tipOrCommunitypriceLimitZAR: "Цена может быть от R 94 до R 186359.",
  tipOrCommunitypriceLimitSGD: "Цена может быть от SGD$ 2.63 до SGD$ 13146.69.",
  tipOrCommunitypriceLimitSAR: "Цена может быть от SAR 19 до SAR 37496.",
  tipOrCommunitypriceLimitRON: "Цена может быть от lei 9.04 до lei 45196.94.",
  tipOrCommunitypriceLimitPLN: "Цена может быть от zł 7.8 до zł 39008.41.",
  tipOrCommunitypriceLimitPHP: "Цена может быть от ₱ 281 до ₱ 560255.",
  tipOrCommunitypriceLimitPEN: "Цена может быть от S/. 19 до S/. 38040.",
  tipOrCommunitypriceLimitPYG: "Цена может быть от ₲ 36387 до ₲ 72772680.",
  tipOrCommunitypriceLimitNOK: "Цена может быть от kr 21.38 до kr 106903.",
  tipOrCommunitypriceLimitNZD: "Цена может быть от NZ$ 3.33 до NZ$ 16646.",
  tipOrCommunitypriceLimitMXN: "Цена может быть от MX$ 37.92 до MX$ 189595.",
  tipOrCommunitypriceLimitKES: "Цена может быть от KSh 803 до KSh 1605000.",

  authorizeToAccess: "Авторизируйтесь, чтобы получить доступ к",
  noCommunitiesFound: "Сообщества не найдены",
  noCommunitiesFoundDescription:
    "Они появятся здесь, когда вы купите или добавите сообщество",
  following: "Избранные",
  appointments: "Встречи",
  share: "Поделиться",
  addOffer: "Добавить предложение",
  aboutMe: "Обо мне",
  estimatedAmount:
    "Общая сумма успешных переводов, сделанных на ваш подключенный аккаунт Stripe в его валюте по умолчанию.",
  ConnectYourTelegram: "Подключите свой Telegram:",
  ConnectYourTelegramAccount:
    "Подключите свой аккаунт в Telegram, чтобы получать уведомления от создателей, которых вы следите.",
  connectTelegram: "Подключить Telegram",
  buyAgain: "Купить снова",
  purchases: "Покупки",
  videoGuide: "Видео-руководство",
  businessBilling: "Бизнес оплаты",
  noPaymentMethodsAdded: "Не добавлены способы оплаты",
  yourPaymentMethods: "Ваши способы оплаты",
  yourPlan: "Ваш план",
  yearlyBilling: "Ежегодно",
  selectFree: "Выбрать Бесплатный",
  whatIncluded: "Что включено",
  selectStarter: "Выбрать Базовый",
  selectPro: "Выбрать Профессиональный",
  enterPromoCode: "Введите промокод:",
  apply: "Применить",
  selected: "Выбрано",
  waylightSubscription: "Подписка Waylight",
  cashbackRefferals: "Рефералы",
  referrals: "Партнерская программа",
  shareLink: "Поделиться ссылкой",
  howReferralsWork: "Как работают рефералы",
  cashback: "Кэшбэк",
  yourCashbackCode: "Ваш код кэшбэка",
  yourCustomPartnerReferralCode: "Ваш индивидуальный партнерский код",
  howCashbackWork: "Как работает кэшбэк",
  toastPromoCodeSuccess: "Ваш промокод успешно применен!",
  toastPromoCodeError: "При применении вашего промокода произошла ошибка!",
  enterPromoCodeSignUp: "Введите промокод (необязательно):",
  enterPromoCodeBtn: "+ Введите промокод",
  billingBusinessTooltip: "Выберите свой план ниже.",
  referralTooltip: "Это ваш уникальный код кэшбэка.",
  cashbackTooltip: "Это ваш уникальный партнерский код.",
  currentPlan: "Текущий план:",
  neverExpires: "Без ограничений",
  expires: "Истекает:",
  emptyBillingBusinessHistoryDescription:
    "emptyBillingBusinessHistoryDescription",
  notificationGoToBillingBusiness:
    "У нас возникла проблема с оплатой вашей подписки! ",
  WeHaveEncounteredAnIssueSubscription:
    "У нас возникла проблема с оплатой вашей подписки",
  checkIfYouHaveSufficientSubscription:
    "Проверьте, достаточно ли у вас средств на счету или включены ли интернет-платежи.",
  selectDifferentPaymentMethodSubscription: "Выберите другой способ оплаты.",
  referralsDescriptions1:
    "Спасибо вам за то, что стали нашим ценным партнером!",
  referralsDescriptions2:
    "Завершите онбординг, чтобы получать выплаты по партнерской программе. Мы храним ваши деньги в безопасности, пока вы не завершите онбординг.",
  referralsDescriptions3:
    "Вам понадобятся реквизиты вашего банка или реквизиты вашей карты в зависимости от вашей страны.",
  referralsDescriptions4:
    "Выплаты происходят каждый понедельник. Вы можете проверить статусы выплат в разделе Транзакции.",
  referralsDescriptions5:
    "Ваш партнерский код предоставляет дополнительные скидки на подписки Waylight.",
  referralsDescriptions6:
    "Ваши скидки и ставки распределения доходов настраиваются на основе нашего соглашения.",
  referralsDescriptions7:
    "Если это ваш первый раз получения выплаты, она может занять от 7 до 14 рабочих дней на доставку из-за ограничений шлюза. Обычно выплаты занимают около 3 рабочих дней.",
  cashbackDescriptions1: "Спасибо вам за участие в нашей программе кэшбэка!",
  cashbackDescriptions2:
    "Завершите онбординг, чтобы получать выплаты по партнерской программе. Мы храним ваши деньги в безопасности, пока вы не завершите онбординг.",
  cashbackDescriptions3:
    "Вам понадобятся реквизиты вашего банка или реквизиты вашей карты в зависимости от вашей страны.",
  cashbackDescriptions4:
    "Выплаты происходят каждый понедельник. Вы можете проверить статусы выплат в разделе Транзакции.",
  cashbackDescriptions5:
    "Поделитесь вашей ссылкой и получайте распределение доходов от новых клиентов.",
  cashbackDescriptions6:
    "Ваш кэшбэк составляет 20-30% от всех комиссий Waylight в течение 1 года (30% только от годовых подписок Pro).",
  cashbackDescriptions7:
    "Если это ваш первый раз получения выплаты, она может занять от 7 до 14 рабочих дней на доставку из-за ограничений шлюза. Обычно выплаты занимают около 3 рабочих дней.",
  invalidGTMiD:
    "Недопустимый идентификатор GTM. Введите только ваш идентификатор, а не код, например: GTM-AA11BB2.",
  invalidMetaPixelID:
    "Недопустимый идентификатор Meta Pixel. Введите только ваш идентификатор, а не код, например: 123456789012345.",
  invalidHotjarID:
    "Недопустимый идентификатор Hotjar. Введите только ваш идентификатор, а не код, например: 1112223.",
  accountCreated: "Аккаунт создан",
  toastOUrlCopied: "Ссылка реферала успешно скопирована!",
  YouAreOnFreePlan: "Вы уже находитесь на бесплатном плане",
  referralCodeCanNotBeUsed:
    "Этот реферальный код не может быть использован для вашей текущей страны учетной записи: {{country}}",
  createWaylightBusiness: "Создать Waylight Business",
  pleaseChooseCountryBank:
    "Выберите страну вашего банка или финансовой службы:",
  referralsDescriptionsUA4:
    "Выплаты происходят после каждой транзакции. Вы можете проверить статусы выплат в разделе Транзакции.",
  referralsDescriptionsUA7:
    "Выплаты происходят не позднее следующего рабочего дня.",
  cashbackDescriptionsUA4:
    "Выплаты происходят после каждой транзакции. Вы можете проверить статусы выплат в разделе Транзакции.",
  cashbackDescriptionsUA7:
    "Выплаты происходят не позднее следующего рабочего дня.",
  issueSubscriptionPayment: "У нас возникла проблема с оплатой вашей подписки!",
  revenueSharhingSelectPE:
    "Партнерская программа доступна только для активированных ПП-счетов в Украине.",
  youCanChangeYourAccount:
    "Вы можете изменить тип вашей учетной записи в настройках.",
  yourReferralCodeWillAppear:
    "Ваш реферальный код появится здесь, как только вы завершите онбординг ПП.",
  goToPayoutSettings: "Перейти к настройкам выплат",
  commission: "Комиссия",
  youAreOnSamePlan:
    "Вы уже находитесь на том же плане, который вы пытаетесь выбрать! Пожалуйста, выберите другой план.",
  onlyCyrillicLettersAllowed: "Разрешены только кириллические буквы",
  dragToChangePosition: "Перетащите для изменения позиции",
  eventsQrcode: "Короткие коды событий",
  eventsQrcodeDescription:
    "Когда этот параметр включен, ваши билеты на события будут генерироваться с короткими 4-значными PIN-кодами и QR-кодами. Когда выключен, будут генерироваться более безопасные 8-значные PIN-коды с штрих-кодами.",
  areYouSureYOuWantEnableQrcode:
    "Вы уверены, что хотите включить короткие PIN-коды событий с QR-кодами?",
  areYouSureYOuWantDisableQrcode:
    "Вы уверены, что хотите отключить короткие PIN-коды событий и вместо них генерировать штрих-коды?",
  enableEventsQrcode: "Включить короткие коды событий",
  disableEventsQrcode: "Отключить короткие коды и переключиться на штрих-коды",
  authorizeScanner: "Авторизовать сканер",
  toastScannerUrlCopied: "Скопирован URL сканера",
  total: "Всего:",
  gtmIdDescription: "Локальный GTM для этого предложения.",
  metaPixelIdDescription: "Локальный Meta Pixel для этого предложения.",
  tutorialBoughtPreview:
    "Это предварительный просмотр приобретённого состояния вашего продукта.",
  tutorialNotBoughtPreview:
    "Так выглядит ваш продукт для клиентов, когда он ещё не куплен.",
  authorizeToAccessPurchases:
    "Авторизуйтесь, чтобы получить доступ к вашим покупкам!",
  myPromotions: "Мой промо-код",
  describeTheDetailsPromotions:
    "Выберите предложение, укажите название, выберите URL и добавьте детали акции ниже",
  promotionDropdownDescription:
    "Выберите ваше предложение из списка. Вы не сможете изменить его позже для этого промокода.",
  offerNamePromotions: "Предложение",
  promotionsTooltip:
    "Выберите предложение, для которого будет применяться этот промокод. Вы можете создать столько других промокодов для этого предложения, сколько пожелаете.",
  offerNameDescriptionPromotions:
    "Введите название акции. Эта информация для внутреннего учёта.",
  codePromotionsDescription:
    "Введите или сгенерируйте уникальный промокод, используя английские буквы и цифры.",
  expirationPromotions: "Срок действия",
  name: "Название",
  codePromotions: "Код",
  usersNumDescription:
    "Этот промокод может быть использован указанное количество раз.",
  max_uses: "Количество использований",
  pricingPromotionsDiscription: "pricingPromotionsDiscription",
  discount: "Скидка",
  discountTitle: "Скидка(и):",
  selectOfferDiscounts:
    "Выберите предложение выше, чтобы начать управлять скидками для него.",
  promoNameCanNotBeEmpty: "Название акции не может быть пустым",
  urlIsAlreadyTaken: "Этот URL уже занят!",
  promoCodeValidation:
    "Промокод может содержать только заглавные и строчные английские буквы и цифры от 0 до 9!",
  promotions: "Промокода",
  enterPromoCodePromotions: "Введите промокод",
  promoCodeIncorrectExpired: "Этот промокод неверен или истек!",
  selectedPromoCodeNotAvailable: "Выбранный промокод недоступен!",
  weWereUnableToProcessYourTransaction:
    "Мы не смогли обработать вашу транзакцию с выбранным промокодом из-за истечения срока его действия, ограничения на использование или недоступности. Пожалуйста, начните покупку снова.",
  promotionsEmptyStateHeader: "По установленному фильтру промокода не найдены.",
  promotionsEmptyStateDescription:
    "Создайте промокод, попробуйте другой фильтр или поисковой запрос.",
  toastPromotionUrlCopied: "URL акции скопирован!",
  toastPromotionCodeCopied: "Промокод скопирован!",
  managePromotionsForYourOffers: "Управляйте промокодами для ваших предложений",
  offerPromotions: "Ваши промокода",
  deletePromotionDescription: "Вы уверены, что хотите удалить промокод?",
  deletePromotionTitle: "Удалить промокод",
  addPromotion: "Добавить промокод",
  toastPromotionNotEditedEnabled: "Мы не смогли обновить ваш промокод!",
  toastPromotionNotEdited: "Мы не смогли обновить ваш промокод!",
  usage: "Использовано",
  discountedPriceError:
    "Сниженная цена не может быть меньше эквивалента USD 2!",
  priceWithDiscount: "Цена со скидкой",
  promoCodeAddedToast: "Промо-код успешно применен!",
  toastPromotionSavedSuccessfully: "Промо-код успешно создан",
  toastPromotiomNotAdded: "Произошла ошибка при создании вашей промоакции!",
  myPromotionsMenu: "Мои промокоды",
  setSubscriptionlevelManually: "Установить уровень подписки вручную:",
  settingLevelManuallyNotifyClientAutomatically:
    "Установка уровня вручную автоматически уведомит клиента и будет применена со следующим платежом {{expiration}}",
  subscribeForFree: "Подписаться бесплатно",
  toastSavedSuccessfullyUpdatedLevelCommunity:
    "Уровень сообщества успешно сохранен и обновлен.",
  toastCommunityLevelNotUpdated: "Уровень сообщества не обновлен.",
  creditCard: "Кредитная карта",
  addNewCardStripe: "Добавить новую карту (Stripe):",
  promocode: "Promocode",
  nonFollowers: "Не подписчики",
  dueOn: "Срок до",
  exportMyClientsSuccess: "Экспорт клиентов выполнен успешно.",
  exportMyClientsError: "Ошибка при экспорте клиентов.",
  eventTelegramDescription:
    "Выберите название вашего канала или группы. Если здесь ничего нет, убедитесь, что вы добавили Waylight Bot в вашу группу или канал как администратора и это единственное предложение для этого канала!",
  onlineTelegramEvent: "Онлайн телеграм событие",
  joinEvent: "Присоединиться к событию",
  levels: "Уровни",
  paidAccess: "Платный доступ",
  clientWillPayOnceAccess: "Клиент заплатит один раз за доступ",
  noOffersFound: "Предложений не найдено",
  noOffersFoundDescription:
    "Они появятся здесь, когда вы купите или добавите предложение",
  youCanNotManageEnabled: "У вас нет предложений, которыми можно управлять.",
  youGetPaidSpecifiedPriceForFixedAmountTime:
    "Вы получаете указанную сумму за фиксированное время.",
  videoCallQuick: "Видеозвонок",
  tabs: "Вкладки",
  disconnectWhatsAppBot: "Отключить WhatsApp-бота",
  connectWhatsAppBot: "Подключить WhatsApp-бота",
  WhatsAppBot: "WhatsApp-бот",
  videoCallSession: "Видеозвонок",
  offerCardSize: "Размер карточки предложения",
  large: "Большой",
  small: "Маленький",
  chooseCardSizeOffer:
    "Выберите размер карточки для вашего предложения в списке",
  copyProfileLink: "Скопировать ссылку на профиль",
  profileQRCode: "Мой QR-код профиля",
  copyCommunitiesLink: "Ссылка на сообщества",
  copyEventsLink: "Ссылка на события",
  copyProductsLink: "Ссылка на продукты",
  copySessionsLink: "Ссылка на встречи",
  copyTipsLink: "Ссылка на советы",
  copyPortfolioLink: "Ссылка на портфолио",
  copyReviewsLink: "Ссылка на отзывы",
  leaveReviewForMe: "Оставьте отзыв для меня",
  shareYourWaylight: "Поделитесь своим Waylight",
  myProfileQRCode: "Мой QR-код профиля",
  menu: "Меню",
  selectOfferExportData: "Выберите предложение для экспорта данных",
  setCancellationDelay: "Установить задержку отмены",
  approvalRequired: "Требуется одобрение",
  cancellationFailed: "Не удалось отменить",
  youUnableCancelDesctiption:
    "Вы не можете отменить встречу за {{cancelDelay}} часов до её начала. Свяжитесь с владельцем встречи для получения дополнительных сведений.",
  rescheduleFailed: "Не удалось перенести встречу",
  unableRescheduleDescription:
    "Вы не можете перенести встречу за {{rescheduleDelay}} часов до её начала. Свяжитесь с владельцем встречи для получения дополнительных сведений.",
  errorRefundDescription:
    "Произошла ошибка при отмене встречи. Пожалуйста, свяжитесь с ",
  refundFailed: "Не удалось вернуть средства",
  appointment: "Встреча",
  addInformationAboutAppointment:
    "Добавьте информацию о встрече, которую ваши клиенты смогут разблокировать.",
  addMapLink: "Добавить ссылку на карту",
  addAddressAppointment: "Добавить адрес встречи",
  uploadFileOptionalAppointmentDetails:
    "Загрузите любой файл с дополнительной информацией о встрече",
  locationOptional: "Ссылка на место (необязательно)",
  appointmentName: "Название встречи",
  appointmentErrorName: "Название встречи не может быть пустым",
  upcomingIn: "Скоро через ",
  joinVideoCall: "Присоединиться к видеозвонку",
  cancelAppointment: "Отменить встречу",
  areYouSureWantCancelClient:
    "Вы уверены, что хотите отменить? Ваш платеж будет возвращен в течение 5-10 дней. Комиссии платежной системы могут быть удержаны.",
  areYouSureWantCancelExpert:
    "Вы уверены, что хотите отменить? Платеж вашего клиента будет возвращен в течение 5-10 дней. Комиссии платежной системы могут быть удержаны.",
  aboutAppointment: "О предложении",
  finishAppointmentTitle: "Вы уверены, что хотите завершить встречу?",
  finishAppointmentDescription:
    "Статус вашей встречи будет изменен на ‘прошедшая’, и она завершится.",
  newAppointmentOffer: "Новое предложение встречи",
  editAppointments: "Pедактировать встречи",
  cancellationReason: "Причина отмены",
  aboutAppointmentCreatUpdate: "О встрече",
  totalSessions: "Общее количество встреч",
  yourSession: "Ваша встреча",
  notHaveUpcomingSessions: "У вас нет предстоящих встреч",
  notHavePastSessions: "У вас нет прошедших встреч",
  notHaveQuickSessions: "Вы ещё не начали ни одной быстрой встречи",
  upcomingSessions: "Предстоящие встречи",
  session: "Встреча",
  sessionWith: "встреча с",
  requestSession: "Запросить встречу",
  goToSession: "Перейти к встрече",
  sessions: "Встречи",
  declineSession: "Отклонить встречу",
  wantDeclineSession: "Вы уверены, что хотите отклонить эту встречу?",
  endSession: "Завершить встречу",
  areYouSureYouWantEndSession: "Вы уверены, что хотите завершить эту встречу?",
  aboutOffer: "О встрече",
  aboutTheOffer: "О встрече",
  noTransactionsDescription:
    "Они появятся, как только у вас будут успешные платные встречи",
  sessionsPassed: "Встречи прошли",
  upcomingSession: "Предстоящая встреча",
  bookSession: "Забронировать встречу",
  sessionsManagement: "Управление встречами",
  hadNoSessions: "Нет встреч на этой неделе",
  bookASession: "Когда вы забронируете встречу, она отобразится здесь",
  knowEachOther: "4. Узнайте друг друга и планируйте свои встречи",
  describeRequirements:
    "Опишите требования для клиентов перед присоединением к встречам: сообщение или вложения.",
  preCharge:
    "Предварительное списание будет сделано для запроса вашей встречи.",
  fewRecomendations:
    "Несколько рекомендаций, как сделать вашу встречу комфортной и продуктивной.",
  enjoyTheSession: "Наслаждайтесь встречей!",
  getToKnow: "4. Узнайте друг друга и планируйте свои встречи",
  approveSession: "Подтвердить встречу",
  approveThisSession:
    "Вы уверены, что хотите принять эту встречу? Подтверждая, вы соглашаетесь присоединиться в указанное время.",
  toastSessionCanceled: "Эта встреча была отменена из-за ограничения времени",
  toastSessionWithYourself: "Вы не можете забронировать встречу с самим собой!",
  toastNotMayJoin:
    "К сожалению, вы не можете присоединиться к этой встрече, так как она достигла своей вместимости.",
  toastCannotStartSession: "Невозможно начать быструю встречу сейчас",
  notificationAddPaymentMethod:
    "Добавьте способ оплаты, чтобы иметь возможность бронировать встречи.",
  notificationSetupVariousKindsOfSessions:
    "Настройте различные виды встреч для своих клиентов, которые будут доступны для бронирования в вашем профиле.",
  notificationSetupSchedule:
    "Настройте расписание, и ваши клиенты смогут бронировать встречи в соответствии с ним.",
  cancelSession: "Отменить встречу",
  paymentFailedDescription:
    "Ваш платёж не прошёл, и мы не смогли запросить новую встречу.",
  areYouSureCancel: "Вы уверены, что хотите отменить эту встречу?",
  sessionEnded: "Встреча успешно завершена",
  wereNoChatMessages: "В этой встрече не было сообщений в чате",
  scrollPastHistory: "Прокрутите историю ваших прошлых встреч выше",
  earnedFromLastSession: "заработано за последнюю встречу",
  emptyBillingHistoryDescription:
    "Они появятся здесь, когда вы начнёте бронировать встречи",
  editOffers: "Редактировать встречи",
  freeChatWarning:
    "Этот аккаунт отключил бесплатный чат. Вы можете запросить встречу для общения.",
  unableToJoinSession: "Невозможно присоединиться к встрече",
  scheduleSession: "Запланировать встречу с экспертом или создателем.",
  startSessionImmediately:
    "Немедленно начните бесплатную встречу. Пригласите других по прямой ссылке.",
  quickSessionWarningTittle: "Вы уверены, что хотите начать встречу?",
  quickSessionWarningDescription:
    "У вас запланирована встреча, которая пересекается с быстрой встречей, которую вы пытаетесь начать.",
  expertSessionDecision:
    "Вы хотите завершить встречу или подождать дольше? Завершение встречи приведёт к переводу средств на ваш счёт.",
  clientSessionDecision:
    "Вы хотите завершить встречу или подождать дольше? Завершение встречи вернёт ваш платёж.",
  cancelledSessionTittle: "Эта встреча была отменена",
  areYouSureYouWantLeaveSession: "Вы уверены, что хотите покинуть встречу?",
  areYouSureYouWantLeaveSessionDescription:
    "Вы сможете вернуться к встрече до её окончания.",
  addExtraMinutesDescription:
    "Вы уверены, что хотите добавить ещё 10 минут к этой встрече?",
  unableAddExtraMinutesDescription:
    "К сожалению, у вас запланирована встреча менее чем через 10 минут.",
  freeSessionDecision: "Вы хотите завершить встречу или подождать дольше?",
  newSessionOffer: "Новое предложение встречи",
  newSessionOfferDescription: "Создайте платную или бесплатную видеовстречу",
  sessionName: "Название встречи",
  createSessionOffer: "Создать предложение встречи",
  createSessionOfferDescription:
    "Добавить платную или бесплатную видеовстречу.",
  rescheduleDelayDescription:
    "Укажите, за сколько заранее можно перенести ваши встречи.",

  rescheduleSessionDescription: "Вы уверены, что хотите перенести встречу?",
  cannotRescheduleSession: "Невозможно перенести встречу",
  ScheduleBookingsOnlineVideo:
    "Настройте расписание и получайте бронирования для онлайн-видеовстреч со своими клиентами.",
  paidVideoSessions: "Платные онлайн-видеовстречи",
  businessflowStep3new:
    "Получайте деньги за свои сообщества, цифровые продукты, онлайн и офлайн события, чаевые, встречи лицом к лицу и видеовстречи и т. д.",
  accordionv2text2:
    "Это зависит от плана, который вы выберете, но в любом случае вы получите доступ к полным бизнес-процессам монетизации Waylight, таким как платные сообщества в Telegram, билеты, цифровые продукты, курсы, встречи, видеовстречи, чаевые, бизнес-сайт, данные клиентов, механизмы продаж, академия монетизации и т. д.",
  enableOffersForManagement:
    "Активируйте встречи, события или сообщества для управления ими здесь.",
  specifyQuickSessionPriceDuration:
    "Укажите цену и продолжительность быстрой встречи",
  pleaseEnterEmailAccessQuickSession:
    "Введите свою электронную почту для доступа к быстрой встрече",
  sessionRescheduledByHost: "Вашу встречу перенёс организатор",
  active: "Активный",
  headline: "Заголовок",
  userName: "Имя пользователя",
  turnOf: "Выключить",
  promotion: "Акция",
  qRCode: "QR-код",
  timeframe: "Временные рамки",
};
