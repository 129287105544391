export const pl = {
  next: "Następny",
  youWontBeCharged:
    "Nie pobierzemy opłaty, dopóki nie potwierdzisz szczegółów sesji",
  home: "Głowna",
  chats: "Czaty",
  ratings: "Oceny",
  settings: "Ustawienia",
  moreSettings: "Więcej ustawień",
  settingsNotify: "Ustawienia | Waylight",
  logOut: "Wyloguj",
  changePassword: "Zmień hasło",
  receiveResetPassword:
    "Kliknij „Resetuj” poniżej, aby otrzymać link do resetowania hasła.",
  enterEmailForResetPassword:
    "Wpisz swój adres email, aby otrzymać link do resetowania hasła.",
  successResetPassword:
    "Wiadomość email dotycząca resetowania hasła została wysłana!",
  failResetPassword: "Nie udało się zresetować hasła",
  reset: "Resetowanie",
  signOut: "Wyloguj się",
  availableBooking: "Dostępne do rezerwacji",
  unavailableBooking: "Niedostępne do rezerwacji",
  shareProfile: "Udostępnij profil",
  requests: "Prośba",
  hours: "godziny",
  totalEarned: "Razem zarobione",
  totalHours: "Suma godzin",
  runningNow: "uruchomione",
  offer: "Oferta",
  gallery: "Galeria",
  photo: "Zdjęcie",
  occupation: "Zawód",
  skills: "Umiejętności",
  experts: "Eksperci",
  noInformation: "Brak informacji",
  speaks: "Mówi",
  socialLinks: "Linki społecznościowe",
  contacts: "Kontakty",
  noOffersYet: "Brak dostępnych ofert",
  all: "Wszystko",
  requested: "Wymagany",
  confirmed: "Potwierdzony",
  passed: "Przeszedł",
  joined: "Dołączył",
  ongoing: "Bieżący",
  canceled: "Anulowany",
  rejected: "Odrzucony",
  approved: "Zatwierdzony",
  calendar: "Kalendarz",
  favorites: "Obserwowany",
  removeAll: "Usuń wszystko",
  viewProfile: "Zobacz profil",
  today: "Dzisiaj",
  upcoming: "Nadchodzące",
  attachments: "Załączniki",
  prepare: "Przygotować",
  prerequisites: "Wymagania wstępne",
  download: "Pobierz",
  past: "Po",
  decline: "Odrzuć",
  accept: "Zaakceptować",
  wantDeclinePaidSession:
    "Czy na pewno chcesz odrzucić prośbę? Płatność Twojego klienta zostanie zwrócona.",
  wantDeclineAfterApprove:
    "Czy na pewno chcesz anulować? Płatność Twojego klienta zostanie zwrócona.",
  videoCall: "Rozpocznij transmisję",
  findExpert: "Znajdź eksperta",
  selectAnExpert: "wybierz eksperta",
  inviteClients: "zaproś swoich klientów",
  selectAChat: "Wybierz czat, aby rozpocząć przesyłanie wiadomości",
  noChatsYet: "Brak czatów",
  notHaveMessages: "Nie masz jeszcze żadnych wiadomości",
  shareProfileToClient: "Udostępnij profil klientowi",
  remove: "Usunąć",
  time: "Czas",
  timeSlots: "Przedziały czasowe",
  deleteOffer: "Usuń ofertę",
  myOffers: "Oferty",

  createNewOffer: "Utwórz nową ofertę",
  editOffer: "Edytuj ofertę",
  successfulyBooked: "Sesja pomyślnie zarezerwowana",
  close: "Zakończ",
  account: "Konto",
  price: "Cena £",
  priceAndLength: "Cena i długość",
  uploadAFile: "Prześlij plik",
  uploadAFileError: "Plik nie został przesłany",
  confirm: "Potwierdzic",
  IDLogin: "Twój email",
  myProfile: "Profil klienta",
  transactions: "Transakcje",
  transactionHistory: "Historia transakcji",
  noTransactions: "Nie masz jeszcze żadnych transakcji",

  typicalSchedule: "Typowy harmonogram",
  amount: "Kwota",
  status: "Status",
  paid: "Wypłacone",
  action: "Akcja",
  description: "Opis",
  billing: "Dane do faktury",
  card: "Karta",
  clients: "Klienci",
  payout: "Wypłaty",
  firstName: "Imię",
  lastName: "Nazwisko",
  skip: "Pominąć",
  connect: "Łączyć",
  about: "O",
  title: "Tytuł",
  languages: "Języki",
  currency: "Waluta",
  addYourOffer: "Dodaj swoją ofertę",
  join: "Dołączyć",
  joinIn: "Dołączyć",
  joinOn: "Dołącz na",
  message: "Wiadomość",
  client: "Klient",
  expert: "Ekspert",
  Expert: "Ekspert",
  noMoreOptions: "Nie ma więcej opcji",
  male: "Mężczyzna",
  female: "Kobieta",
  custom: "Dostosuj",
  copyYourLink: "Skopiuj swój link",
  done: "Gotowe",
  search: "Szukaj",
  searchWaylight: "Znajdź swój Waylight",
  suggested: "Zasugerowane",

  startASession: "Szybka sesja",
  quickSession: "Szybka sesja",
  rate: "Wskaźnik",
  perHour: "na godzinę",
  packages: "Pakiety",
  timezone: "Strefa czasowa",
  book: "Książka",
  favorite: "Ulubiony",
  lastSession: "Ostatnia interakcja",
  ago: "temu",
  gender: "Płeć",
  loading: "Ładowanie",
  min: "min",
  hour: "godzina",
  online: "online",
  offline: "offline",
  date: "Data",
  displayName: "Wyświetlana nazwa",
  closePanel: "Zamknij panel",
  goToStripe: "Otwórz panel wypłat",
  completeStripeOnBoarding: "Dokończ wdrażanie Stripe Express",
  stripeOnBoarding: "Stripe Onboarding",
  completed: "zakończony",
  notCompleted: "nie skończone",
  myClients: "Moi klienci",
  findClients: "Nie możemy znaleźć klientów",
  clientDisplayedHere: "Gdy znajdziesz klientów, zostaną oni wyświetleni tutaj",
  writeSomething: "Wpisz coś i naciśnij enter, aby utworzyć element",
  registerAs: "Zarejestruj się jako:",
  addedCards: "Twoje karty",
  cardType: "Typ karty",
  billingHistory: "Historia rozliczeń",
  deleteCard: "Usuń kartę",
  wantToDeleteCard: "Czy na pewno chcesz usunąć kartę?",
  selectPaymentMethod: "Wybierz Metodę Płatności",
  addNewCard: "Dodaj nową kartę",
  emailAddress: "Email publiczny",
  phoneNumber: "Numer telefonu",
  publicPhoneNumber: "Ogólnodostępny numer telefonu",
  username: "Nazwa użytkownika",
  socialProfilesOrLinks: "Profile społecznościowe / Inne linki",
  newOffer: "Nowa oferta",
  myExperts: "Moi eksperci",
  myClientProfile: "Mój profil klienta",
  noExpertsFound: "Nie znaleziono ekspertów",
  openMenu: "Otwórz menu",
  becomeAnExpert: "Dołącz do Waylight Business",
  switchToExpert: "Przełącz na widok biznesowy",
  switchToClient: "Przejdź do widoku klienta",
  notHaveExperts: "Nie masz jeszcze żadnych ekspertów",
  endCall: "Zatrzymaj transmisję",
  endSharing: "Przestań udostępniać",
  lastOnline: "Ostatnio online",
  clientSince: "Klient od",
  welcome: "Powitanie",
  messageError: "Pole wymagane",
  support: "Wsparcie",
  profile: "Profil",
  portfolio: "Teczka",
  publicId: "Identyfikator publiczny",
  files: "Pliki",
  reason: "Powód",
  declineReason: "Poinformuj swojego klienta, dlaczego musisz odrzucić",
  rejectionReason: "Powód odrzucenia",
  business: "Biznes",
  passwordResetInvalidEmail: "Email jest nie prawidłowy",
  logIn: "Zaloguj się",
  logInSubtitle: "Aby wykonać dowolne czynności, zaloguj się lub zarejestruj.",
  enterEmail: "Wprowadź email",
  enterPassword: "Hasło",
  capsLockIsOn: "Caps Lock jest włączony",
  numLockIsOn: "Num Lock jest włączony",
  forgotPassword: "Zapomniałeś hasła?",
  forgotPasswordEmail:
    "Wpisz swój adres email, aby otrzymać link do resetowania hasła.",
  requestPasswordReset: "Poproś o zresetowanie hasła",
  requestResetLink: "Wyślij ponownie link",
  in: "w",
  sec: "sek",
  passwordResetRequestReceived: "Otrzymano żądanie zresetowania hasła",
  passwordResetIfAccountExist:
    "Jeśli konto istnieje, wkrótce otrzymasz wiadomość email z linkiem.",
  resetPassword: "Zresetuj hasło",
  passwordChangeSuccessful: "Pomyślna zmiana hasła",
  passwordChangeSuccessfulSubtitle:
    "Możesz teraz użyć nowego hasła, aby zalogować się na swoje konto.",
  setNewPassword: "Ustaw nowe hasło, aby zalogować się na swoje konto",
  passwordChange: "Zmień hasło",
  passwordOld: "Stare hasło",
  passwordNew: "Nowe hasło",
  dontHaveAccount: "Nie masz konta?",
  alreadyRegistered: "Posiadasz już konto?",
  tokenExpired: "Token wygasł",
  tokenExpiredDescription: "Ten token wygasł lub był używany wcześniej",
  validLength: "8 lub więcej znaków",
  upperCase: "wielkie litery (A - Z)",
  lowerCase: "małe litery (a - z)",
  numbersOrSymbols: "Cyfry lub symbole (0-9!@#$%^&*)",
  joinFree: "Dołącz za darmo",
  businessTitle: "Waylight | Narzędzie Link in BIO, które pomaga zarabiać!",
  businessDescription:
    "Zarabiaj 10 razy więcej na swoich mediach społecznościowych! Uruchom się w zaledwie 2 minuty!",

  spotlightYourExperience:
    "Kompleksowe narzędzia dla Twoich strumieni przychodów z treści, ekspertów i społeczności",
  monetizationPlatform: "Studio Biznesu Online",
  createAccount: "Utwórz konto",
  watchVideo: "Obejrzyj wideo",
  bestToolsAvailable:
    "Chcemy, abyś miał najlepsze dostępne narzędzia i nie pobieramy opłat za korzystanie z nich.",
  schedulingManagement: "Zarządzanie harmonogramem.",
  chooseConvenientTimeSlots:
    "Wybierz dogodne przedziały czasowe do pracy i uzyskaj rezerwacje.",
  expertLandingPayouts: "Wypłaty.",
  simpleOnboarding: "Proste wprowadzanie kont finansowych.",
  industryStandard: "Standardowa w branży jakość i dostępność połączeń.",
  lead: "Prowadzić",
  engage: "Angażować się",
  frequentlyAskedQuestions: "Często Zadawane Pytania",
  contactUs: "Skontaktuj się z nami:",
  accordionLabel0: "Jeśli usługa jest bezpłatna, jak działa Waylight?",
  accordionText0:
    "Pobieramy prowizję opartą na wolumenie od opłat eksperckich, a odkrycia i telefony charytatywne leżą po naszej stronie!",
  accordionLabel1: "Czy obsługujecie wiele typów ofert?",
  accordionText1:
    "Absolutnie, możesz stworzyć dowolną liczbę ofert, określając cenę, czas trwania i szczegóły każdej oferty.",
  accordionLabel2: "Jak otrzymam zapłatę?",
  accordionText2:
    "Używamy Stripe i innych bramek do obsługi wypłat. Prosty onboarding jest wymagany do podłączenia konta bankowego lub metody płatności, która działa w ponad 55 krajach. Wiele więcej metod płatności i elastyczne ustawienia wypłat są w drodze.",
  accordionLabel3: "Czy mogę używać Zoomu, Meet lub Skype?",
  accordionText3:
    "Mamy w pełni zintegrowane rozwiązanie wideo, zbudowane tak, aby zagwarantować bezpieczny przepływ dla obu stron. Korzystanie z innych rozwiązań wideo o mniejszym bezpieczeństwie, ale większej elastyczności będzie opcją w przyszłości.",
  accordionLabel4: "Czy mogę użyć Zapier, Calendly itp.",
  accordionText4:
    "Aktywnie pracujemy nad backlogiem integracji, na których najbardziej zależy naszym użytkownikom. Chętnie poznamy Twoje pomysły w tej sprawie. Podrzuć krótki email na adres support@waylight.me. Dziękujemy!",
  freeAudience: "My się rozwijamy - Ty tworzysz!",
  businessProfile: "Profil działalności.",
  onlineBusiness:
    "Stwórz biznes online w ciągu kilku minut, dostrzegając to, co robisz najlepiej.",
  paidCalls: "Płatne rozmowy audio i wideo.",
  paidChatsAndBlog: "Płatne czaty i blog.",
  setUpSubscription: "Ustaw subskrypcję dla czatu i osobistego bloga.",
  web3: "Web3 oceny i recenzje.",
  collectRealRatings:
    "Zbieraj prawdziwe oceny i recenzje, aby budować swoją markę osobistą.",
  getStartedToday: "Trzy proste kroki do nowego strumienia przychodów:",
  createProfile: "1. Utwórz swój profil",
  fillTemplate:
    "Wypełnij wstępnie zbudowany szablon swojego profilu, harmonogramu i ofert, ustaw ceny za płatny czat i blog, a w ciągu kilku minut będziesz gotowy do przyjmowania zleceń i subskrybentów.",
  shareIt: "2. Podziel się nim z całym światem",
  linkYourProfile:
    "Połącz swój Profil ze wszystkimi swoimi kanałami - mediami społecznościowymi, reklamami, pocztą elektroniczną i innymi. Dziel się informacjami o swoim biznesie online w postach, historiach, filmach, osobistych wiadomościach itp.",
  growYourBusiness: "3. Rozwijaj swój biznes",
  shareYourExpertise:
    "Podziel się swoim doświadczeniem i wiedzą, aktualizuj swój blog, odpowiadaj na rezerwacje i czaty, rób konsultacje wideo online i zdobądź publiczne oceny i recenzje Web3.",
  businessflow: "Przepływ",
  businessflowStep1Title: "Dodaj link w BIO",
  businessflowStep1:
    "Dodaj link do profilu Waylight Business w sieciach społecznościowych BIO (Instagram, Facebook, YouTube, LinkedIn, Twitter, TikTok itp.) i dziel się informacjami poprzez historie i posty na jego temat.",
  businessflowStep2Title: "Wideorozmowy 1:1, czaty 1:1, Blog osobisty",
  businessflowStep2:
    "Monetyzuj poprzez rozmowy wideo 1:1, czaty 1:1 ze swoimi subskrybentami i klientami. Twórz treści tylko dla członków w swoim osobistym blogu z tutorialami, artykułami, poradami wideo, kulisami, osobistymi doświadczeniami życiowymi, lekcjami, muzyką itp.",
  businessflowStep3Title: "Otrzymać zapłatę",
  businessflowStep3:
    "Otrzymuj wynagrodzenie za swoje rozmowy wideo, czaty i subskrypcje tylko dla członków w swoim osobistym blogu. Zwiększaj swoją monetyzację, dzieląc się więcej informacjami o swoim Waylight!",
  bookOnboardingCall:
    "Zarezerwuj bezpłatną rozmowę w sprawie przyjęcia na pokład",
  talkToFounders:
    "Porozmawiaj z założycielami o tym, jak zacząć monetyzować z Waylight.",
  orScanQR: "lub zeskanować QR",
  yourFreeTools: "Twoje darmowe narzędzia",
  features: "Cechy",
  personalLink: "Osobisty adres URL",
  personalQR: "Osobisty QR",
  scheduling: "Scheduling",
  bookings: "Rezerwacje",
  paidChats: "Płatne czaty 1:1",
  personalPaidBlog: "Osobisty płatny blog",
  soon: "wkrótce",
  subscriptions: "Subskrypcje",
  payments: "Płatności w 100+ krajach",
  payouts: "Wypłaty w 58+ krajach",
  cryptoPayments: "Płatności kryptowalutowe",
  coinsAwarding: "Przyznawanie monet",
  web3Ratings: "Web3 oceny, recenzje",
  clientsManagement: "Zarządzanie klientami",

  dashboard: "Tablica rozdzielcza",
  businessCalculator:
    "Kalkulator dochodów z działalności gospodarczej Waylight",
  howMuchMoney:
    "Sprawdź, ile pieniędzy przynieśliby abonenci do Twojego biznesu.",
  estimateNumber: "1. Oszacowanie liczby klientów i abonentów",
  howMuchYouCharge: "2. Ile można pobierać za wideorozmowę lub abonament",
  monthlyIncome: "Miesięczny dochód",
  newToday: "dziś nowy",

  atThisWeek: "w tym tygodniu",
  welcomeToWaylight: "Witamy w Waylight!",
  welcomeToWaylightBusiness: "Witamy w Waylight Business!",
  linkToVideo: "Link do filmu wprowadzającego",
  writeSentences: "Napisz kilka zdań o sobie.",
  emptyExpertTitle: "Wygląda na to, że ta strona nie istnieje",
  emptyExpertText:
    "Link może być uszkodzony, albo strona została wyłączona lub usunięta.",
  profileData: "Dane profilowe",
  headerImageTooltip:
    "Sugerowane wymiary obrazu nagłówka to około 10:1. Możesz spróbować czegoś takiego jak 1900x190px lub 1280x128px.",
  emailTooltip:
    "To jest adres email do publicznego wyświetlania (nie jest to twój login). Możesz podać dowolny adres email lub usunąć go całkowicie, jest to opcjonalne!",
  timezoneTooltip:
    "Jeśli nie możesz znaleźć swojego miasta, spróbuj poszukać strefy, takiej jak 'Pacific Daylight Time' lub 'Eastern European Time'.",
  linkToVideoPresentation: "Dodaj zewnętrzny link",
  expertProfileVideoPresentation: "Lub załaduj plik",
  linkToVideoPresentationTooltip:
    "Ustaw link do zewnętrznego wideo, aby był częścią twoich szczegółów. Większość istniejących usług hostingu wideo jest obsługiwana. Jeśli załadujesz film - będzie on miał pierwszeństwo przed tym linkiem.",
  linkToVideoOfferTooltip:
    "Ustaw link do zewnętrznego filmu jako część szczegółów oferty. Większość istniejących usług hostingu wideo jest obsługiwana. Jeśli załadujesz wideo - będzie ono miało pierwszeństwo przed tym linkiem.",
  phoneTooltip:
    "Jest to numer telefonu do publicznego wyświetlania. Jest nieobowiązkowy!",
  socialProfilesTooltip:
    "Dodaj linki do swoich profili i stron społecznościowych, takich jak Youtube, Facebook, Linkedin, Instagram czy Tiktok.",
  videoPresentationTooltip:
    "Prześlij film, który ma być wyświetlany w Twoich szczegółach. Zastąpi on powyższy link do filmu.",
  videoOfferTooltip:
    "Prześlij film, który ma być wyświetlany w szczegółach oferty. Zastąpi on powyższy link do filmu.",
  occupationTooltip:
    "Powiedz światu o swoim zawodzie, np. 'AR Consultant' lub 'Top Tiktok Influencer'.",
  skillsTooltip:
    "Dodaj swoje umiejętności, dzięki czemu Twoi klienci będą wiedzieli, w czym jesteś dobry, np. 'Przedsiębiorczość' lub 'Rodzicielstwo'.",
  aboutTooltip:
    "Dodaj osobisty opis, aby Twoi klienci czuli się mile widziani i docenili Twoją osobistą markę.",
  speaksTooltip: "Określ języki, którymi się posługujesz.",
  cropPhoto: "Kadrowanie zdjęcia",
  updatePhoto: "Aktualizacja zdjęcia profilowego",
  crop: "Uprawa",

  videoAudioSettings: "Ustawienia wideo i audio",
  videoAudioSettingsDescription:
    "Te ustawienia służą do regulacji obrazu i dźwięku",
  cameras: "Kamera",
  microphones: "Mikrofon",
  output: "Mówcy",
  bgSettings: "Wybierz tło wideo",
  toStartAChat: "Aby rozpocząć czat,",
  startAChat: "i rozpocząć czat.",
  messageToAnExpert: "Wyślij swoją pierwszą wiadomość do eksperta",
  messageToAClient: "Wyślij swoją pierwszą wiadomość do klienta",
  chatWindowGetToKnow: "1. Poznaj swoich potencjalnych klientów i ich potrzeby",
  requestInformation: "2. W razie potrzeby poproś o dodatkowe informacje",
  notGettingAReply:
    "3. Jeśli nie otrzymujesz odpowiedzi, możesz spróbować grzecznie kontynuować",

  chatWindowProvideABrief: "1. Podaj krótki opis tego, czego szukasz",
  chatWindowAddAttachments: "2. Dodaj załączniki, jeśli to konieczne",
  describeYourIssue:
    "Proszę opisać swoją prośbę i to, czego chcesz się nauczyć lub potrzebujesz pomocy. W razie potrzeby dołącz dodatkowe pliki.",
  getAConfirmation: "Wkrótce otrzymasz potwierdzenie od eksperta.",
  describeTheDetails: "Opisz szczegóły, cenę i warunki wstępne swojej oferty.",
  areYouSure: "Czy na pewno chcesz usunąć tę ofertę?",
  offerAvailable: "Oferta dostępna",
  offerUnavailable: "Oferta niedostępna",
  notHaveOffers: "Nie masz jeszcze żadnych ofert",
  createOne: "Stwórz jeden, aby rozpocząć pracę z klientami.",
  getPaid: "Otrzymujesz wynagrodzenie w określonej cenie za ustalony czas.",
  describeYourOffer: "Opisz krótko i szczegółowo swoją ofertę.",

  priceLimit: "Cena może wynosić $0 lub od ${{minPrice}} do ${{maxPrice}}.",
  priceLimitForUA:
    "Cena może wynosić ₴0 lub od ₴{{minPrice}} do ₴{{maxPrice}}.",
  offerNameDescription:
    "Spróbuj czegoś takiego jak 'Discovery Call' lub 'Basic Graphic Design', jeśli to jest twoja rzecz.",
  clickingConfirm: "Klikając 'Potwierdź' zgadzam się z",
  termsAndConditions: "Zasady i warunki",
  paymentFeesMayAlter: "Opłaty za system płatności mogą się różnić.",
  notBeCharged: "Nie zostaniesz obciążony bez dodatkowego potwierdzenia.",
  last: "Ostatnie 4",
  lastDigits: "4 ostatnie cyfry",
  billingPageName: "Nazwa",
  gateway: "Bramka",
  prepareForTheMeeting: "Przygotuj się do spotkania",
  prepareForSession: "Przygotuj się na",

  findAQuiet: "Znajdź ciche miejsce, gdzie nikt nie będzie ci przeszkadzał",
  makeSure: "Upewnij się, że masz dobre połączenie z Internetem",
  weRecommendUsing: "Zalecamy używanie słuchawek",
  prepareYourQuestions: "Przygotuj swoje pytania",
  initialization: "Inicjalizacja...",
  beginOnboardingAsExpert:
    "Rozpocznij szkolenie jako ekspert w Waylight Business",
  connectStripeAccount:
    "Aby otrzymywać pieniądze od swoich klientów, musisz stworzyć metodę wypłaty.",
  provideABrief: "1. Podaj krótki opis tego, czego szukasz",
  addAttachments: "2. Dodaj załączniki, jeśli to konieczne",
  gettingAReply:
    "3. Jeśli nie otrzymujesz odpowiedzi, możesz spróbować grzecznie kontynuować",
  fileType: "typ pliku nie jest obsługiwany",
  orDragAndDrop: "lub przeciągnij i upuść",
  upTo: "do 1 GB (skontaktuj się z pomocą techniczną, jeśli masz większe rozmiary plików)",
  headerImage: "Obrazek z nagłówka",
  inviteClientsModalInviteClients: "Zapraszaj klientów",
  shareYourLink: "Udostępnij swój osobisty link, aby zaprosić swoich klientów!",
  addDays: "Ustaw swój regularny harmonogram",
  publiclyInformation:
    "Można dodać wiele przydziałów czasowych na jeden dzień tygodnia.",
  availableTimeslot: "Dostępny przedział czasowy",
  AddTimeslot: "Dodaj timeslot",
  AddToSchedule: "Dodaj do harmonogramu",
  signUpAs: "Zapisz się jako",
  registerAsClient: "Chcę się nauczyć czegoś nowego",
  registerAsExpert: "Chcę się podzielić moim doświadczeniem",

  noTimeslots:
    "Brak dostępnych przedziałów czasowych. Wybierz inny termin lub zapytaj eksperta o harmonogram.",
  toastSavedSuccessfully: "Zapisane pomyślnie",
  toastDeletedSuccessfully: "Usunięto z powodzeniem",
  toastEmailCopied: "Email skopiowany",
  toastProfileCopied: "Profil skopiowany",
  toastBookingAvalable: "Jesteś teraz dostępny do rezerwacji",
  toastBookingUnavalable: "Nie jesteś dostępny do rezerwacji",
  toastQrCopied: "Skopiowany kod QR",
  toastPaymentMethodAdded: "Metoda płatności dodana pomyślnie",
  toastPaymentMethodDeleted: "Metoda płatności usunięta pomyślnie",
  toastUrlCopied: "Skopiowany link osobisty",
  toastOfferUrlCopied: "Link do oferty skopiowany",

  toastPayoutMethodAdded: "Metoda wypłaty została dodana pomyślnie",
  toastPayoutMethodDeleted: "Sposób wypłaty usunięty pomyślnie",
  toastPrimaryCardError: "Nie można ustawić karty głównej",
  toastPrimaryCardAdded: "Karta główna dodana pomyślnie",
  toastTooLargeFile: "Rozmiar pliku jest zbyt duży",
  toastOfferNotAdded: "Oferta nie została dodana",
  toastOfferNotEdited: "Oferta nie została zredagowana",
  toastSessionLinkCopied: "Skopiowane łącze sesji",
  toastMaxParticipantsReached: "Maksymalna liczba osiągniętych uczestników",

  toastLinkExpired: "Ten link już wygasł.",

  toastSessionExtendedSuccessfully: "Sesja została przedłużona pomyślnie",
  toastTimeslotStartAndEnd:
    "Nie można dodać przedziału czasowego, który zaczyna się i kończy w tym samym czasie",
  toastTimeslotEndsInPast:
    "Nie można dodać czasu antenowego, który kończy się w przeszłości",
  toastTimeslotDuplicate: "Nie można dodać zduplikowanego przedziału czasowego",
  toastSkillNameTooLong:
    "Twoja nazwa umiejętności jest zbyt długa, spróbuj wybrać krótszą nazwę",
  buttonSave: "Zapisz",
  buttonCancel: "Anuluj",
  buttonApprove: "Zatwierdzić",
  buttonDelete: "Usuń",
  buttonChat: "Czat",
  buttonOpenChat: "Czat",
  buttonFiles: "Pliki",
  buttonNotNow: "Nie teraz",
  buttonSubmit: "Zgłoś",
  buttonNo: "Nie",
  buttonEnd: "Koniec",
  buttonSignUp: "Zarejestruj się",
  buttonYes: "Tak",
  buttonEdit: "Edytuj",
  buttonCopy: "Kopia",
  buttonReply: "Odpowiedz",
  buttonAdd: "Dodaj",
  buttonSend: "Wyślij",
  buttonRequest: "Poproś o wsparcie",
  buttonSet: "Ustaw",
  buttonEditPhoto: "Edytuj zdjęcie",
  buttonStartAnyway: "I tak zacznijcie",
  buttonWait: "Poczekaj",
  buttonCopyLink: "Skopiowany link",
  buttonLeave: "Zostaw",
  buttonExtraMinutes: "Dodatkowe 10 minut",
  buttonViewOffer: "Zobacz szczegóły",
  buttonMoreDetails: "Więcej szczegółów",
  buttonContinue: "Kontynuuj",
  buttonBookACall: "Zarezerwuj połączenie",
  notificationSetup: "Ustawienie",
  notificationFillProfile: "Profil wypełnienia",
  notificationHi: "Cześć!",
  notificationAddYourName:
    "Dodaj swoje imię i zdjęcie, aby ożywić komunikację, jeśli masz na to ochotę. Bez presji :)",
  notificationSetupBilling: "Ustawienie rozliczeń",

  notificationGoToBilling: "Przejdź do rozliczeń",
  notificationWeAreSureYouHaveALotToSay:
    "Jesteśmy pewni, że masz wiele do powiedzenia o sobie. Wypełnij swój profil i zacznij się dzielić!",
  notificationAddAnOffer: "Dodaj ofertę",
  notificationAddYourOffers: "Dodaj swoje oferty",

  notificationGoToMyOffers: "Przejdź do oferty.",
  notificationConnectStripe: "Ustawienie wypłat",
  notificationSetupPayouts: "Wprowadzanie wypłat",
  notificationInOrderToReceiveMoney:
    "Aby otrzymywać pieniądze od swoich klientów, musisz stworzyć metodę wypłaty.",
  notificationGoToPayouts: "Przejdź do Wypłaty",
  notificationSetYourSchedule: "Ustal swój harmonogram",
  notificationWorkAtConvenientHours: "Praca w dogodnych godzinach",

  accountOn: "Konto na",
  accountOff: "Konto wyłączone",

  paymentFailed: "Płatność nie powiodła się",

  areYouSureCancelPaidSession:
    "Czy na pewno chcesz zrezygnować? Twoja wpłata zostanie zwrócona.",
  areYouSureCancelLessThanADay:
    "Czy na pewno chcesz zrezygnować, gdy zostało mniej niż 24 godziny? Twoja płatność NIE zostanie zwrócona.",
  link: "Link",
  view: "Zobacz",
  requires_action: "Wymaga działania",
  succeeded: "Succeeded",
  signUpTitle: "Zarejestruj się",
  profileTitleNotify: "Profil | Waylight",
  dashboardTitleNotify: "Dashboard | Waylight",
  chatTitleNotify: "Chat | Waylight",
  with: "z",
  expertsTitle: "Eksperci",
  expertsTitleNotify: "Eksploracja | Światło drogi",
  signUpTitleNotify: "Zarejestruj się | Waylight",
  chatTitle: "Czat",
  clientsTitle: "Klienci",
  clientsTitleNotify: "Klienci | Waylight",
  clientTitle: "Klient",
  fixedPrice: "Cena stała",
  internetConnectionLost: "Utrata połączenia internetowego",
  fiveMinutesLeft: "Zostało 5 minut",
  summarizeMeeting: "Proszę podsumować spotkanie i podzielić się wrażeniami",
  oopsConnectionLost: "Ups... Połączenie partyjne utracone",
  partyTemporaryLostConnection:
    "Wygląda na to, że Twoja strona chwilowo straciła połączenie ze spotkaniem. Przepraszamy za niedogodności.",
  leaveAReviewFor: "Zostaw recenzję dla",
  leaveAReview: "Zostaw recenzję",
  review: "Przegląd",
  followers: "Zwolennicy",
  rating: "Ocena",
  hitStarRate: "Oceń sesję poniżej!",
  reviewsEmpty: "Nie ma jeszcze żadnych recenzji",
  reviews: "Recenzje",

  thankYouForLighting: "Dziękujemy, że oświetlasz komuś drogę!",
  sessionNotStarted: "Sesja nie została rozpoczęta",
  devicePermissionTittle:
    "Zezwól Waylight na dostęp do Twojego mikrofonu i kamery. Odśwież stronę, jeśli nie masz wideo.",
  networkConnection: "Jakość Twojego połączenia internetowego jest",
  unknown: "nieznany",
  low: "niski",
  average: "średnia",
  good: "dobry",
  expertDidNotCome:
    "Niestety, Twój ekspert nie pojawił się. Nie zostaniesz obciążony kosztami i otrzymasz zwrot pieniędzy. Przepraszamy za niedogodności.",
  noFilesAdded: "Nie dodano żadnych plików.",
  enterYourEmail: "Wpisz swój adres email",
  required: "Pole jest wymagane",
  emailOrPassword: "Nieprawidłowy email lub hasło",
  emailInUse: "Ten email jest już w użyciu",
  businessIsNotAvailableTitle:
    "Waylight Business nie jest dostępny w Twoim kraju. Bądź pierwszym, który dowie się, kiedy zostanie uruchomiony.",
  beInTouch: "Bądź w kontakcie",
  businessIsNotAvailableEmailAddress: "Adres email",
  pleaseTryAgain:
    "Nie udało nam się zapisać Twojego adresu email. Proszę spróbować ponownie.",
  thankYou: "Dziękujemy, będziemy w kontakcie jak tylko to się rozwinie.",

  yourCountry: "Kraj Twojego banku",
  open: "Otwórz",
  offerName: "Nazwa oferty",
  gatewayCountryWarning:
    "Uwaga: powinien to być kraj Twojej instytucji bankowej i NIE może być zmieniony później, co może wpłynąć na możliwość otrzymywania wypłat.",
  addAttachment: "Dodaj załącznik",
  you: "Ty",
  explore: "Poznaj",
  stats: "Statystyki",
  more: "Zobacz więcej",
  noPrice: "Bezpłatne oferty",
  theyWillAppear: "Pojawią się one tutaj, gdy rozpoczniesz interakcję",
  noExpertFoundUsingFilter: "Nie znaleziono ekspertów używających tego filtra.",
  tryAnotherInput: "Spróbuj innego znacznika lub wejścia.",
  noFavoriteExpertsFound: "Nie znaleziono ulubionych ekspertów.",
  tryAddingSomeone: "Spróbuj dodać kogoś do ulubionych.",
  agoraDeniedError:
    "Proszę włączyć dostęp do mikrofonu i kamery w ustawieniach, aby móc korzystać z Waylight!",
  editCover: "Edytuj okładkę",
  language: "Język",
  invalid: "Nieważne",
  typeHere: "Wpisz tutaj",
  schedule: "Harmonogram",
  offers: "Oferty",
  qrSharing: "Pobierz i udostępnij swój kod QR.",
  qrSharingMobile: "Naciśnij długo na kod QR, aby go skopiować i udostępnić.",
  preview: "Podgląd",
  back: "Powrót",
  love: "Wykonane z miłością przez Waylight",
  allRightsReserved: "© 2024 Waylight Inc. Wszelkie prawa zastrzeżone.",
  allRightsReservedSecond:
    "© 2022 Arceon Consulting OÜ. Wszelkie prawa zastrzeżone",
  termsOfService: "Warunki korzystania z usług",
  privacyPolicy: "Polityka prywatności",
  sessionSharingpopupTitle: "Nie można rozpocząć udostępniania ekranu!",
  sessionSharingpopupDescription:
    "Sprawdź, czy masz najnowszą wersję przeglądarki i czy ma ona uprawnienia systemowe do udostępniania ekranu. Na koniec możesz spróbować odświeżyć stronę. Jeśli problem nadal występuje, skontaktuj się z pomocą techniczną!",
  sessionSharingpopupSafariDescription:
    "Udostępnianie ekranu nie jest obsługiwane w Twojej przeglądarce. Najnowsze wersje popularnych przeglądarek obsługują tę funkcję.",
  copy: "Kopia",
  expertWithoutOffers: "Ten ekspert nie dodał jeszcze żadnych płatnych ofert.",
  errorViewHeader: "Ups, coś poszło nie tak!",
  errorViewDescription:
    "Napotkałeś błąd. Ten link może być uszkodzony lub nie masz dostępu do elementu.",
  returnToHome: "Wróć do domu",
  invalidURL:
    "Nieprawidłowy adres URL. Upewnij się, że zaczyna się od 'https://' lub 'http://'.",
  invalidVideoExternalURL:
    "Nieprawidłowy adres URL filmu. Dodaj link Youtube lub Vimeo.",
  repeatedURL: "Ten link został już dodany",
  tooLongUrl:
    "Ten link jest zbyt długi. Proszę nie używać linków dłuższych niż 128 znaków.",
  edited: "Edytowane",
  emptyCards: "Nie dodałeś jeszcze żadnych kart",
  emptyBillingHistory: "Nie masz jeszcze żadnych transakcji",

  addNewSkill: "Dodaj nową umiejętność",
  confirmationAddSkill: "Czy na pewno chcesz dodać nową umiejętność",
  addPaymentMethod: "Dodaj metodę płatności",
  stripeDescription: "Do transakcji z ekspertami z 50+ krajów",
  fondyDescription: "Dla transakcji z ekspertami z Ukrainy",
  fondyAddCardDescription:
    "Dodaj metodę płatności, aby kontynuować. W celu potwierdzenia zostanie podjęta próba obciążenia testowego.",
  timeZoneChangedHeader: "Zmiana strefy czasowej",
  timeZoneSChangedDescription:
    "Wykryliśmy, że Twoja strefa czasowa uległa zmianie. Czy chcesz zaktualizować swoją strefę czasową w profilu?",
  updateButton: "Aktualizacja",
  and: "oraz",
  moreFiles: "więcej plików",
  addPayout: "Dodaj co najmniej jedną metodę otrzymywania wypłat",
  testChargeWarning:
    "W celu potwierdzenia zostanie podjęta próba ładowania testowego.",
  payoutMethods: "Twoje metody wypłat",
  identifier: "Identyfikator",
  stopWar: "Stop War",
  yourAreOnlyOneHere: "Tylko ty tu jesteś.",
  yourAreOnlyOneHereSingle:
    "Jesteś tu jedyny. Podziel się linkiem, aby inni mogli się przyłączyć.",
  clientView: "Klient",
  businessView: "Biznes",
  mainPage: "Strona główna",
  signUpCompleted: "Zapisywanie się zakończone",
  selectPaymentInfo:
    "Dostępne metody płatności zależą od bramy Twojego eksperta",
  payment: "Płatności",
  paymentWasDeclined: "Płatność została odrzucona.",
  payoutWasDeclined: "Wypłata została odrzucona.",
  primary: "Podstawowa",
  notUsed: "Nieużywany",
  releaseBannerTitle: "Waylight został zaktualizowany do nowej wersji.",
  refresh: "Odśwież",
  country: "Kraj",
  viewAll: "Zobacz wszystkie",
  videoPresentation: "Prezentacja wideo",
  bookFor: "Książka dla",
  bookForFree: "Książka za darmo",
  follow: "Śledź",
  followed: "Followed",
  emptyPortfolio: "Wygląda na to, że w tym portfolio nie ma żadnych plików",
  media: "Media",
  other: "Pliki",
  emptyReview: "Wygląda na to, że nie ma żadnych recenzji",
  newest: "Najnowsza",
  oldest: "Najstarszy",
  viewProfileInfo: "Zobacz informacje o profilu",
  emptyTransactions: "Wygląda na to, że nie ma żadnych transakcji",
  emptyOffers: "Niestety, ten ekspert nie ma jeszcze żadnych ofert.",
  searchExperts: "Szukaj ekspertów",
  modifySchedule: "Modyfikacja harmonogramu",
  editMode: "Tryb edycji",
  finishEditing: "Zakończenie edycji",
  editPersonalInfo: "Edytuj dane osobowe",
  editPortfolio: "Edytuj portfolio",
  profileId: "Osobisty identyfikator publiczny",
  profileSettings: "Ustawienia profilu",
  editPublicID: "Edytuj identyfikator publiczny",
  removeTimeslot: "Usunąć czasoprzestrzeń",
  areYouSureRemoveTimeslot: "Czy na pewno chcesz usunąć timeslot?",
  qrCode: "kod QR",
  communication: "Komunikacja",
  editCoverDescription: "Dostosuj obrazek na okładce",
  editProfileData: "Edycja danych profilu",
  editProfileDataDescription: "Dodaj informacje o sobie i swoim pochodzeniu",
  editPublicIDDescription:
    "Wybierz unikalny identyfikator publiczny dla swojego profilu",
  profilePhoto: "Zdjęcie profilowe",
  publicIDDescription: "To jest automatycznie wygenerowany identyfikator!",
  publicIDWarning:
    "Nie zapomnij zmienić go na coś fajnego w trybie edycji, jak 'supergirl'.",
  followWarning: "Przycisk Follow będzie działał dla wszystkich użytkowników.",
  openChatWarning:
    "Ty nie będziesz mógł prowadzić rozmowy z samym sobą, ale Twoi klienci już tak.",
  bookingOfferWarning: "Użytkownicy będą mogli zakupić ofertę, klikając tutaj.",
  shareOffer: "Kopiuj link",
  turnOffAccount: "Wyłączyć konto",
  turnOnAccount: "Włączenie konta",
  areYouSureYouWantTurnOff:
    "Czy na pewno chcesz całkowicie wyłączyć swoje konto?",
  areYouSureYouWantTurnOn: "Czy na pewno chcesz ponownie włączyć swoje konto?",
  disableFreeChat: "Wyłączenie darmowego czatu",
  enableFreeChat: "Włącz bezpłatny czat",
  areYouSureYOuWantDisableFreeChat:
    "Czy na pewno chcesz wyłączyć darmowy czat?",
  areYouSureYOuWantEnableFreeChat: "Czy na pewno chcesz włączyć darmowy czat?",
  cancelEditing: "Anuluj edycję",
  descriptionCancelEditing: "Czy na pewno chcesz anulować edycję?",
  takenPublicID: "Ten identyfikator publiczny jest już zajęty",
  charactersMatch: "2-64 znaki",
  charactersBetweenMatch: "Pole musi zawierać od 1 do 64 znaków",
  charactersMaxMatch: "Maksymalna długość pola wynosi 64 znaki",
  deletePhoto: "Usuń zdjęcie",
  descriptionDeletePhoto: "Czy na pewno chcesz usunąć swoje zdjęcie?",
  accountOnDescription:
    "Gdy konto jest wyłączone, nie jest dostępne i nie jest widoczne w żaden sposób (linki bezpośrednie, wyniki wyszukiwania itp.).",
  freechat: "Bezpłatny czat",
  freechatDescription:
    "Gdy darmowy czat jest wyłączony, użytkownicy nie będą mogli inicjować komunikacji z Tobą tą drogą. Będziesz jednak mógł to zrobić ze swoimi klientami.",

  editMessage: "Edycja wiadomości",
  replyTo: "Odpowiedź",
  file: "Plik",

  backgroundWarning:
    "Ostrzeżenie: tła nie są w pełni obsługiwane w przeglądarkach Safari i Firefox i mogą nie działać.",
  single: "Quick",

  quickSessionWarningTittle: "Czy na pewno chcesz rozpocząć spotkanie?",
  quickSessionWarningDescription:
    "Masz zaplanowane spotkanie, które nakłada się na szybkie spotkanie, które próbujesz rozpocząć.",
  connectIn: "Podłączenie w",
  connectOn: "Podłączenie na",
  sessionDecisionTittle: "Twoja partia nie pojawiła się.",
  expertSessionDecision:
    "Czy chcesz zakończyć spotkanie, czy czekać dłużej? Zakończenie spotkania spowoduje przelanie płatności na twoje konto.",
  clientSessionDecision:
    "Czy chcesz zakończyć spotkanie, czy czekać dłużej? Zakończenie spotkania zwróci twoją płatność.",
  cancelledSessionTittle: "To spotkanie zostało anulowane",
  cancelledSessionExpertDescription:
    "Płatność została przelana na Twoje konto. Przepraszamy za niedogodności.",
  cancelledSessionClientDescription:
    "Nie zostaniesz obciążony i otrzymasz zwrot pieniędzy. Przepraszamy za niedogodności.",
  areYouSureYouWantLeaveSession: "Czy na pewno chcesz opuścić spotkanie?",
  areYouSureYouWantLeaveSessionDescription:
    "Będziesz mógł wrócić na spotkanie, dopóki się nie skończy.",
  addExtraMinutes: "Dodaj dodatkowe 10 minut",
  addExtraMinutesDescription:
    "Czy na pewno chcesz dodać dodatkowe 10 minut do tego spotkania?",
  unableAddExtraMinutes: "Nie można dodać dodatkowych 10 minut",
  unableAddExtraMinutesDescription:
    "Niestety, masz zaplanowane spotkanie za mniej niż 10 minut.",
  monday: "Poniedziałek",
  tuesday: "Wtorek",
  wednesday: "Środa",
  thursday: "Czwartek",
  friday: "Piątek",
  saturday: "Sobota",
  sunday: "Niedziela",
  h: "h",
  sharingSetting: "Ustawienie współdzielenia",
  call: "Zadzwoń na",
  public: "Publiczna",
  byLinkOnly: "Tylko przez link",
  offerInfo: "Informacje o ofercie",
  coverVideo: "Okładka / wideo",
  imageVideo: "Obraz / wideo",
  chooseCountry: "Wybierz kraj",
  passwordChangedSuccessfully: "Hasło zostało zmienione pomyślnie",
  connected: "Podłączony",
  passedALot: "Pozytywnie oceniony",
  chooseCountryBank:
    "Wybierz kraj, w którym działa Twój bank lub serwis finansowy.",
  createAccountContinue: "Załóż konto, aby kontynuować",
  metasignInTitle: "Zaloguj się | Waylight",
  metasignUpTitle: "Zarejestruj się | Waylight",
  metapasswordForgotTitle: "Nie pamiętam hasła | Waylight",
  metapasswordResetTitle: "Resetowanie hasła | Waylight",
  clientflowStep1Title: "Znajdź link w BIO lub Waylight Search",
  clientflowStep1:
    "Znajdź link Waylight swojego twórcy, influencera, eksperta lub opiekuna w sieciach społecznościowych (Instagram, Facebook, YouTube, LinkedIn itp.) lub wpisz nazwisko lub pseudonim w wyszukiwaniu Waylight.",
  clientflowStep2Title:
    "Zarezerwuj rozmowę wideo lub zapisz się na blog lub czat",
  clientflowStep2:
    "Otwórz i poznaj profil biznesowy twórcy, influencera, eksperta lub korepetytora. Znajdź ciekawą ofertę na wideorozmowę 1:1, zarezerwuj ją i skocz na nią o wyznaczonej godzinie. Spróbuj czatować po lub przed, aby uzyskać więcej informacji w dłuższej perspektywie. Subskrybuj osobisty blog tylko dla członków z tutorialami, artykułami, poradami wideo, kulisami, osobistymi doświadczeniami życiowymi, lekcjami, muzyką itp.",
  clientflowStep3Title: "Sprawdź zdecentralizowane oceny i recenzje",
  clientflowStep3:
    "Zdecentralizowane oceny budują rekord ścieżki dla twórców i ekspertów, nie mogą być sprzedawane lub podrabiane i budują poczucie zaufania, przejrzystości, dowód ekspertyzy i popyt na treści dla klientów i subskrybentów. Zostaw swoje prawdziwe oceny po rozmowach wideo, czatach i pod postami na blogach przeznaczonymi tylko dla członków. Podziel się swoimi recenzjami, aby pomóc społeczności Waylight w wyłonieniu najlepszych z nich.",
  confirmBooking: "Potwierdź wybór",
  free: "Bezpłatnie",
  unableVerifyEmail: "Nie udało nam się zweryfikować Twojego maila.",
  thankYouForVerifiedEmail: "Dziękujemy za weryfikację maila!",
  reSend: "Ponowne wysłanie",
  signInToContinue: "Zaloguj się, aby kontynuować",
  switchingToClientView: "Przechodzenie do widoku klienta",
  switchingToExpertView: "Przechodzenie do widoku biznesowego",
  cookiePermission:
    "Cenimy sobie Twoją prywatność. Używamy plików cookie, aby zwiększyć komfort przeglądania strony, zapewnić spersonalizowaną treść i analizować nasz ruch. Klikając 'Akceptuj', wyrażasz zgodę na używanie przez nas plików cookie.",
  readMore: "Więcej",
  notReadableDevicesWarning:
    "Ostrzeżenie: kamera i/lub mikrofon są aktualnie używane lub nie mogą być dostępne!",
  howPayoutsWork: "Jak działają wypłaty",
  payoutsOnboarding1: "Zakończ onboarding, aby umożliwić wypłaty.",
  payoutsOnboarding2:
    "Przechowujemy Twoje pieniądze w bezpiecznym miejscu aż do momentu wejścia na pokład.",
  payoutsOnboarding3:
    "W zależności od kraju potrzebne będą dane bankowe lub dane karty.",
  payoutsOnboarding4: "Wypłaty następują w każdy poniedziałek.",
  payoutsOnboarding5: "Status wypłaty można sprawdzić w zakładce Transakcje.",
  payoutsOnboarding6:
    "Bramka płatnicza pobiera opłaty w wysokości 0,25% + 0,25$ za wypłatę.",
  payoutsOnboardingEcommpay6:
    "Bramka płatności pobiera opłatę w wysokości 0,5% za karty i 0% za handlowców, jeśli są włączone opłaty klienta.",
  payoutsOnboarding7:
    "Jeśli jest to pierwszy raz, kiedy otrzymujesz wypłatę, może to potrwać 7-14 dni roboczych ze względu na ograniczenia bramki.",
  payoutsOnboarding8: "Normalnie wypłata trwa około 3 dni roboczych.",
  payoutsOnboarding9:
    "Zmiana kraju nie jest obecnie możliwa. Skontaktuj się z nami, jeśli tego potrzebujesz, a my postaramy się rozwiązać na zasadzie indywidualnego przypadku.",
  payoutsOnboarding10:
    "Waylight pobiera zmienną prowizję w zależności od Twojego planu.",
  payoutsOnboarding11: "Jeśli masz jakieś pytania, skontaktuj się z",
  supportAlternative: "Wsparcie",
  freeSessionDecision: "Czy chcesz zakończyć spotkanie, czy czekać dłużej?",
  cancelledFreeSessionDescription:
    "Jesteś jedynym, który się pojawił. Przepraszamy za niedogodności.",
  m: "m",
  cityNotFound: "Nie znaleziono żadnych opcji. Wybierz najbliższe duże miasto.",
  notifications: "Powiadomienia",
  telegramBot: "Telegram Bot",
  notConnected: "niepodłączony",
  connectTelegramBot: "Połącz Telegram Bot",
  disconnectTelegramBot: "Rozłącz Telegram Bot",
  telegramConnected: "podłączone",
  bgSettingsDescription:
    "Użyj tych ustawień, aby dostosować tło dla strumienia wideo",
  selectBackground: "Wybierz tło",
  bookingDelay: "Ustaw opóźnienie rezerwacji",
  bookingDelayDescription:
    "Określ z jakim wyprzedzeniem można dokonać rezerwacji.",
  processingFee: "Opłata za system płatności",
  payoutsOnboardingEcommpay1:
    "Wybierz metodę wypłaty dla karty kredytowej osobistej lub prywatnej przedsiębiorczości.",
  payoutsOnboardingEcommpay3:
    "Musisz dodać co najmniej jedną kartę kredytową osobistą + swój indywidualny kod podatkowy LUB ukończyć onboarding handlowca Fondy, aby transakcje działały!",
  payoutsOnboardingEcommpay4: "Wypłaty następują po każdej transakcji.",
  payoutsOnboardingEcommpay7:
    "Wypłaty następują najpóźniej następnego dnia roboczego.",
  tutorials: "Produkty",
  tutorialsAndCourses: "Kup produkty",
  noOffers: "Wydaje się, że nie ma jeszcze ofert",
  newSessionOffer: "Nowa oferta spotkania",
  newSessionOfferDescription: "Utwórz płatne lub bezpłatne spotkanie wideo",
  newTutorialOffer: "Nowa oferta produktu",
  newTutorialOfferDescription: "Utwórz płatny lub bezpłatny produkt",
  sessionName: "Nazwa spotkania",
  tutorialName: "Nazwa produktu",
  tutorialNameDescription:
    "Wybierz nazwę dla swojego produktu, na przykład „Klasa taneczna #1”",
  cover: "Okładka",
  tutorialCoverTooltip:
    "Prześlij obraz lub wideo, które zostanie wyświetlone w opisie produktu.",
  getTutorialPaid: "Otrzymujesz wynagrodzenie za każdy zakup tego produktu.",
  unlockedAfterPurchase: "Odblokowane po zakupie",
  unlockedAfterPurchaseDescription:
    "Dodaj treści, które twoi klienci odblokują.",
  tutorialDetailsDescription:
    "Dodaj komentarze, szczegóły lub instrukcje tutaj",
  removeSection: "Usuń sekcję",
  addAnotherSection: "Dodaj kolejną sekcję",
  details: "Szczegóły",
  toastEmptyUnlockedContent: "Zablokowana zawartość nie może być pusta",
  toastTutorialNotEdited: "Ten produkt nie został edytowany",
  removeSectionDescription: "Czy na pewno chcesz usunąć sekcję?",
  buyFor: "Kup za",
  buttonOpenChatAlt: "Otwórz czat",
  buttonShare: "Udostępnij",
  tutorial: "Produkt",
  toastBuyingYourOwnTutorial: "Nie możesz kupić swojego własnego produktu!",
  purchasingInProgress: "Transakcja w trakcie realizacji",
  byClickingConfirm: "Klikając,",
  iAgreeWith: "zgadzam się z",
  allowWaylightToCharge:
    "i zezwól Waylight na obciążanie mojej karty teraz i w przyszłości zgodnie z warunkami subskrypcji, dopóki nie anuluję.",
  paymentProcessingFees: "Mogą obowiązywać opłaty za przetwarzanie płatności.",
  bookingTutorialWarning:
    "Użytkownicy będą mogli kupić produkt, klikając tutaj.",
  editTutorials: "Edytuj produkty",
  topReview: "Najlepsza recenzja",
  addForFree: "Dodaj za darmo",
  tutorialPaymentFailed:
    "Twoja płatność nie została przetworzona, i nie mogłeś zakupić tego produktu.",
  buy: "Kup",
  tutorialWith: "produkt z",
  purchased: "Zakupiony",
  noTutorialsFound: "Nie znaleziono produktów",
  noTutorialsFoundDescription:
    "Pojawią się tutaj po zakupie lub dodaniu produktu",
  tags: "Tagi",
  createSessionOffer: "Utwórz ofertę spotkania",
  createSessionOfferDescription: "Dodaj płatne lub bezpłatne spotkanie wideo.",
  createTutorial: "Utwórz ofertę produktu",
  createTutorialDescription: "Dodaj płatny lub bezpłatny produkt.",
  requires_transfer: "Wymaga wdrożenia",
  pending: "Oczekiwanie na wypłatę",
  requires_payout: "Oczekiwanie na wypłatę",
  requires_capture: "Wstrzymane",
  reschedule: "Przełożenie",
  rescheduleDelay: "Ustaw opóźnienie przełożenia",
  rescheduleDelayDescription:
    "Określ, z jakim wyprzedzeniem można przełożyć twoje spotkania.",
  rescheduleSession: "Przełóż spotkanie",
  rescheduleSessionDescription: "Czy na pewno chcesz przełożyć spotkanie?",
  buttonChange: "Przełożyć",
  timeslots: "Dostępne terminy",
  specifyReason: "Podaj powód zmiany daty i godziny.",
  cannotRescheduleSession: "Nie można przełożyć spotkania",
  rescheduleReason: "Powód przełożenia",
  optionLink: "Dodaj zewnętrzny link",
  optionUpload: "Lub przekaż plik",
  tutorialLinkDescription: "Dodaj link do swojego produktu.",
  tutorialUploadDescription:
    "Prześlij dowolny plik tutaj. Możesz dodać jeden plik na sekcję.",
  bgNone: "Brak",
  bgLowBlur: "Niski rozmycie",
  bgMediumBlur: "Średnie rozmycie",
  bgHighBlur: "Wysokie rozmycie",
  bgSolidColour: "Jednolity kolor",
  bgCustomImage: "Niestandardowy obraz",
  telegramNotifications: "Powiadomienia na Telegramie",
  connectYourTelegramAccount:
    "Podłącz swoje konto Telegram, aby otrzymywać powiadomienia od Waylight.",
  buttonConnectTelegram: "Połącz Telegram",
  notificationGoToMyProfile: "Przejdź do profilu",
  setupSchedule: "Ustaw harmonogram",
  aboutTheTutorial: "O produkcie",
  added: "Dodano",
  orderingChangeInProgress: "Trwa zmiana kolejności...",
  gloryToUkraine: "Chwała Ukrainie!",
  terroristStates:
    "Waylight nie działa dla użytkowników z krajów objętych sankcjami i rządami dyktatorskimi oraz terrorystycznymi: Rosja, Iran, Korea Północna, Syria, Kuba, Wenezuela, Afganistan.",
  never: "Bez limitu",
  expiration: "Limit czasu",
  expirationDescription:
    "Klienci będą mieli dostęp do tego produktu przez określoną liczbę dni",
  daysLeft: "dni pozostało",
  tutorialExpired: "Twój dostęp do tego produktu wygasł.",
  days: "dni",
  tips: "Datki",
  newTipOffer: "Nowa oferta datku",
  newTipOfferDescription: "Utwórz datki",
  tipName: "Nazwa datku",
  tipNameDescription: "Wybierz nazwę dla swojego datku",
  tipCoverTooltip:
    "Prześlij obraz lub wideo, które będzie wyświetlane w opisie datku.",
  aboutTheTip: "O datku",
  describeYourTip:
    "Dodaj opis datku: zbieranie datków, prowadzenie działalności charytatywnej lub kupowanie kawy?",
  tipAmounts: "Dodaj trzy kwoty datków",
  tipAmountsDescription:
    "Twoi klienci będą mogli również wpisać własną kwotę, jeśli zechcą.",
  bonusTipper: "(Opcjonalnie) Wyślij bonus klientowi",
  bonusTipperDescription:
    "Dodaj treść lub wiadomość, którą klienci otrzymają jako bonus po przekazaniu datku.",
  tipLinkDescription: "Dodaj link do bonusu.",
  tipMessageDescription: "Dodaj tutaj swoją wiadomość tekstową.",
  toastTipNotEdited: "Ten datków nie został edytowany",
  leaveATip: "Zostaw datkę",
  bookingTipWarning: "Użytkownicy będą mogli zostawić datkę, klikając tutaj.",
  includesAReward: "Zawiera bonus",
  selectTip: "Wybierz, ile chciałbyś dać datku",
  selectTipAmount: "Wybierz kwotę datku",
  toastBuyingYourOwnTip: "Nie możesz kupić własnego datku!",
  tipSuccessfullyBought: "Datka została pomyślnie zakupiona",
  tip: "Datka",
  tipPaymentFailed:
    "Płatność nie powiodła się, nie udało się zakupić tego datku.",
  purchasedOffers: "Zakupione",
  noTipsFound: "Nie znaleziono datków",
  noTipsFoundDescription: "Pojawią się tutaj, gdy zakupisz datkę",
  thankYouExclamation: "Dziękuję!",
  editTips: "Edytuj darowizny",
  tipPriceLimit: "Cena może wynosić od ${{minPrice}} do ${{maxPrice}}.",
  tipPriceLimitForUA: "Cena może wynosić od ₴{{minPrice}} do ₴{{maxPrice}}.",
  cardAuthorization: "Autoryzacja karty kredytowej",
  failed: "Error",
  communityName: "Nazwa społeczności",
  communityNameDescription:
    "Wybierz nazwę dla swojej społeczności, np. „Mój kanał Telegram tylko dla subskrybentów“",
  getCommunityPaid: "Cena subskrypcji",
  Period: "Okres*",
  expirationCommunityDescription: "Ustaw czas trwania subskrypcji",
  aboutTheCommunityOffer: "O ofercie",
  TelegramChannelName: "Nazwa kanału/grupy Telegram*",
  communityTelegramNameDescription:
    "Wybierz nazwę swojego kanału lub grupy. Jeśli pole jest puste, kliknij ikonę informacji, aby uzyskać instrukcje.",
  communities: "Społeczności",
  community: "Społeczność",
  subscribeFor: "Subskrybuj za",
  subscribed: "Subskrybowano",
  unsubscribe: "Anuluj subskrypcję",
  unsubscribeDescription:
    "Czy na pewno chcesz anulować subskrypcję?\nDostęp wygaśnie",
  subscribeDescription: "{{community}}.",
  usubscribed: "Anulowano subskrypcję",
  unlimited: "Bez ograniczeń",
  subscribeAgain: "Subskrybuj ponownie",
  unsubscribeDescriptionModal:
    "Czy na pewno chcesz ponownie subskrybować?\nNastępna opłata zostanie pobrana",
  subscribe: "Subskrybuj",
  currentlyMember: "Obecnie jesteś członkiem {{title}} na Telegramie.",
  notCurrentlyMember: "Obecnie nie jesteś członkiem {{title}} na Telegramie.",
  newCommunityOffer: "Nowa oferta społeczności",
  newCommunityOfferDescription: "Utwórz lub zarządzaj społecznością",
  editCommunities: "Edytuj społeczności",
  joinCommunities: "Dołącz do społeczności",
  noCommunitiesAdded: "Nie dodano społeczności",
  TheyWillAppear: "Pojawią się tutaj, gdy utworzysz swoje oferty.",
  createCommunity: "Utwórz społeczność",
  noAccessToData: "Brak dostępu do danych! Dodaj",
  noAccessToDataAsAnAdministrator:
    "jako administratora do swojej grupy, aby zapewnić dostęp.",
  retryPayment: "Ponów płatność",
  areYouSureYouWantToSetCard:
    "Czy na pewno chcesz ustawić kartę *{{last4}} jako podstawową?",
  thingsToTry: "Rzeczy do wypróbowania:",
  checkIfYouHaveSufficient:
    "Sprawdź, czy masz wystarczającą ilość środków na swoim koncie lub czy włączono płatności internetowe.",
  selectDifferentPaymentMethod: "Wybierz inny sposób płatności.",
  set: "Ustaw",
  primaryCard: "Podstawowa",
  setPrimary: "Ustaw jako podstawową",
  inviteMember: "Zaproś członka",
  copyLink: "Skopiuj link",
  members: "Członkowie",
  cancel: "Anuluj",
  actions: "DZIAŁANIA",
  subscribedUntil: "SUBSKRYBOWANO DO",
  waylightClients: "Klient WAYLIGHT",
  nameMembers: "NAZWA",
  admin: "Administrator",
  external: "Zewnętrzny",
  subscriber: "Subskrybent",
  unsubscribed: "Anulowano subskrypcję",
  kicked: "Wyrzucono",
  owner: "Właściciel",
  save: "Zapisz",
  kickAndBan: "Wyrzuć",
  areYouSureYouWantToRemove:
    "Czy na pewno chcesz usunąć „{{name}}” z Twojego kanału?",
  WeHaveEncounteredAnIssue: "Wystąpił problem z Twoją płatnością cykliczną!",
  yourPaymentHasNotGone:
    "Twoja płatność nie została przetworzona, a my nie mogliśmy ponownie obciążyć Twojej subskrypcji.",
  gatewayFee: "Klienci płacą opłaty bramkowe",
  gatewayFeeDescription:
    "Po włączeniu tej opcji opłata bramkowa za płatność zostanie doliczona do rachunków klientów. W przypadku wyłączenia opłata zostanie odjęta od wypłat biznesowych.",
  enableGatewayFee: "Włączyć opłatę bramkową dla klientów?",
  areYouSureYOuWantEnableGatewayFee:
    "Czy na pewno chcesz włączyć opłatę bramkową dla klientów?",
  disableGatewayFee: "Wyłączyć opłatę bramkową dla klientów?",
  areYouSureYOuWantDisableGatewayFee:
    "Czy na pewno chcesz wyłączyć opłatę bramkową dla klientów? Opłata zostanie odjęta od Twoich wypłat!",
  communityTooltip:
    "Upewnij się, że dodałeś bota Waylight do swojej grupy/kanału jako administratora, połączyłeś swoje konto Telegram z Waylight i że jest to jedyna oferta dla tego kanału!",
  communityCoverTooltip:
    "Prześlij obraz lub wideo, które będą wyświetlane w opisie społeczności.",
  setCard: "Ustaw",
  expirationDateMustBeInFuture: "Data ważności musi być w przyszłości",
  accessGranted: "Dostęp przyznany",
  noAccess: "Brak dostępu",
  manageCommunity: "Zarządzaj społecznością",
  manage: "Zarządzaj",
  January: "Styczeń",
  February: "Luty",
  March: "Marzec",
  April: "Kwiecień",
  May: "Maj",
  June: "Czerwiec",
  July: "Lipiec",
  August: "Sierpień",
  September: "Wrzesień",
  October: "Październik",
  November: "Listopad",
  December: "Grudzień",
  toastBuyingYourOwnCommunity:
    "Nie możesz subskrybować swojej własnej społeczności!",
  priceLimitCommunity: "Cena może wynosić od ${{minPrice}} do ${{maxPrice}}.",
  priceLimitCommunityForUA:
    "Cena może wynosić od ₴{{minPrice}} do ₴{{maxPrice}}.",
  toastRetryPaymentSuccess:
    "Ponowiona próba płatności zakończyła się powodzeniem.",
  toastCommunityLinkCopied: "Skopiowano link do społeczności",
  communityPaymentFailed: "Nieudana płatność za społeczność",
  AddExpirationDateManually: "Dodaj datę wygaśnięcia ręcznie:",
  SetTimeFormat: "Ustaw format czasu",
  events: "Wydarzenia",
  newEventOffer: "Nowa oferta wydarzenia",
  newEventOfferDescription: "Utwórz zaplanowane wydarzenie",
  eventName: "Nazwa wydarzenia",
  eventNameDescription:
    "Wybierz nazwę dla swojego wydarzenia, np. „Masterclass SEO”",
  eventCoverTooltip: "Podpowiedź dotycząca okładki wydarzenia",
  getEventPaid:
    "Otrzymujesz wynagrodzenie za każdy zakup miejsc na tym wydarzeniu",
  aboutTheEventOffer: "O wydarzeniu",
  dateAndTime: "Data i godzina",
  clickToSetDateAndTime: "Kliknij, aby ustawić datę i godzinę",
  DescribeYourEvent: "Opisz krótko swoje wydarzenie.",
  onlineEvent: "Wydarzenie online",
  offlineEvent: "Wydarzenie offline",
  eventLinkDescription:
    "Dodaj informacje o miejscu wydarzenia, takie jak Zoom lub Meet",
  uploadOptional: "Prześlij (opcjonalne)",
  uploadEventDetails:
    "Prześlij tutaj dowolny plik z opcjonalnymi szczegółami wydarzenia",
  addInformationAboutLocation:
    "Dodaj informacje o miejscu wydarzenia lub link do mapy",
  location: "Link do lokalizacji",
  address: "Adres",
  addressOptional: "Adres (opcjonalne)",
  addAddressEvent: "Dodaj adres wydarzenia",
  AllDayEvent: "To jest wydarzenie trwające cały dzień.",
  toastBuyingYourOwnEvent: "You can not buy your own event!",
  event: "Wydarzenie",
  timeToEvent: "Czas do wydarzenia",
  hoursEvent: "godziny",
  seatsLeft: "wolne miejsca",
  follower: "Obserwujący",
  priceLimitEventForUA: "Cena może wynosić od ₴{{minPrice}} do ₴{{maxPrice}}.",
  soldOut: "Wyprzedane!",
  joinEvents: "Brać udział w wydarzeniach",
  noEventFound: "Nie znaleziono wydarzeń",
  noEventsFoundDescription:
    "Pojawią się tutaj, gdy weźmiesz udział w co najmniej jednym wydarzeniu",
  editEvents: "Edytuj wydarzenia",
  attendForfree: "Weź udział za darmo",
  disabled: "Wyłączone",
  enabled: "Włączone",
  personalCreditcard: "Osobiste (Karta kredytowa)",
  privateEntrepreneur: "Przedsiębiorca prywatny (IBAN)",
  selectPayoutMethod: "Wybierz metodę wypłaty",
  enterYourPersonalTaxCode: "Wprowadź swój osobisty kod podatkowy*",
  createFondyMerchantAccount:
    "Utwórz konto handlowca Fondy i ukończ proces rejestracji:",
  create: "Utwórz",
  participantsMin: "Minimalna liczba uczestników to 1",
  personalTaxCodeError:
    "Indywidualny kod podatkowy musi być prawidłowym numerem!",
  participantsError: "Liczba uczestników musi być prawidłowym numerem!",
  peName: "Nazwa przedsiębiorcy",
  url: "Strona internetowa",
  okpo: "OKPO",
  phone: "Telefon",
  peNameTooltip:
    "Oficjalna nazwa twojego prywatnego przedsiębiorstwa: 'Nazwisko Imię Imię ojca'.",
  urlTooltip:
    "Adres URL twojego biznesu. Może to być twoja strona internetowa, Facebook, Instagram itp.",
  okpoTooltip: "Kod OKPO twojego prywatnego przedsiębiorstwa.",
  phonePayoutTooltip: "Twój numer telefonu.",
  paymentsTipStripe:
    "Płatności są zawsze dostępne dla użytkowników Stripe. Wypłaty są dostępne po zakończeniu rejestracji.",
  paymentsTipFondy1:
    "Przedsiębiorcy prywatni: płatności są aktywowane po utworzeniu handlowca. Wypłaty są dostępne po zakończeniu rejestracji.",
  paymentsTipFondy2:
    "Karty kredytowe: płatności i wypłaty są aktywowane po dodaniu kombinacji prywatnej karty kredytowej i indywidualnego numeru podatkowego.",
  phoneError: "Telefon musi być prawidłowym numerem!",
  toastTaxCodeAdded: "Twój indywidualny kod podatkowy został pomyślnie dodany.",
  toastTaxCodeError:
    "Wystąpił błąd podczas próby dodania twojego indywidualnego kodu podatkowego!",
  toastPECodeAdded:
    "Twój nowy handlowiec Fondy został pomyślnie utworzony. Sprawdź swoją pocztę email, aby rozpocząć proces integracji!",
  toastPECodeError:
    "Wystąpił błąd podczas próby utworzenia nowego handlowca Fondy!",
  onboarding: "Onboarding",
  notCompletedOnboarding: "Nie zakończono",
  completedOnboarding: "Zakończono",
  completeOnboarding: "Zakończ onboarding",
  onboardingTooltip:
    "Zakończ onboarding w panelu sterowania Fondy. Sprawdź swoją skrzynkę pocztową pod kątem zaproszenia do utworzenia hasła, zaloguj się do panelu sterowania Fondy, załaduj wymagane dokumenty i dodaj podpis cyfrowy. Po zatwierdzeniu twoje konto zostanie aktywowane.",
  fondyMerchantAccountDetails: "Szczegóły konta handlowca Fondy",
  eventIsLife: "Wydarzenie jest na żywo",
  created: "Rozpoczęte",
  processing: "Przetwarzanie",
  goToFondyDashboard: "Otwórz swoją tablicę rozdzielczą Fondy",
  youMustCompleteFondy:
    "Musisz ukończyć proces wdrażania Fondy za pomocą zaproszenia emailowego, które otrzymałeś(-aś) na:",
  chooseEventType: "Wybierz rodzaj wydarzenia",
  addException: "Dodaj wyjątek",
  manageScheduleExceptions: "Zarządzaj wyjątkami w harmonogramie",
  addRemoveSpecificTime:
    "Dodaj lub usuń określony czas, kiedy jesteś dostępny lub niedostępny.",
  exceptionType: "Typ wyjątku:",
  available: "Dostępny",
  notAvailable: "Niedostępny",
  yes: "Tak",
  no: "Nie",
  removeException: "Usuń wyjątek",
  removeExceptionDesc: "Czy na pewno chcesz usunąć wyjątek {{time}}?",
  NoExceptionsAdded: "Nie dodano jeszcze żadnych wyjątków",
  availableAllDay: "Będę dostępny przez cały dzień.",
  notAvailableAllDay: "Nie będę dostępny przez cały dzień.",
  exceptionDeleteSuccessfully: "Wyjątek został pomyślnie usunięty",
  exceptionDeleteError: "Nie można usunąć twojego wyjątku",
  exceptionSavedSuccessfully: "Wyjątek został pomyślnie zapisany",
  exceptionSavedError: "Nie można zapisać twojego wyjątku",
  addJoininstructions: "Dodaj instrukcje dołączenia",
  addJoininstructionsDesc:
    "Dodaj treść lub wiadomość, którą klienci zobaczą po zakupie, jeśli jeszcze nie dołączyli do kanału",
  joinChannel: "Dołącz do kanału",
  communityUploadDescription: "Prześlij obraz lub wideo.",
  detailsOptional: "Szczegóły (opcjonalne)",
  type: "Typ",
  youCannotAddPast:
    "Nie możesz dodać przedziału czasowego, który kończy się w przeszłości",
  Sun: "Niedz.",
  Mon: "Pon.",
  Tue: "Wt.",
  Wed: "Śr.",
  Thu: "Czw.",
  Fri: "Pt.",
  Sat: "Sob.",
  toastEventNotEdited: "Wystąpił błąd podczas próby edycji twojego wydarzenia!",
  refunded: "zwrócono",
  declined: "odrzucono",
  verified: "zweryfikowano",
  transactionSuccessful: "Transakcja udana",
  myTabs: "Moje karty",
  tabStatus: "{{tabName}} są {{status}}",
  controlOfferTabs:
    "Rodzaje ofert, które klienci mogą zobaczyć na Twojej stronie głównej:",
  onlyEnglishLetters: "Dozwolone są tylko litery i cyfry w języku angielskim",
  notificationCompleteOnboarding: "Zakończ proces wprowadzania",
  notificationYourPaidOffersAreUnavailable:
    "Twoje płatne oferty nie są dostępne dla Twoich klientów.",
  products: "Produkty",
  bookingAvailable: "Dostępność rezerwacji",
  bookingAvailableDescription:
    "Określ, czy jesteś ogólnie dostępny do rezerwacji.",
  availableKey: "Dostępne",
  notAvailableKey: "Niedostępne",
  paymentOccurs: "Płatność co",
  paymentOccursOneTime: "Jednorazowa płatność.",
  eventIsOver: "Wydarzenie się zakończyło",
  integrations: "Integracje",
  externalAnalytics: "Zewnętrzna analiza",
  externalAnalyticsDescription:
    "Ustaw swoje identyfikatory kont zewnętrznej analizy poniżej.",
  participantsManage: "Uczestnicy",
  manageEvent: "Zarządzaj wydarzeniem",
  noEventsAdded: "Brak dodanych wydarzeń",
  createEvent: "Utwórz wydarzenie",
  shareEvent: "Udostępnij wydarzenie",
  waylightProfile: "Profil Waylight",
  tickedId: "ID biletu",
  checkIn: "Check-in",
  checkedIn: "Zaznaczono jako obecny",
  notCheckedin: "Nie zaznaczono jako obecny",
  codeIsValid: "Kod jest ważny! Zaznaczono jako obecny!",
  codeisInvalid: "Kod jest nieprawidłowy! Proszę spróbować ponownie.",
  codeIsUsed: "Ten kod jest już używany!",
  buyMore: "Kup więcej",
  noParticipantsYet: "Brak uczestników na razie",
  emptyDeteilsBuysTickets:
    "Pojawią się tutaj, gdy ktoś kupi bilety na Twoje wydarzenie.",
  connectYourTelegram:
    "Podłącz swój konta Telegram, aby uzyskać dostęp do kanału!",
  change: "Zmiana",
  numberOfTickets: "Liczba biletów:",
  selectPaymentMethodTitle: "Wybierz metodę płatności:",
  or: "- lub -",
  addACard: "Dodaj kartę",
  subscriptionLevels: "Poziomy subskrypcji",
  subscriptionLevelsTooltip:
    "Dodaj do 3 poziomów subskrypcji dla Twojej społeczności. Na przykład: miesięczny, kwartalny i roczny.",
  levelName: "Nazwa poziomu",
  starter: "Początkujący",
  pro: "Pro",
  levelDiscription: "Nazwa poziomu widoczna dla klientów",
  period: "Okres",
  periodDiscription:
    "Klienci będą obciążani, aby pozostać z subskrypcją w tym odstępie czasu",
  priceDiscription: "Otrzymujesz płatność w określonych odstępach czasu",
  removeLevel: "Usuń poziom",
  addAnotherLevel: "Dodaj kolejny poziom",
  noTrial: "Brak próby",
  trialDiscription: "Liczba dni dostępu, jakie Twoi klienci otrzymają za darmo",
  trialPeriod: "Okres próbny:",
  selectSubscription: "Wybierz subskrypcję:",
  subscription: "Subskrypcja",
  removeLevelDescription: "Czy na pewno chcesz usunąć poziom?",
  tryForFree: "Wypróbuj za darmo",
  startTrial: "Rozpocznij okres próbny",
  subscribedSuccessfully: "Pomyślnie zapisano na subskrypcję",
  edit: "Edytuj",
  expirationColumName: "wygaśnięcie",
  kick: "Wyrzuć",
  trial: "Próbny",
  notJoined: "Nie dołączono",
  telegramProfile: "Profil Telegram",
  notAuthorized: "Nieautoryzowany",
  noMembersFoundFilter: "Nie znaleziono członków według ustawionego filtra.",
  tryADifferentFilter: "Spróbuj innego filtra lub wprowadź inne wyszukiwanie.",
  "not joined": "Nie dołączono",
  "not authorized": "Nieautoryzowany",
  membership: "Członkostwo",
  priceDiscriptionEvents:
    "Otrzymujesz zapłatę za każdy zakup miejsc na tym wydarzeniu",
  fanZone: "Strefa fanów",
  vipZone: "Strefa VIP",
  selectTickets: "Wybierz bilety:",
  attendance: "Frekwencja",
  eventLevels: "Poziomy wydarzenia",
  noTicketsSelected: "Nie wybrano biletów",
  SetMaximumNumberOfSeats: "Ustaw maksymalną liczbę miejsc dla tego poziomu",
  participants: "Liczba miejsc",
  import: "Importuj",
  export: "Eksportuj",
  labelNameDescription: "Wybierz etykietę dla swoich zaimportowanych danych",
  hidden: "ukryty",
  upload: "Prześlij",
  labelName: "Nazwa etykiety",
  fileIsRequired: "Musisz przesłać plik!",
  code: "kod",
  level: "Poziom",
  label: "Etykieta",
  exportFileError: "Eksport nie powiódł się!",
  exportFileSuccess: "Plik pomyślnie wyeksportowany.",
  importFileSuccess: "Plik pomyślnie zaimportowany.",
  importFileError: "Import nie powiódł się!",
  statsSuccess: "Statystyki zameldowania załadowane pomyślnie.",
  statsError: "Wystąpił błąd podczas ładowania statystyk zameldowania!",
  uploadAText: "Zwykły tekst lub plik CSV do 50 MB",
  onlyTextFilesAllowed: "Dozwolone są tylko pliki tekstowe lub CSV!",
  toastCommunityNotEdited: "Podczas edytowania społeczności wystąpił błąd",
  toastYouCanSelectOnlyImage: "Możesz wybrać tylko jeden obrazek na nagłówek",
  sections: "Sekcje",
  logInOrSignup: "Zaloguj się lub zarejestruj poniżej na Waylight",
  continue: "Kontynuuj",
  invalidEmail: "Nieprawidłowy adres email",
  enterCode: "Wprowadź kod",
  haveSentCodeTo: "Wysłaliśmy kod na",
  paste: "Wklej",
  resendCodeIn: "Ponownie wyślij kod... (za {{time}} sekundy)",
  resendCode: "Ponownie wyślij kod",
  waylight: "Waylight",
  pleaseChooseCountry:
    "Proszę wybrać kraj swojego banku lub usługi finansowej.",
  buyAccessFor: "Kup dostęp za",
  thankYouForFollowing: "Dziękujemy za obserwację!",
  incorrectCode: "Kod jest nieprawidłowy lub wygasł!",
  enterEmailToContinue: "Wprowadź email, aby kontynuować",
  city: "Miasto",
  social: "Społeczne",
  earned: "łącznie",
  priceLimitARS:
    "Cena pode ser ARS$ 0, ou de ARS$ {{minPrice}} a ARS$ {{maxPrice}}.",
  priceLimitAUD: "Cena pode ser A$ 0, ou de A$ {{minPrice}} a A$ {{maxPrice}}.",
  priceLimitEUR: "Cena pode ser €0, ou de €{{minPrice}} a €{{maxPrice}}.",
  priceLimitBOB:
    "Cena pode ser Bs. 0, ou de Bs. {{minPrice}} a Bs. {{maxPrice}}.",
  priceLimitBGN: "Cena pode ser лв 0, ou de лв {{minPrice}} a лв {{maxPrice}}.",
  priceLimitCAD:
    "Cena pode ser CA$ 0, ou de CA$ {{minPrice}} a CA$ {{maxPrice}}.",
  priceLimitCLP:
    "Cena pode ser CL$ 0, ou de CL$ {{minPrice}} a CL$ {{maxPrice}}.",
  priceLimitCOP:
    "Cena pode ser COL$ 0, ou de COL$ {{minPrice}} a COL$ {{maxPrice}}.",
  priceLimitCRC: "Cena pode ser ₡0, ou de ₡{{minPrice}} a ₡{{maxPrice}}.",
  priceLimitHRK: "Cena pode ser kn 0, ou de kn {{minPrice}} a kn {{maxPrice}}.",
  priceLimitCZK: "Cena pode ser Kč 0, ou de Kč {{minPrice}} a Kč {{maxPrice}}.",
  priceLimitDKK: "Cena pode ser kr 0, ou de kr {{minPrice}} a kr {{maxPrice}}.",
  priceLimitDOP:
    "Cena pode ser RD$ 0, ou de RD$ {{minPrice}} a RD$ {{maxPrice}}.",
  priceLimitHKD:
    "Cena pode ser HK$ 0, ou de HK$ {{minPrice}} a HK$ {{maxPrice}}.",
  priceLimitEGP:
    "Cena pode ser EGP 0, ou de EGP {{minPrice}} a EGP {{maxPrice}}.",
  priceLimitHUF: "Cena pode ser Ft 0, ou de Ft {{minPrice}} a Ft {{maxPrice}}.",
  priceLimitISK: "Cena pode ser kr 0, ou de kr {{minPrice}} a kr {{maxPrice}}.",
  priceLimitINR: "Cena pode ser ₹0, ou de ₹{{minPrice}} a ₹{{maxPrice}}.",
  priceLimitIDR: "Cena pode ser Rp 0, ou de Rp {{minPrice}} a Rp {{maxPrice}}.",
  priceLimitILS: "Cena pode ser ₪0, ou de ₪{{minPrice}} a ₪{{maxPrice}}.",
  priceLimitUYU: "Cena pode ser $U 0, ou de $U {{minPrice}} a $U {{maxPrice}}.",
  priceLimitGBP: "Cena pode ser £0, ou de £{{minPrice}} a £{{maxPrice}}.",
  priceLimitTRY: "Cena pode ser ₺0, ou de ₺{{minPrice}} a ₺{{maxPrice}}.",
  priceLimitTTD:
    "Cena pode ser TT$ 0, ou de TT$ {{minPrice}} a TT$ {{maxPrice}}.",
  priceLimitTHB: "Cena pode ser ฿0, ou de ฿{{minPrice}} a ฿{{maxPrice}}.",
  priceLimitCHF:
    "Cena pode ser CHF 0, ou de CHF {{minPrice}} a CHF {{maxPrice}}.",
  priceLimitSEK: "Cena pode ser kr 0, ou de kr {{minPrice}} a kr {{maxPrice}}.",
  priceLimitZAR: "Cena pode ser R 0, ou de R {{minPrice}} a R {{maxPrice}}.",
  priceLimitSGD:
    "Cena pode ser SGD$ 0, ou de SGD$ {{minPrice}} a SGD$ {{maxPrice}}.",
  priceLimitSAR:
    "Cena pode ser SAR 0, ou de SAR {{minPrice}} a SAR {{maxPrice}}.",
  priceLimitRON:
    "Cena pode ser lei 0, ou de lei {{minPrice}} a lei {{maxPrice}}.",
  priceLimitPLN: "Cena pode ser zł 0, ou de zł {{minPrice}} a zł {{maxPrice}}.",
  priceLimitPHP: "Cena pode ser ₱ 0, ou de ₱ {{minPrice}} a ₱ {{maxPrice}}.",
  priceLimitPEN:
    "Cena pode ser S/. 0, ou de S/. {{minPrice}} a S/. {{maxPrice}}.",
  priceLimitPYG: "Cena pode ser ₲ 0, ou de ₲ {{minPrice}} a ₲ {{maxPrice}}.",
  priceLimitNOK: "Cena pode ser kr 0, ou de kr {{minPrice}} a kr {{maxPrice}}.",
  priceLimitNZD:
    "Cena pode ser NZ$ 0, ou de NZ$ {{minPrice}} a NZ$ {{maxPrice}}.",
  priceLimitMXN:
    "Cena pode ser MX$ 0, ou de MX$ {{minPrice}} a MX$ {{maxPrice}}.",
  priceLimitKES:
    "Cena pode ser KSh 0, ou de KSh {{minPrice}} a KSh {{maxPrice}}.",
  linkInBIOtool: "Link w narzędziu BIO",
  thatHelpsYouEarn: "które pomaga ci zarabiać!",
  earnMore: "Zarabiaj więcej",
  withWaylight: "z Waylight:",
  descriptionEarn:
    "Zarabiaj 10 razy więcej ze swoich dochodów z mediów społecznościowych!",
  descriptionEarnPart: "Uruchom w zaledwie 2 minuty!",
  earnOnPaidCommunities: "Zarabiaj na płatnych społecznościach.",
  createAndManage:
    "Twórz i zarządzaj społecznościami opłacanymi na Telegramie.",
  EarnPaidDigital: "Zarabiaj na płatnych produktach cyfrowych.",
  hostAndManage:
    "Organizuj i zarządzaj swoimi kursami, poradnikami, e-bookami, przewodnikami, plikami itp.",
  earnOnline: "Zarabiaj na wydarzeniach online i stacjonarnych.",
  sellTicketsAndManage:
    "Sprzedawaj bilety i zarządzaj swoimi wydarzeniami grupowymi online i stacjonarnymi.",
  EarnServices: "Zarabiaj na płatnych usługach.",
  sellManage: "Sprzedawaj i zarządzaj przepływem swoich usług.",
  earnOnlineVideoSessions: "Zarabiaj na płatnych sesjach wideo online.",
  ScheduleBookingsOnlineVideo:
    "Skonfiguruj harmonogram i otrzymuj rezerwacje na spotkania wideo online z klientami.",
  EarnAppointments: "Zarabiaj na płatnych wizytach osobistych.",
  ScheduleSignups:
    "Ustaw harmonogram i zdobywaj zapisy na swoje spotkania 1 na 1 lub grupowe.",
  earnTipping: "Zarabiaj na napiwkach.",
  getTippingSupport:
    "Otrzymuj napiwki na wsparcie swoich kanałów lub za udostępnianie treści w sieciach społecznościowych.",
  youWilGet: "Otrzymasz",
  tools: "1. Narzędzia",
  sales: "2. Sprzedaż",
  clientsGet: "3. Klienci",
  howItWorks: "Jak to działa",
  GetClients: "Zdobądź nowe sprzedaże i klientów",
  useWaylightGrowth:
    "Wykorzystaj narzędzia i porady silnika wzrostu Waylight, aby sprzedawać więcej i zdobywać nowych klientów z mediów społecznościowych.",
  createWaylight: "Stwórz Waylight",
  creatorsExperts: "Twórcy i eksperci",
  interactionsMonthly: "Interakcje miesięcznie",
  monthOverMonth: "Wzrost miesiąc po miesiącu",
  forWhom: "Dla kogo:",
  pricing: "Cennik:",
  chooseYourPlan: "Wybierz swój plan",
  saveWhenChoosingYearlyPlan: "Zaoszczędź więcej, wybierając plan roczny!",
  monthlyBilling: "Płatność miesięczna",
  annuallyBilling: "Płatność roczna",
  freePlan: "Darmowy",
  startLowestCosts: "Zacznij od najniższych kosztów i odkrywaj Waylight",
  mo: "/mies.",
  signUp: "Zapisz się",
  impressionMo: "Opłaty transakcyjne {{comission}}%.",
  basicToolsIncluded: "Podstawowe narzędzia są wliczone!",
  basic: "Podstawowy",
  getEverythingBasic:
    "Zdobądź wszystko w darmowy wersji z niższym poziomem opłat za transakcje",
  contactSales: "Skontaktuj się z działem sprzedaży",
  transactionFees: "Opłaty transakcyjne 2%.",
  publishingMarketplace: "Publikacja w wyszukiwaniu na rynku.",
  saveBadge: "Oszczędź {{percent}}%",
  bestValue: "Najlepsza wartość",
  GetMoreSales:
    "Zdobądź więcej sprzedaży ze swoich mediów społecznościowych bez opłat transakcyjnych",
  ziroTransactionFees: "0% opłat transakcyjnych.",
  proToolsIncluded: "Narzędzia Pro są wliczone!",
  customizations: "Dostosowania.",
  verifiedSign: "Zweryfikowany znak.",
  prioritySupport: "Priorytetowe wsparcie.",
  launchExpert: "Dołączony jest ekspert od uruchamiania.",
  askTelegram: "Zapytaj na Telegramie",
  askWhatsApp: "Zapytaj na WhatsAppie",
  askEmail: "Zapytaj drogą mailową",
  MakeTenX: "Zdobądź 10-krotność",
  onYourSocialMedia: "na swoich mediach społecznościowych",
  revenue: "dochodu! 🎉",
  launchJust: "Uruchom w zaledwie",
  twoMinutes: "2 minuty!",
  product: "Produkt:",
  customersDataManagement: "Zarządzanie danymi klientów",
  reviewsProduct: "Recenzje",
  portfolioProduct: "Portfolio",
  businessWebsite: "Strona internetowa firmy",
  securePayments: "Bezpieczne płatności",
  tipping: "Napiwki",
  paidAppointments: "Płatne spotkania osobiste",
  paidVideoSessions: "Płatne spotkania wideo online",
  paidServices: "Płatne usługi",
  ticketsOnlineEvents: "Bilety na wydarzenia online",
  ticketsScanner: "Skaner biletów",
  ticketsPersonEvents: "Bilety na wydarzenia osobiste",
  paidAccessCourses: "Płatny dostęp do kursów",
  paidAccessDigitalProducts: "Płatny dostęp do produktów cyfrowych",
  paidGroupChannelAccessTelegram:
    "Płatny dostęp do grup i kanałów na Telegramie",
  paidSubscriptionCommunitiesTelegram:
    "Płatne społeczności opłacane na Telegramie",
  productMenuItem: "Produkt",
  PricingMenuItem: "Cennik",
  Creator: "Twórca",
  Freelancer: "Freelancer",
  Tutor: "Korepetytor",
  Coach: "Trener",
  Influencer: "Influencer",
  ask: "Zapytaj",
  anyQuestions: "jakiekolwiek pytania tutaj:",
  how: "Jak",
  itWorks: "to działa",
  businessflowStep1new:
    "Dodaj swój link Waylight do BIO na mediach społecznościowych i zacznij angażować swoją publiczność i klientów, dzieląc się historiami i postami na ten temat.",
  businessflowStep2new:
    "Użyj narzędzi i porad silnika wzrostu Waylight, aby sprzedawać więcej i pozyskiwać nowych klientów z mediów społecznościowych.",
  businessflowStep3new:
    "Zarabiaj na swoich społecznościach, produktach cyfrowych, wydarzeniach online i offline, napiwkach, spotkaniach osobistych i wideo, itp.",
  businessCalculatorNew: "Kalkulator przychodów Waylight",
  howMuchMoneyNew:
    "Dowiedz się, ile pieniędzy przyniosą subskrybenci Twojej firmie.",
  estimateNumberNew: "1. Szacuj liczbę klientów i subskrybentów",
  howMuchYouChargeNew: "2. Ile możesz pobierać za ofertę",
  for: "Dla",
  whom: "Dla kogo",
  getEverythingBasicNew:
    "Zdobądź wszystko w wersji podstawowej z niższym poziomem opłat transakcyjnych",
  startLowestCostsNew:
    "Zacznij bez żadnych kosztów początkowych i odkrywaj Waylight",
  GetMoreSalesNew:
    "Zdobądź więcej sprzedaży ze swoich mediów społecznościowych bez opłat transakcyjnych",
  onYourSocialMediaNew: "na swoich dochodach z mediów społecznościowych! 🎉",
  accordionv2label0: "W jakich krajach pracujesz?",
  accordionv2text0:
    "Waylight Business działa w ponad 55 krajach na 5 kontynentach. Możesz skontaktować się z naszym wsparciem w sprawie swojego kraju lub po prostu spróbować się zarejestrować i sprawdzić. Klienci mogą płacić za Twoje oferty z ponad 150 krajów na całym świecie.",
  accordionv2label1: "Jak otrzymam wynagrodzenie?",
  accordionv2text1:
    "Otrzymasz wypłaty na swoje konto bankowe każdego poniedziałku. Połączenie konta bankowego z Waylight Business zajmuje mniej niż 1 minutę.",
  accordionv2label2: "Jakie narzędzia są wliczone w cenę?",
  accordionv2text2:
    "To zależy od wybranego planu, ale w każdym przypadku uzyskasz dostęp do pełnych przepływów biznesowych monetyzacji Waylight, takich jak płatne społeczności na Telegramie, bilety, produkty cyfrowe, kursy, spotkania, spotkania wideo, napiwki, strona internetowa biznesu, dane klientów, silniki sprzedaży, akademia monetyzacji itp.",
  accordionv2label3: "Czy macie Apple Pay, Google Pay, PayPal?",
  accordionv2text3:
    "Tak, mamy. Nazywamy to „Smooth Checkout”. Waylight pracuje nad automatyzacją przepływów sprzedaży z wyższymi wskaźnikami konwersji, dlatego mamy płatności jednym kliknięciem (z PayPal w drodze).",
  accordionv2label4: "Jak Waylight pomoże mi zarobić 10 razy więcej?",
  accordionv2text4:
    "Otrzymasz dostęp do silnika wzrostu sprzedaży i akademii monetyzacji, które pomogą Ci sprzedawać więcej i pozyskiwać więcej klientów ze swoich mediów społecznościowych. Ponadto Waylight już stworzył zautomatyzowane przepływy i narzędzia, które oferują wyższe wskaźniki konwersji.",
  accordionv2label5: "Co potrzebuję, aby zacząć?",
  accordionv2text5:
    "To łatwe 👌 Zarejestruj się, dodaj swoje konto bankowe, twórz oferty, dołącz do akademii monetyzacji, dodawaj linki na mediach społecznościowych i zacznij zarabiać coraz więcej pieniędzy.",
  connectWaylight: "Połącz się z Waylight!",
  toastTooLargeFileTwilio:
    "Ten plik jest zbyt duży. Maksymalny rozmiar pliku to 150 Mb.",
  enableSold: "Włączyć wskaźniki sprzedaży ofert?",
  areYouSureYOuWantEnableSold:
    "Czy na pewno chcesz włączyć wskaźniki sprzedaży ofert? Wartości sprzedaży będą widoczne dla wszystkich użytkowników.",
  disableSold: "Wyłączyć wskaźniki sprzedaży ofert?",
  areYouSureYOuWantDisableSold:
    "Czy na pewno chcesz wyłączyć wskaźniki sprzedaży ofert?",
  soldEnabled: "Wskaźniki sprzedaży ofert są włączone",
  soldEnabledDescription:
    "Kiedy ta opcja jest włączona, wartości sprzedaży są widoczne dla wszystkich użytkowników.",
  tipOrCommunitypriceLimitARS:
    "Cena może wynosić od ARS$ 1879.31 do ARS$ 9396554.46.",
  tipOrCommunitypriceLimitAUD: "Cena może wynosić od A$ 3.01 do A$ 15097.1 .",
  tipOrCommunitypriceLimitEUR: "Cena może wynosić od €1.81 do €9070.",
  tipOrCommunitypriceLimitBOB:
    "Cena może wynosić od Bs. 13.81 do Bs. 69119.48.",
  tipOrCommunitypriceLimitBGN: "Cena może wynosić od лв 3.55 do лв 17750.81.",
  tipOrCommunitypriceLimitCAD: "Cena może wynosić od CA$ 2.74 do CA$ 13718.98.",
  tipOrCommunitypriceLimitCLP:
    "Cena może wynosić od CL$ 1864.56 do CL$ 9321644.2.",
  tipOrCommunitypriceLimitCOP:
    "Cena może wynosić od COL$ 8072.11 do COL$ 40360576.84.",
  tipOrCommunitypriceLimitCRC: "Cena może wynosić od ₡1055.02 do ₡5277582.56.",
  tipOrCommunitypriceLimitHRK: "Cena może wynosić od kn 36 do kn 70472.",
  tipOrCommunitypriceLimitCZK: "Cena może wynosić od Kč 45.71 do Kč 228558.",
  tipOrCommunitypriceLimitDKK: "Cena może wynosić od kr 13.54 do kr 67719.",
  tipOrCommunitypriceLimitDOP: "Cena może wynosić od RD$ 295 do RD$ 588928.",
  tipOrCommunitypriceLimitHKD: "Cena może wynosić od HK$ 15.58 do HK$ 77915.",
  tipOrCommunitypriceLimitEGP: "Cena może wynosić od EGP 154.75 do EGP 309501.",
  tipOrCommunitypriceLimitHUF: "Cena może wynosić od Ft 716 do Ft 3579217.",
  tipOrCommunitypriceLimitISK: "Cena może wynosić od kr 684 do kr 1369300.",
  tipOrCommunitypriceLimitINR: "Cena może wynosić od ₹167.89 do ₹839435.",
  tipOrCommunitypriceLimitIDR: "Cena może wynosić od Rp 78852 do Rp 157703833.",
  tipOrCommunitypriceLimitILS: "Cena może wynosić od ₪19 do ₪36657.",
  tipOrCommunitypriceLimitUYU: "Cena może wynosić od $U 81 do $U 402925.",
  tipOrCommunitypriceLimitGBP: "Cena może wynosić od £1.56 do £7779.95.",
  tipOrCommunitypriceLimitTRY: "Cena może wynosić od ₺67.06 do ₺335289.19.",
  tipOrCommunitypriceLimitTTD:
    "Cena może wynosić od TT$ 13.58 do TT$ 67917.76.",
  tipOrCommunitypriceLimitTHB: "Cena może wynosić od ฿69.82 do ฿349109.6.",
  tipOrCommunitypriceLimitCHF: "Cena może wynosić od CHF 1.73 do CHF 8647.",
  tipOrCommunitypriceLimitSEK: "Cena może wynosić od kr 20.82 do kr 104092.39.",
  tipOrCommunitypriceLimitZAR: "Cena może wynosić od R 94 do R 186359.",
  tipOrCommunitypriceLimitSGD:
    "Cena może wynosić od SGD$ 2.63 do SGD$ 13146.69.",
  tipOrCommunitypriceLimitSAR: "Cena może wynosić od SAR 19 do SAR 37496.",
  tipOrCommunitypriceLimitRON: "Cena może wynosić od lei 9.04 do lei 45196.94.",
  tipOrCommunitypriceLimitPLN: "Cena może wynosić od zł 7.8 do zł 39008.41.",
  tipOrCommunitypriceLimitPHP: "Cena może wynosić od ₱ 281 do ₱ 560255.",
  tipOrCommunitypriceLimitPEN: "Cena może wynosić od S/. 19 do S/. 38040.",
  tipOrCommunitypriceLimitPYG: "Cena może wynosić od ₲ 36387 do ₲ 72772680.",
  tipOrCommunitypriceLimitNOK: "Cena może wynosić od kr 53 do kr 106903.",
  tipOrCommunitypriceLimitNZD: "Cena może wynosić od NZ$ 3.33 do NZ$ 16646.",
  tipOrCommunitypriceLimitMXN: "Cena może wynosić od MX$ 37.92 do MX$ 189595.",
  tipOrCommunitypriceLimitKES: "Cena może wynosić od KSh 803 do KSh 1605000.",

  authorizeToAccess: "Musisz autoryzować dostęp",
  noCommunitiesFound: "Nie znaleziono żadnych społeczności",
  noCommunitiesFoundDescription:
    "Pojawią się tutaj, gdy kupisz lub dodasz społeczność",
  following: "Następne",
  appointments: "Spotkania",
  share: "Udostępnij",
  addOffer: "Dodaj ofertę",
  aboutMe: "O mnie",
  estimatedAmount:
    "Łączna kwota udanych przelewów na twoje połączone konto Stripe w jego domyślnej walucie.",
  ConnectYourTelegram: "Połącz swój Telegram:",
  ConnectYourTelegramAccount:
    "Połącz swoje konto Telegram, aby otrzymywać powiadomienia od twórców, których obserwujesz.",
  connectTelegram: "Połącz Telegram",
  buyAgain: "Kup ponownie",
  purchases: "Zakupiony",
  videoGuide: "Przewodnik wideo",
  businessBilling: "Fakturowanie Biznesowe",
  noPaymentMethodsAdded: "Nie dodano żadnych metod płatności",
  yourPaymentMethods: "Twoje metody płatności",
  yourPlan: "Twój plan",
  yearlyBilling: "Fakturowanie roczne",
  selectFree: "Wybierz Darmowy",
  whatIncluded: "Co zawiera",
  selectStarter: "Wybierz Podstawowy",
  selectPro: "Wybierz Pro",
  enterPromoCode: "Wprowadź kod promocyjny:",
  apply: "Zastosuj",
  selected: "Wybrane",
  waylightSubscription: "Subskrypcja Waylight",
  cashbackRefferals: "Referral",
  referrals: "Program partnerski",
  shareLink: "Udostępnij link",
  howReferralsWork: "Jak działają referencje",
  cashback: "Cashback",
  yourCashbackCode: "Twój kod cashback",
  yourCustomPartnerReferralCode: "Twój niestandardowy kod polecający partnera",
  howCashbackWork: "Jak działa cashback",
  toastPromoCodeSuccess: "Twój kod promocyjny został pomyślnie zastosowany!",
  toastPromoCodeError:
    "Wystąpił błąd podczas aplikowania twojego kodu promocyjnego!",
  enterPromoCodeSignUp: "Wprowadź kod promocyjny (opcjonalnie):",
  enterPromoCodeBtn: "+ Wprowadź kod promocyjny",
  billingBusinessTooltip: "Wybierz swój plan poniżej.",
  referralTooltip: "To jest twój unikalny kod cashback.",
  cashbackTooltip: "To jest twój unikalny kod partnerski.",
  currentPlan: "Obecny plan:",
  neverExpires: "Nigdy",
  expires: "Wygasa:",
  emptyBillingBusinessHistoryDescription:
    "emptyBillingBusinessHistoryDescription",
  notificationGoToBillingBusiness:
    "Wystąpił problem z płatnością za subskrypcję! ",
  WeHaveEncounteredAnIssueSubscription:
    "Wystąpił problem z płatnością za subskrypcję",
  checkIfYouHaveSufficientSubscription:
    "Sprawdź, czy masz wystarczające środki na swoim koncie lub włączone są płatności internetowe.",
  selectDifferentPaymentMethodSubscription: "Wybierz inną metodę płatności.",
  referralsDescriptions1:
    "Dziękujemy, że jesteś naszym cenionym partnerem afiliacyjnym!",
  referralsDescriptions2:
    "Ukończ onboarding, aby otrzymać wypłaty za polecanie. Zabezpieczamy twoje pieniądze, dopóki nie zostaniesz onboardowany.",
  referralsDescriptions3:
    "Będziesz potrzebować swoich danych bankowych lub danych karty, w zależności od kraju, w którym mieszkasz.",
  referralsDescriptions4:
    "Wypłaty są dokonywane w każdy poniedziałek. Możesz sprawdzić stan wypłat w Transakcjach.",
  referralsDescriptions5:
    "Twój kod partnerski zapewnia dodatkowe zniżki na subskrypcje Waylight.",
  referralsDescriptions6:
    "Twoje zniżki i stawki udziału w przychodach są dostosowane na podstawie naszej umowy.",
  referralsDescriptions7:
    "Jeśli jest to pierwszy raz, gdy otrzymujesz wypłatę, może minąć od 7 do 14 dni roboczych, zanim dotrze do Ciebie ze względu na ograniczenia bramki. Zazwyczaj wypłaty docierają w około 3 dni robocze.",
  cashbackDescriptions1:
    "Dziękujemy za bycie członkiem naszego programu cashback!",
  cashbackDescriptions2:
    "Ukończ onboarding, aby otrzymać wypłaty za polecanie. Zabezpieczamy twoje pieniądze, dopóki nie zostaniesz onboardowany.",
  cashbackDescriptions3:
    "Będziesz potrzebować swoich danych bankowych lub danych karty, w zależności od kraju, w którym mieszkasz.",
  cashbackDescriptions4:
    "Wypłaty są dokonywane w każdy poniedziałek. Możesz sprawdzić stan wypłat w Transakcjach.",
  cashbackDescriptions5:
    "Udostępniaj swój link i uzyskuj udział w przychodach od nowych klientów.",
  cashbackDescriptions6:
    "Twój cashback wynosi 20-30% wszystkich opłat Waylight przez 1 rok (tylko 30% z rocznych subskrypcji Pro).",
  cashbackDescriptions7:
    "Jeśli jest to pierwszy raz, gdy otrzymujesz wypłatę, może minąć od 7 do 14 dni roboczych, zanim dotrze do Ciebie ze względu na ograniczenia bramki. Zazwyczaj wypłaty docierają w około 3 dni robocze.",
  invalidGTMiD:
    "Nieprawidłowy identyfikator GTM. Wprowadź tylko swój identyfikator, a nie kod, na przykład: GTM-AA11BB2.",
  invalidMetaPixelID:
    "Nieprawidłowy identyfikator Meta Pixel. Wprowadź tylko swój identyfikator, a nie kod, na przykład: 123456789012345.",
  invalidHotjarID:
    "Nieprawidłowy identyfikator Hotjar. Wprowadź tylko swój identyfikator, a nie kod, na przykład: 1112223.",
  accountCreated: "Konto utworzone",
  toastOUrlCopied: "Link polecający skopiowany pomyślnie!",
  YouAreOnFreePlan: "Aktualnie jesteś już na planie darmowym",
  referralCodeCanNotBeUsed:
    "Ten kod polecający nie może być użyty dla twojego obecnego kraju konta: {{country}}",
  createWaylightBusiness: "Utwórz biznes Waylight",
  pleaseChooseCountryBank: "Wybierz kraj swojego banku lub usługi finansowej:",
  referralsDescriptionsUA4:
    "Wypłaty występują po każdej transakcji. Możesz sprawdzić statusy wypłat w Transakcjach.",
  referralsDescriptionsUA7:
    "Wypłaty występują najpóźniej następnego dnia roboczego.",
  cashbackDescriptionsUA4:
    "Wypłaty występują po każdej transakcji. Możesz sprawdzić statusy wypłat w Transakcjach.",
  cashbackDescriptionsUA7:
    "Wypłaty występują najpóźniej następnego dnia roboczego.",
  issueSubscriptionPayment:
    "Napotkaliśmy problem z płatnością za twoją subskrypcję!",
  revenueSharhingSelectPE:
    "Program polecający jest dostępny tylko dla aktywowanych kont PE na Ukrainie.",
  youCanChangeYourAccount: "Możesz zmienić typ swojego konta w ustawieniach.",
  yourReferralCodeWillAppear:
    "Twój kod polecający pojawi się tutaj, gdy ukończysz onboarding PE.",
  goToPayoutSettings: "Przejdź do ustawień wypłat",
  commission: "Opłata",
  youAreOnSamePlan:
    "Aktualnie jesteś na tym samym planie, który próbujesz wybrać! Proszę wybierz inny plan.",
  onlyCyrillicLettersAllowed: "Dozwolone są tylko litery cyrylicy",
  dragToChangePosition: "Przeciągnij, aby zmienić pozycję",
  eventsQrcode: "Krótkie kody wydarzeń",
  eventsQrcodeDescription:
    "Gdy ta opcja jest włączona, twoje bilety na wydarzenia będą generowane z krótkimi PINami 4-cyfrowymi i kodami QR. Gdy jest wyłączona, będą generowane bardziej bezpieczne 8-cyfrowe PINy z kodami kreskowymi.",
  areYouSureYOuWantEnableQrcode:
    "Czy na pewno chcesz włączyć krótkie PINy wydarzeń z kodami QR?",
  areYouSureYOuWantDisableQrcode:
    "Czy na pewno chcesz wyłączyć krótkie PINy wydarzeń i generować zamiast nich kody kreskowe?",
  enableEventsQrcode: "Włącz krótkie kody wydarzeń",
  disableEventsQrcode: "Wyłącz krótkie kody i przełącz się na kody kreskowe",
  authorizeScanner: "Autoryzuj skaner",
  toastScannerUrlCopied: "Skopiowano adres URL skanera",
  total: "Razem:",
  gtmIdDescription: "Lokalne GTM dla tej oferty.",
  metaPixelIdDescription: "Lokalny Meta Pixel dla tej oferty.",
  tutorialBoughtPreview: "To jest podgląd zakupionego stanu twojego produktu.",
  tutorialNotBoughtPreview:
    "Tak widzą klienty twój produkt, gdy nie jest zakupiony.",
  authorizeToAccessPurchases: "Proszę autoryzować dostęp do swoich zakupów!",
  myPromotions: "Mój kod promocyjny",
  describeTheDetailsPromotions:
    "Wybierz ofertę, podaj nazwę, wybierz URL i dodaj szczegóły promocji poniżej",
  promotionDropdownDescription:
    "Wybierz swoją ofertę z listy. Nie będziesz mógł jej później zmienić dla tej promocji.",
  offerNamePromotions: "Oferta",
  promotionsTooltip:
    "Wybierz ofertę, do której ta promocja będzie miała zastosowanie. Możesz stworzyć dowolną liczbę innych promocji dla tej oferty.",
  offerNameDescriptionPromotions:
    "Wprowadź nazwę promocji. Jest to tylko do celów wewnętrznych.",
  codePromotionsDescription:
    "Wprowadź unikalny kod promocyjny używając angielskich liter i cyfr. Wypróbuj magiczny przycisk generowania, jeśli chcesz!",
  expirationPromotions: "Wygaśnięcie",
  name: "Nazwa",
  codePromotions: "Kod",
  usersNumDescription:
    "Ten kod promocyjny może być użyty określoną liczbę razy.",
  max_uses: "Liczba użyć",
  pricingPromotionsDiscription: "pricingPromotionsDiscription",
  discount: "Zniżka",
  discountTitle: "Zniżka(i):",
  selectOfferDiscounts:
    "Wybierz ofertę powyżej, aby rozpocząć zarządzanie zniżkami dla niej.",
  promoNameCanNotBeEmpty: "Nazwa promocji nie może być pusta",
  urlIsAlreadyTaken: "Ten URL jest już zajęty!",
  promoCodeValidation:
    "Kod promocyjny może zawierać tylko wielkie i małe litery angielskie oraz cyfry od 0 do 9!",
  promotions: "Promocje",
  enterPromoCodePromotions: "Wprowadź kod promocyjny",
  promoCodeIncorrectExpired:
    "Ten kod promocyjny jest nieprawidłowy lub wygasł!",
  selectedPromoCodeNotAvailable: "Wybrany kod promocyjny nie jest dostępny!",
  weWereUnableToProcessYourTransaction:
    "Nie udało nam się przetworzyć twojej transakcji z wybranym kodem promocyjnym z powodu wygaśnięcia, limitu użyć lub niedostępności. Proszę rozpocznij zakup ponownie.",
  promotionsEmptyStateHeader:
    "Nie znaleziono promocji według ustalonego filtru.",
  promotionsEmptyStateDescription:
    "Stwórz promocje, spróbuj innego filtra lub zapytania wyszukiwania.",
  toastPromotionUrlCopied: "URL promocji skopiowany!",
  toastPromotionCodeCopied: "Kod promocyjny skopiowany!",
  managePromotionsForYourOffers: "Zarządzaj promocjami dla swoich ofert",
  offerPromotions: "Oferty promocyjne",
  deletePromotionDescription: "Czy na pewno chcesz usunąć promocję?",
  deletePromotionTitle: "Usuń promocję",
  addPromotion: "Dodaj promocję",
  toastPromotionNotEditedEnabled:
    "Nie udało nam się zmodyfikować twojej promocji!",
  toastPromotionNotEdited: "Nie udało nam się zmodyfikować twojej promocji!",
  usage: "Użyte",
  discountedPriceError:
    "Cena ze zniżką nie może być niższa niż równowartość 2 USD!",
  priceWithDiscount: "Cena ze zniżką",
  promoCodeAddedToast: "Kod promocyjny został pomyślnie zastosowany!",
  toastPromotionSavedSuccessfully: "Kod promocyjny został pomyślnie utworzony",
  toastPromotiomNotAdded: "Wystąpił błąd podczas tworzenia promocji!",
  myPromotionsMenu: "Moje promocje",
  setSubscriptionlevelManually: "Ustaw poziom subskrypcji ręcznie:",
  settingLevelManuallyNotifyClientAutomatically:
    "Ustawienie poziomu ręcznie powiadomi klienta automatycznie i zostanie zastosowane przy następnym obciążeniu {{expiration}}",
  subscribeForFree: "Subskrybuj za darmo",
  toastSavedSuccessfullyUpdatedLevelCommunity:
    "Poziom społeczności został pomyślnie zapisany i zaktualizowany.",
  toastCommunityLevelNotUpdated:
    "Poziom społeczności nie został zaktualizowany.",
  addNewCardStripe: "Dodaj nową kartę (Stripe):",
  creditCard: "Karta kredytowa",
  promocode: "Promocode",
  nonFollowers: "Nie obserwujący",
  dueOn: "Termin na",
  exportMyClientsSuccess: "Eksport klientów zakończony pomyślnie.",
  exportMyClientsError: "Błąd podczas eksportu klientów.",
  eventTelegramDescription:
    "Wybierz nazwę swojego kanału lub grupy. Jeśli nic tutaj nie widzisz, upewnij się, że dodałeś Waylight Bot do swojej grupy lub kanału jako administratora i jest to jedyna oferta dla tego kanału!",
  onlineTelegramEvent: "Wydarzenie online na telegramie",
  joinEvent: "Dołącz do wydarzenia",
  levels: "Poziomy",
  paidAccess: "Dostęp płatny",
  clientWillPayOnceAccess: "Klient zapłaci raz, aby uzyskać dostęp",
  noOffersFound: "Nie znaleziono ofert",
  noOffersFoundDescription: "Pojawią się tutaj, gdy kupisz lub dodasz ofertę",
  youCanNotManageEnabled: "Nie masz żadnych ofert, którymi możesz zarządzać.",
  enableOffersForManagement:
    "Aktywuj spotkania, wydarzenia lub społeczności, aby zarządzać nimi tutaj.",
  specifyQuickSessionPriceDuration:
    "Określ cenę i czas trwania szybkiego spotkania",
  youGetPaidSpecifiedPriceForFixedAmountTime:
    "Otrzymujesz zapłatę za określoną kwotę za ustalony czas.",
  videoCallQuick: "Rozmowa wideo",
  tabs: "Karty",
  pleaseEnterEmailAccessQuickSession:
    "Wprowadź swój e-mail, aby uzyskać dostęp do szybkiego spotkania",
  disconnectWhatsAppBot: "Odłącz WhatsApp-bota",
  connectWhatsAppBot: "Połącz WhatsApp-bota",
  WhatsAppBot: "WhatsApp-bot",
  videoCallSession: "Rozmowa wideo",
  offerCardSize: "Rozmiar karty oferty",
  large: "Duży",
  small: "Mały",
  chooseCardSizeOffer: "Wybierz rozmiar karty dla swojej oferty na liście",
  copyProfileLink: "Skopiuj link do profilu",
  profileQRCode: "Mój kod QR profilu",
  copyCommunitiesLink: "Link do społeczności",
  copyEventsLink: "Link do wydarzeń",
  copyProductsLink: "Link do produktów",
  copySessionsLink: "Link do spotkań",
  copyTipsLink: "Link do porad",
  copyPortfolioLink: "Link do portfolio",
  copyReviewsLink: "Link do recenzji",
  leaveReviewForMe: "Zostaw recenzję dla mnie",
  shareYourWaylight: "Udostępnij swój Waylight",
  myProfileQRCode: "Mój kod QR profilu",
  menu: "Menu",
  selectOfferExportData: "Wybierz ofertę do eksportu danych",
  setCancellationDelay: "Ustaw opóźnienie anulowania",
  approvalRequired: "Wymagana zgoda",
  cancellationFailed: "Anulowanie nie powiodło się",
  youUnableCancelDesctiption:
    "Nie możesz anulować spotkania w ciągu {{cancelDelay}} godzin przed jego rozpoczęciem. Skontaktuj się z właścicielem spotkania, aby uzyskać więcej informacji.",
  rescheduleFailed: "Przełożenie nie powiodło się",
  unableRescheduleDescription:
    "Nie możesz przełożyć spotkania w ciągu {{rescheduleDelay}} godzin przed jego rozpoczęciem. Skontaktuj się z właścicielem spotkania, aby uzyskać więcej informacji.",
  errorRefundDescription:
    "Coś poszło nie tak podczas anulowania spotkania. Skontaktuj się z ",
  refundFailed: "Zwrot pieniędzy nie powiódł się",
  appointment: "Spotkanie",
  addInformationAboutAppointment:
    "Dodaj informacje o spotkaniu, które twoi klienci odblokują.",
  addMapLink: "Dodaj link do mapy",
  addAddressAppointment: "Dodaj adres spotkania",
  uploadFileOptionalAppointmentDetails:
    "Prześlij dowolny plik z opcjonalnymi szczegółami spotkania",
  locationOptional: "Link do lokalizacji (opcjonalnie)",
  appointmentName: "Nazwa spotkania",
  appointmentErrorName: "Nazwa spotkania nie może być pusta",
  upcomingIn: "Nadchodzące za ",
  joinVideoCall: "Dołącz do wideorozmowy",
  cancelAppointment: "Anuluj spotkanie",
  areYouSureWantCancelClient:
    "Czy na pewno chcesz anulować? Twoja płatność zostanie zwrócona w ciągu 5-10 dni. Opłaty za bramkę płatności mogą zostać potrącone.",
  areYouSureWantCancelExpert:
    "Czy na pewno chcesz anulować? Płatność twojego klienta zostanie zwrócona w ciągu 5-10 dni. Opłaty za bramkę płatności mogą zostać potrącone.",
  aboutAppointment: "O ofercie",
  finishAppointmentTitle: "Czy na pewno chcesz zakończyć spotkanie?",
  finishAppointmentDescription:
    "Status twojego spotkania zostanie zmieniony na ‘zakończone’ i zakończy się.",
  newAppointmentOffer: "Nowa oferta spotkania",
  editAppointments: "Edytuj spotkania",
  cancellationReason: "Powód anulowania",
  aboutAppointmentCreatUpdate: "O spotkaniu",
  sessionRescheduledByHost:
    "Twoje spotkanie zostało przełożone przez gospodarza",
  totalSessions: "Łączna liczba spotkań",
  yourSession: "Twoje spotkanie",
  notHaveUpcomingSessions: "Nie masz jeszcze nadchodzących spotkań",
  notHavePastSessions: "Nie masz jeszcze przeszłych spotkań",
  notHaveQuickSessions: "Nie rozpocząłeś jeszcze żadnych szybkich spotkań",
  upcomingSessions: "Nadchodzące spotkania",
  session: "Spotkanie",
  sessionWith: "spotkanie z",
  requestSession: "Poproś o spotkanie",
  goToSession: "Przejdź do spotkania",
  sessions: "Spotkania",
  declineSession: "Odrzuć spotkanie",
  wantDeclineSession: "Czy na pewno chcesz odrzucić to spotkanie?",
  endSession: "Zakończ spotkanie",
  areYouSureYouWantEndSession: "Czy na pewno chcesz zakończyć to spotkanie?",
  aboutOffer: "O spotkaniu",
  aboutTheOffer: "O spotkaniu",
  noTransactionsDescription:
    "Pojawią się, gdy otrzymasz udane płatne spotkania",
  sessionsPassed: "Spotkania przeszły",
  upcomingSession: "Nadchodzące spotkanie",
  bookSession: "Zarezerwuj spotkanie",
  sessionsManagement: "Zarządzanie spotkaniami",
  hadNoSessions: "Brak spotkań w tym tygodniu",
  bookASession: "Kiedy zarezerwujesz spotkanie, pojawi się ono tutaj",
  knowEachOther: "4. Poznajcie się i zaplanujcie swoje spotkania",
  describeRequirements:
    "Opisz wymagania dla klientów przed dołączeniem do spotkań: wiadomość lub załączniki.",
  preCharge: "Zostanie dokonana wstępna opłata za zarezerwowanie spotkania.",
  fewRecomendations:
    "Kilka zaleceń, jak sprawić, by twoje spotkanie było komfortowe i produktywne.",
  enjoyTheSession: "Ciesz się spotkaniem!",
  getToKnow: "4. Poznajcie się i zaplanujcie swoje spotkania",
  approveSession: "Zatwierdź spotkanie",
  approveThisSession:
    "Czy na pewno chcesz zaakceptować to spotkanie? Zatwierdzając, zgadzasz się dołączyć w określonym czasie.",
  toastSessionCanceled:
    "To spotkanie zostało anulowane z powodu ograniczeń czasowych",
  toastSessionWithYourself: "Nie możesz zarezerwować spotkania ze sobą!",
  toastNotMayJoin:
    "Niestety, nie możesz dołączyć do tego spotkania, ponieważ osiągnęło ono swoją pojemność.",
  toastCannotStartSession: "Nie można teraz rozpocząć szybkiego spotkania",
  notificationAddPaymentMethod:
    "Dodaj metodę płatności, aby móc rezerwować spotkania.",
  notificationSetupVariousKindsOfSessions:
    "Skonfiguruj różne rodzaje spotkań dla swoich klientów, które będą dostępne do rezerwacji w twoim profilu.",
  notificationSetupSchedule:
    "Skonfiguruj harmonogram, a twoi klienci będą mogli rezerwować spotkania zgodnie z nim.",
  cancelSession: "Anuluj spotkanie",
  paymentFailedDescription:
    "Twoja płatność nie została przetworzona i nie mogliśmy zarezerwować nowego spotkania.",
  areYouSureCancel: "Czy na pewno chcesz anulować to spotkanie?",
  sessionEnded: "Spotkanie zakończyło się sukcesem",
  wereNoChatMessages: "Nie było wiadomości na czacie podczas tego spotkania",
  scrollPastHistory:
    "Przewiń przez historię swojego przeszłego spotkania powyżej",
  earnedFromLastSession: "zarobione z ostatniego spotkania",
  emptyBillingHistoryDescription:
    "Pojawią się tutaj, gdy zaczniesz rezerwować spotkania",
  editOffers: "Edytuj spotkania",
  freeChatWarning:
    "To konto wyłączyło darmowy czat. Możesz poprosić o spotkanie, aby się komunikować.",
  unableToJoinSession: "Nie można dołączyć do spotkania",
  scheduleSession: "Zaplanuj spotkanie z ekspertem lub twórcą.",
  startSessionImmediately:
    "Rozpocznij natychmiast darmowe spotkanie. Zaproś innych bezpośrednio za pomocą linku.",
  active: "Aktywny",
  headline: "Nagłówek",
  userName: "Nazwa użytkownika",
  turnOf: "Wyłącz",
  promotion: "Promocja",
  qRCode: "Kod QR",
  timeframe: "Ramy czasowe",
};
